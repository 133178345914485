<template>
  <div class="row justify-content-center">
    <div class="col-md-6 col-lg-6 d-flex">
      <div class="card user-details flex-fill">
        <div class="card-header">
          <h5>Recent Joined Members</h5>
        </div>
        <div class="card-body">
          <div class="table-responsive mb-0">
            <table class="table">
              <thead class="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Reg Date</th>
                  <th>Login Time</th>
                  <th>Country</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <router-link
                        to="/admin-template/user/user-list"
                        class="avatar avatar-md"
                        ><img
                          src="@/assets/admin/img/users/user-01.jpg"
                          class="img-fluid rounded-circle"
                          alt="img"
                      /></router-link>
                      <div class="ms-2 profile-name">
                        <p class="text-dark mb-0">
                          <router-link to="/admin-template/user/user-list"
                            >Aaryian Jose</router-link
                          >
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>02 Sep 2024</td>
                  <td>10:00 AM</td>
                  <td>United States</td>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <router-link
                        to="/admin-template/user/user-list"
                        class="avatar avatar-md"
                        ><img
                          src="@/assets/admin/img/users/user-02.jpg"
                          class="img-fluid rounded-circle"
                          alt="img"
                      /></router-link>
                      <div class="ms-2 profile-name">
                        <p class="text-dark mb-0">
                          <router-link to="/admin-template/user/user-list"
                            >Sarika Jain</router-link
                          >
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>14 Sep 2024</td>
                  <td>11:30 AM</td>
                  <td>Canada</td>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <router-link
                        to="/admin-template/user/user-list"
                        class="avatar avatar-md"
                        ><img
                          src="@/assets/admin/img/users/user-03.jpg"
                          class="img-fluid rounded-circle"
                          alt="img"
                      /></router-link>
                      <div class="ms-2 profile-name">
                        <p class="text-dark mb-0">
                          <router-link to="/admin-template/user/user-list"
                            >Clyde Smith</router-link
                          >
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>28 Sep 2024</td>
                  <td>08:15 AM</td>
                  <td>United Kingdom</td>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <router-link
                        to="/admin-template/user/user-list"
                        class="avatar avatar-md"
                        ><img
                          src="@/assets/admin/img/users/user-04.jpg"
                          class="img-fluid rounded-circle"
                          alt="img"
                      /></router-link>
                      <div class="ms-2 profile-name">
                        <p class="text-dark mb-0">
                          <router-link to="/admin-template/user/user-list"
                            >Carla Jenkins</router-link
                          >
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>12 Oct 2024</td>
                  <td>06:40 PM</td>
                  <td>Germany</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6 d-flex">
      <div class="card user-details flex-fill">
        <div class="card-header">
          <h5>Recent Created Groups</h5>
        </div>
        <div class="card-body">
          <div class="table-responsive mb-0">
            <table class="table">
              <thead class="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Reg Date</th>
                  <th>Login Time</th>
                  <th>Members</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <router-link to="/admin-template/group" class="avatar avatar-md"
                        ><img
                          src="@/assets/admin/img/group-chat/group-01.jpg"
                          class="img-fluid rounded-circle"
                          alt="img"
                      /></router-link>
                      <div class="ms-2 profile-name">
                        <p class="text-dark mb-0">
                          <router-link to="/admin-template/group"
                            >The Dream Team</router-link
                          >
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>02 Sep 2024</td>
                  <td>10:00 AM</td>
                  <td>105</td>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <router-link to="/admin-template/group" class="avatar avatar-md"
                        ><img
                          src="@/assets/admin/img/group-chat/group-02.jpg"
                          class="img-fluid rounded-circle"
                          alt="img"
                      /></router-link>
                      <div class="ms-2 profile-name">
                        <p class="text-dark mb-0">
                          <router-link to="/admin-template/group"
                            >The Meme Team</router-link
                          >
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>14 Sep 2024</td>
                  <td>11:30 AM</td>
                  <td>120</td>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <router-link to="/admin-template/group" class="avatar avatar-md"
                        ><img
                          src="@/assets/admin/img/group-chat/group-03.jpg"
                          class="img-fluid rounded-circle"
                          alt="img"
                      /></router-link>
                      <div class="ms-2 profile-name">
                        <p class="text-dark mb-0">
                          <router-link to="/admin-template/group"
                            >Tech Talk Tribe</router-link
                          >
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>28 Sep 2024</td>
                  <td>08:15 AM</td>
                  <td>200</td>
                </tr>
                <tr>
                  <td>
                    <div class="d-flex align-items-center">
                      <router-link to="/admin-template/group" class="avatar avatar-md"
                        ><img
                          src="@/assets/admin/img/group-chat/group-04.jpg"
                          class="img-fluid rounded-circle"
                          alt="img"
                      /></router-link>
                      <div class="ms-2 profile-name">
                        <p class="text-dark mb-0">
                          <router-link to="/admin-template/group"
                            >The Academic Alliance</router-link
                          >
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>12 Oct 2024</td>
                  <td>06:40 PM</td>
                  <td>250</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
