<script>
export default {
  data() {
    return {
      title: "Appearance",
      text: "Theme Settings",
      text1: "Appearance",
      NunitoSel: ["Select", "Nunito", "Poppins"],
      SelcSel: ["Select", "Small - 85px", "Large - 250px"],
      activeTheme: { color: "bg-primary" }, // Set the first theme as the default
      themes: [
        { color: "bg-primary" },
        { color: "bg-secondary" },
        { color: "bg-info" },
        { color: "bg-purple" },
        { color: "bg-pink" },
        { color: "bg-warning" },
        { color: "bg-danger" },
      ],
    };
  },
  methods: {
    selectClick(theme) {
      this.activeTheme = theme; // Set the active theme
    },
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- App Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-xl-3 col-md-4">
              <div class="card mb-3 mb-md-0">
                <div class="card-body setting-sidebar">
                  <div class="d-flex">
                    <router-link
                      to="authentication-settings"
                      class="active rounded flex-fill"
                      ><i class="ti ti-globe me-2"></i>Appearance</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- Autentication Settings -->
            <div class="col-xl-9 col-md-8">
              <div class="card setting-content mb-0">
                <div class="card-header px-0 mx-3">
                  <h4>Appearance</h4>
                </div>
                <div class="card-body pb-0">
                  <div class="row align-items-center">
                    <div class="col-xl-3 col-lg-12 col-md-3">
                      <div class="setting-info mb-4">
                        <h6 class="fs-14 fw-medium">Select Theme</h6>
                      </div>
                    </div>
                    <div class="col-xl-9 col-lg-12 col-md-9">
                      <div class="d-flex align-items-center">
                        <div class="me-3">
                          <div class="card shadow-none">
                            <div class="card-body">
                              <a href="#">
                                <div class="border rounded border-gray mb-2">
                                  <img
                                    src="@/assets/admin/img/theme/light.svg"
                                    class="img-fluid rounded"
                                    alt="theme"
                                  />
                                </div>
                                <p class="text-dark text-center">Light</p>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="me-3">
                          <div class="card shadow-none">
                            <div class="card-body">
                              <a href="#">
                                <div class="border rounded border-gray mb-2">
                                  <img
                                    src="@/assets/admin/img/theme/dark.svg"
                                    class="img-fluid rounded"
                                    alt="theme"
                                  />
                                </div>
                                <p class="text-dark text-center">Dark</p>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="card shadow-none">
                            <div class="card-body">
                              <a href="#">
                                <div class="border rounded border-gray mb-2">
                                  <img
                                    src="@/assets/admin/img/theme/automatic.svg"
                                    class="img-fluid rounded"
                                    alt="theme"
                                  />
                                </div>
                                <p class="text-dark text-center">Automatic</p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-xl-3 col-lg-12 col-md-3">
                      <div class="setting-info mb-4">
                        <h6 class="fs-14 fw-medium">Accent Color</h6>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-12 col-md-4">
                      <div class="theme-colors mb-4">
                        <ul class="d-flex align-items-center">
                          <li v-for="(theme, index) in themes" :key="index">
                            <span
                              class="themecolorset"
                              @click="selectClick(theme)"
                              :class="{ active: activeTheme.color === theme.color }"
                            >
                              <span :class="'primecolor ' + theme.color">
                                <span class="colorcheck text-white">
                                  <i class="ti ti-check"></i>
                                </span>
                              </span>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center mb-4">
                    <div class="col-xl-3 col-lg-12 col-md-3">
                      <div class="">
                        <h6 class="fs-14 fw-medium">Sidebar Size</h6>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-12 col-md-3">
                      <vue-select :options="SelcSel" id="selcsel" placeholder="Select" />
                    </div>
                  </div>
                  <div class="row align-items-center mb-3">
                    <div class="col-xl-3 col-lg-12 col-md-3">
                      <div class="">
                        <h6 class="fs-14 fw-medium">Font Family</h6>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-12 col-md-3">
                      <vue-select
                        :options="NunitoSel"
                        id="nunitosel"
                        placeholder="Select"
                      />
                    </div>
                  </div>
                </div>
                <div class="card-footer mx-3 px-0">
                  <div class="d-flex align-items-center justify-content-end m-0">
                    <a href="#" class="btn btn-outline-primary me-2">Cancel</a>
                    <a href="#" class="btn btn-primary">Save</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- Autentication Settings -->
          </div>
        </div>
      </div>
      <!-- /App Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
