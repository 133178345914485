<template>
  <div class="card mb-3 mb-md-0">
    <div class="card-body setting-sidebar">
      <div class="d-flex">
        <router-link to="profile-settings" class="rounded flex-fill"
          ><i class="ti ti-user-circle me-2"></i>Profile Settings</router-link
        >
      </div>
      <div class="d-flex">
        <router-link to="change-password" class="rounded flex-fill"
          ><i class="ti ti-lock-cog me-2"></i>Change Password</router-link
        >
      </div>
      <div class="d-flex">
        <router-link to="notification-settings" class="rounded flex-fill"
          ><i class="ti ti-bell-ringing me-2"></i>Notification
        </router-link>
      </div>
    </div>
  </div>
</template>
