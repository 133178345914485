<script>
export default {
  data() {
    return {
      title: "Email Settings",
      text: "System Settings",
      text1: "Email Settings",
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- Profile Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-xl-3 col-md-4">
              <system-sidebar></system-sidebar>
            </div>
            <div class="col-xl-9 col-md-8">
              <div class="card setting-content mb-0">
                <div class="card-header px-0 mx-3">
                  <h4>Language</h4>
                </div>
                <div class="card-body pb-0">
                  <div class="card mb-3">
                    <div class="card-header">
                      <div class="row align-items-center g-3">
                        <div class="col-sm-5">
                          <h6>Language</h6>
                        </div>
                        <div class="col-sm-7">
                          <div
                            class="d-flex align-items-center justify-content-sm-end flex-wrap row-gap-2"
                          >
                            <router-link
                              to="language"
                              class="btn btn-sm btn-primary d-inline-flex align-items-center me-3"
                            >
                              <i class="ti ti-arrow-left me-2"></i>
                              Back to Translations
                            </router-link>
                            <a
                              href="#"
                              class="btn btn-sm btn-outline-dark d-inline-flex align-items-center me-3"
                            >
                              <img
                                src="@/assets/admin/img/flag/ar.png"
                                class="me-2"
                                alt=""
                              />
                              Arabic
                            </a>
                            <div class="flex-shrink-0 flex-fill">
                              <span class="d-block">Progress</span>
                              <div class="d-flex align-items-center">
                                <div class="progress progress-xs w-100">
                                  <div
                                    class="progress-bar bg-warning rounded"
                                    role="progressbar"
                                    style="width: 80%"
                                    aria-valuenow="80"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <span class="d-inline-flex fs-12 ms-2">80%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <div class="table-responsive">
                        <table class="table">
                          <thead class="thead-light">
                            <tr>
                              <th class="w-50">English</th>
                              <th class="w-50">Arabic</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Name</td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control text-end"
                                  value="اسم"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Email Address</td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control text-end"
                                  value="عنوان البريد الإلكتروني"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Phone Number</td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control text-end"
                                  value="رقم التليفون"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Reg Date</td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control text-end"
                                  value="تاريخ التسجيل"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Country</td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control text-end"
                                  value="دولة"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Last Seen</td>
                              <td>
                                <input
                                  type="text"
                                  class="form-control text-end"
                                  value="شوهد آخر مرة"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Profile Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
