<script>
export default {
  data() {
    return {
      title: "Notification",
      text: "General Settings",
      text1: "Notification",
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- General Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-xl-3 col-md-4">
              <general-sidebar></general-sidebar>
            </div>
            <!-- Notification Settings -->
            <div class="col-xl-9 col-md-8">
              <div class="card setting-content mb-0">
                <div class="card-header px-0 mx-3">
                  <h4>Notifications</h4>
                </div>
                <div class="card-body">
                  <div class="card mb-3">
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center mb-3">
                        <h6 class="fw-medium">Mobile Push Notifications</h6>
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked
                          />
                        </div>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-3">
                        <h6 class="fw-medium">Desktop Notifications</h6>
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked
                          />
                        </div>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-3">
                        <h6 class="fw-medium">Email Notifications</h6>
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked
                          />
                        </div>
                      </div>
                      <div class="d-flex justify-content-between align-items-center">
                        <h6 class="fw-medium">SMS Notification</h6>
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 class="mb-3">General Notifications</h5>
                  <div class="card notification-table mb-0">
                    <div class="card-body pb-0">
                      <div class="table-responsive">
                        <table class="table">
                          <thead class="thead-light">
                            <tr>
                              <th class="fw-semibold fs-18">Modules</th>
                              <th class="fw-semibold fs-18">Push</th>
                              <th class="fw-semibold fs-18">Email</th>
                              <th class="fw-semibold fs-18">SMS</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td><h6 class="fw-medium">Payment</h6></td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td><h6 class="fw-medium">Transaction</h6></td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td><h6 class="fw-medium">Email Verification</h6></td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td><h6 class="fw-medium">OTP</h6></td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td><h6 class="fw-medium">Activity</h6></td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td><h6 class="fw-medium">Account</h6></td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Notification Settings -->
          </div>
        </div>
      </div>
      <!-- /General Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
