<script>
export default {
  data() {
    return {};
  },
  methods: {
    submitForm() {
      this.$router.push("reset-password");
    },
  },
};
</script>
<template>
  <div class="container-fluid">
    <div class="login-wrapper">
      <header class="logo-header">
        <router-link to="index" class="logo-brand">
          <img
            src="@/assets/admin/img/full-logo.svg"
            alt="Logo"
            class="img-fluid logo-dark"
          />
        </router-link>
      </header>
      <div class="login-inbox">
        <div class="log-auth">
          <div class="login-auth-wrap">
            <div class="login-content-head">
              <h3>Forgot Password</h3>
              <p>Enter your email to get a password reset link</p>
            </div>
          </div>
          <form @submit.prevent="submitForm">
            <div class="form-group">
              <label class="form-label">Email <span>*</span></label>
              <input class="form-control" id="email" name="email" type="text" />
            </div>
            <button
              type="submit"
              class="btn btn-primary w-100 btn-size justify-content-center mb-3"
            >
              Reset Password
            </button>
            <div class="bottom-text">
              <p>
                Remember your password?
                <router-link to="/admin-template/">Login</router-link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
