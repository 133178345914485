<script>
const columns = [
  {
    title: "From",
    dataIndex: "From",
    key: "From",
    sorter: {
      compare: (a, b) => {
        a = a.From.toLowerCase();
        b = b.From.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "To",
    dataIndex: "To",
    key: "To",
    sorter: {
      compare: (a, b) => {
        a = a.To.toLowerCase();
        b = b.To.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total Chat Count",
    dataIndex: "Total_Chat_Count",
    sorter: {
      compare: (a, b) => {
        a = a.Total_Chat_Count.toLowerCase();
        b = b.Total_Chat_Count.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "Action",
    sorter: false,
  },
];

const data = [
  {
    From_Img: "user-01.jpg",
    From: "Aaryian Jose",
    To: "Charles Sellars",
    To_Img: "user-11.jpg",
    Total_Chat_Count: "105",
  },
  {
    From_Img: "user-02.jpg",
    From: "Sarika Jain",
    To_Img: "user-12.jpg",
    To: "Richard Ohare",
    Total_Chat_Count: "120",
  },
  {
    From_Img: "user-03.jpg",
    From: "Clyde Smith",
    To_Img: "user-13.jpg",
    To: "Judy Mercer",
    Total_Chat_Count: "200",
  },
  {
    From_Img: "user-04.jpg",
    From: "Carla Jenkins",
    To_Img: "user-14.jpg",
    To: "Michael Howard",
    Total_Chat_Count: "250",
  },
  {
    From_Img: "user-05.jpg",
    From: "Federico Wells",
    To_Img: "user-15.jpg",
    To: "Gilbert Humphrey",
    Total_Chat_Count: "300",
  },
  {
    From_Img: "user-06.jpg",
    From: "Edward Lietz",
    To_Img: "user-16.jpg",
    To: "Christopher Hurt",
    Total_Chat_Count: "180",
  },
  {
    From_Img: "user-07.jpg",
    From: "Sharon Ford",
    To_Img: "user-17.jpg",
    To: "Chasity Williams",
    Total_Chat_Count: "200",
  },
  {
    From_Img: "user-08.jpg",
    From: "Thomas Rethman",
    To_Img: "user-18.jpg",
    To: "Harry Newton",
    Total_Chat_Count: "320",
  },
  {
    From_Img: "user-09.jpg",
    From: "Wilbur Martinez",
    To_Img: "user-19.jpg",
    To: "John Gonzalez",
    Total_Chat_Count: "400",
  },
  {
    From_Img: "user-10.jpg",
    From: "Danielle Baker",
    To_Img: "user-20.jpg",
    To: "Pearl Dewitt",
    Total_Chat_Count: "210",
  },
];
const rowSelection = {
  onChange: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
};

export default {
  data() {
    return {
      data,
      columns,
      rowSelection,
    };
  },
};
</script>

<template>
  <div class="custom-datatable-filter table-responsive">
    <a-table
      class="table datatable thead-light"
      :columns="columns"
      :data-source="data"
      :row-selection="rowSelection"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'From'">
          <div class="d-flex align-items-center">
            <a href="#" class="avatar avatar-md">
              <img
                :src="require(`@/assets/admin/img/users/${record.From_Img}`)"
                class="img-fluid rounded-circle"
                alt="img"
              />
            </a>
            <div class="ms-2 profile-name">
              <p class="text-dark mb-0">
                <a href="#">{{ record.From }}</a>
              </p>
            </div>
          </div>
        </template>
        <template v-if="column.key === 'To'">
          <div class="d-flex align-items-center">
            <a href="#" class="avatar avatar-md">
              <img
                :src="require(`@/assets/admin/img/users/${record.To_Img}`)"
                class="img-fluid rounded-circle"
                alt="img"
              />
            </a>
            <div class="ms-2 profile-name">
              <p class="text-dark mb-0">
                <a href="#">{{ record.To }}</a>
              </p>
            </div>
          </div>
        </template>
        <template v-if="column.key === 'Action'">
          <a href="#" data-bs-toggle="modal" data-bs-target="#delete-modal"
            ><span class="file-icon"><i class="ti ti-trash"></i></span
          ></a>
        </template>
      </template>
    </a-table>
  </div>

  <admin-chat-modal></admin-chat-modal>
</template>