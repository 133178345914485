<script>
export default {
  data() {
    return {
      title: "Sms Settings",
      text: "System Settings",
      text1: "Sms Settings",
    };
  },
  methods: {
    submitForm() {
      this.$router.push("sms-settings");
    },
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- Profile Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-xl-3 col-md-4">
              <system-sidebar></system-sidebar>
            </div>
            <div class="col-xl-9 col-md-8">
              <div class="card setting-content mb-0">
                <div class="card-header px-0 mx-3">
                  <h4>SMS Settings</h4>
                </div>
                <div class="card-body pb-0">
                  <div class="row gx-3">
                    <div class="col-lg-4 col-md-6">
                      <div class="card mb-3">
                        <div class="card-body">
                          <div class="d-flex align-items-center justify-content-between">
                            <span
                              ><img
                                src="@/assets/admin/img/icon/sms-settings-01.svg"
                                alt=""
                            /></span>
                            <div class="d-flex align-items-center">
                              <a
                                href="#"
                                class="d-inline-flex me-2"
                                data-bs-toggle="modal"
                                data-bs-target="#sms_mail"
                              >
                                <i class="ti ti-settings"></i>
                              </a>
                              <div class="form-check form-switch">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="card mb-3">
                        <div class="card-body">
                          <div class="d-flex align-items-center justify-content-between">
                            <span
                              ><img
                                src="@/assets/admin/img/icon/sms-settings-02.svg"
                                alt=""
                            /></span>
                            <div class="d-flex align-items-center">
                              <a
                                href="#"
                                class="d-inline-flex me-2"
                                data-bs-toggle="modal"
                                data-bs-target="#sms_mail"
                              >
                                <i class="ti ti-settings"></i>
                              </a>
                              <div class="form-check form-switch">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <div class="card mb-3">
                        <div class="card-body">
                          <div class="d-flex align-items-center justify-content-between">
                            <span
                              ><img
                                src="@/assets/admin/img/icon/sms-settings-03.svg"
                                alt=""
                            /></span>
                            <div class="d-flex align-items-center">
                              <a
                                href="#"
                                class="d-inline-flex me-2"
                                data-bs-toggle="modal"
                                data-bs-target="#sms_mail"
                              >
                                <i class="ti ti-settings"></i>
                              </a>
                              <div class="form-check form-switch">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Profile Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
  <!-- Php Mail -->
  <div class="modal fade" id="sms_mail">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Nexmo</h4>
          <button
            type="button"
            class="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ti ti-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="mb-3">
              <label class="form-label">API Key </label>
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">API Secret Key </label>
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">Sender ID </label>
              <input type="text" class="form-control" />
            </div>
            <div class="row gx-3">
              <div class="col-6">
                <button
                  type="button"
                  class="btn btn-outline-primary w-100"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
              <div class="col-6">
                <button
                  type="submit"
                  class="btn btn-primary w-100"
                  data-bs-dismiss="modal"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Php Mail -->
</template>
