<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper cardhead">
    <div class="content">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <h3 class="page-title">Range Slider</h3>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <!-- Rangeslider -->
        <div class="col-md-6" v-for="slider in sliders" :key="slider.id">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">{{ slider.title }}</h5>
            </div>
            <div class="card-body">
              <a-slider
                v-model="slider.value"
                :min="slider.min"
                :max="slider.max"
                :step="slider.step"
                :marks="slider.marks"
              />
              <div style="margin-top: 10px">Value: {{ slider.value }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>

<script>
import { ref } from "vue";
import { Row, Col, Card, Slider } from "ant-design-vue";

export default {
  name: "RangeSliders",
  components: {
    ARow: Row,
    ACol: Col,
    ACard: Card,
    ASlider: Slider,
  },
  setup() {
    const sliders = ref([
      { id: 1, title: "Default", value: 30, min: 0, max: 100 },
      { id: 2, title: "Min-Max", value: 50, min: 0, max: 100 },
      { id: 3, title: "Prefix", value: 20, min: 0, max: 100 },
      { id: 4, title: "Range", value: 40, min: 0, max: 100 },
      { id: 5, title: "Step", value: 25, min: 0, max: 100, step: 5 },
      { id: 6, title: "Custom Values", value: 70, min: 0, max: 100 },
      { id: 7, title: "Modern Skin", value: 60, min: 0, max: 100 },
      { id: 8, title: "Sharp Skin", value: 80, min: 0, max: 100 },
      { id: 9, title: "Round Skin", value: 90, min: 0, max: 100 },
      { id: 10, title: "Square Skin", value: 10, min: 0, max: 100 },
    ]);

    return {
      sliders,
    };
  },
};
</script>
