<script>
export default {
  data() {
    return {
      title: "Social Authentication",
      text: "App Settings",
      text1: "Social Authentication",
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- Social-Authentication Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-xl-3 col-md-4">
              <app-sidebar></app-sidebar>
            </div>
            <!-- Social Autentication -->
            <div class="col-xl-9 col-md-8">
              <div class="card setting-content mb-0">
                <div class="card-header px-0 mx-3">
                  <h4>Social Authentication</h4>
                </div>
                <div class="card-body pb-0">
                  <div class="company-img pt-0">
                    <div class="row gx-3">
                      <div class="col-xl-6 col-sm-12">
                        <div class="card social-auth mb-3">
                          <div class="card-body">
                            <div class="header-section">
                              <div class="company-img-title">
                                <div class="social-icons">
                                  <span
                                    ><img
                                      src="@/assets/admin/img/Settings/google-icon.svg"
                                      alt="icons"
                                      class="img-fluid"
                                  /></span>
                                  <h6>Google</h6>
                                </div>
                                <span class="badge badge-success">Connected</span>
                              </div>
                              <p>
                                Sign in securely with your Google account for quick and
                                easy access.
                              </p>
                            </div>
                            <div class="body-footer">
                              <div class="footer-content">
                                <a
                                  href="#"
                                  class="btn btn-sm btn-light"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add_google"
                                  >View Integration</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-6 col-sm-12">
                        <div class="card social-auth mb-3">
                          <div class="card-body">
                            <div class="header-section">
                              <div class="company-img-title">
                                <div class="social-icons">
                                  <span
                                    ><img
                                      src="@/assets/admin/img/Settings/face-book-icons.svg"
                                      alt="icons"
                                      class="img-fluid"
                                  /></span>
                                  <h6>Facebook</h6>
                                </div>
                                <span class="badge badge-light text-dark"
                                  >Not Connected</span
                                >
                              </div>
                              <p>
                                Connect easily using your Facebook account for fast and
                                secure access.
                              </p>
                            </div>
                            <div class="body-footer">
                              <div class="footer-content">
                                <a
                                  href="#"
                                  class="btn btn-sm btn-light"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add_facebook"
                                  >View Integration</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-6 col-sm-12">
                        <div class="card social-auth mb-3">
                          <div class="card-body">
                            <div class="header-section">
                              <div class="company-img-title">
                                <div class="social-icons">
                                  <span
                                    ><img
                                      src="@/assets/admin/img/Settings/apple-icons.svg"
                                      alt="icons"
                                      class="img-fluid"
                                  /></span>
                                  <h6>Apple</h6>
                                </div>
                                <span class="badge badge-success">Connected</span>
                              </div>
                              <p>
                                Sign in securely with your Apple ID for a seamless and
                                private login experience
                              </p>
                            </div>
                            <div class="body-footer">
                              <div class="footer-content">
                                <a
                                  href="#"
                                  class="btn btn-sm btn-light"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add_apple"
                                  >Connect Now</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Social Autentication -->
          </div>
        </div>
      </div>
      <!-- /Social-Authentication Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
  <social-auth-modal></social-auth-modal>
</template>
