<script>
export default {
  data() {
    return {};
  },
  methods: {
    submitForm() {
      this.$router.push("social-auth");
    },
  },
};
</script>
<template>
  <!-- Google -->
  <div class="modal fade" id="add_google">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Google Login Settings</h4>
          <button
            type="button"
            class="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ti ti-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Client ID </label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Consumer Secret (Secret Key) </label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-12">
                <div>
                  <label class="form-label">Login Redirect URL </label>
                  <input type="email" class="form-control" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <div class="d-flex w-100 justify-content-between">
              <a
                href="#"
                class="btn btn-outline-primary me-2 d-flex justify-content-center w-100"
                data-bs-dismiss="modal"
                >Cancel</a
              >
              <button
                type="submit"
                class="btn btn-primary d-flex justify-content-center w-100"
                data-bs-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Google -->

  <!-- Facebook -->
  <div class="modal fade" id="add_facebook">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Facebook Login Settings</h4>
          <button
            type="button"
            class="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ti ti-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Consumer Key (API Key) </label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Consumer Secret (Secret Key) </label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-12">
                <div>
                  <label class="form-label">Login Redirect URL </label>
                  <input type="email" class="form-control" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <div class="d-flex w-100 justify-content-between">
              <a
                href="#"
                class="btn btn-outline-primary me-2 d-flex justify-content-center w-100"
                data-bs-dismiss="modal"
                >Cancel</a
              >
              <button
                type="submit"
                class="btn btn-primary d-flex justify-content-center w-100"
                data-bs-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Facebook -->

  <!-- Apple -->
  <div class="modal fade" id="add_apple">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Apple Login Settings</h4>
          <button
            type="button"
            class="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ti ti-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Client ID </label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Consumer Secret (Secret Key) </label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-12">
                <div>
                  <label class="form-label">Login Redirect URL </label>
                  <input type="email" class="form-control" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <div class="d-flex w-100 justify-content-between">
              <a
                href="#"
                class="btn btn-outline-primary me-2 d-flex justify-content-center w-100"
                data-bs-dismiss="modal"
                >Cancel</a
              >
              <button
                type="submit"
                class="btn btn-primary d-flex justify-content-center w-100"
                data-bs-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Apple -->
</template>
