<script>
const columns = [
  {
    title: "Group Name",
    dataIndex: "Group_Name",
    key: "Group_Name",
    sorter: {
      compare: (a, b) => {
        a = a.Group_Name.toLowerCase();
        b = b.Group_Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Group Description",
    dataIndex: "Group_Description",
    sorter: {
      compare: (a, b) => {
        a = a.Group_Description.toLowerCase();
        b = b.Group_Description.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Members",
    dataIndex: "Members",
    key: "Members",
    sorter: {
      compare: (a, b) => {
        a = a.Members.toLowerCase();
        b = b.Members.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total Chat Count",
    dataIndex: "Total_Chat_Count",
    sorter: {
      compare: (a, b) => {
        a = a.Total_Chat_Count.toLowerCase();
        b = b.Total_Chat_Count.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Created Date",
    dataIndex: "Created_Date",
    sorter: {
      compare: (a, b) => {
        a = a.Created_Date.toLowerCase();
        b = b.Created_Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "Action",
    sorter: false,
  },
];

const data = [
  {
    Group_Img: "group-01.jpg",
    Group_Name: "The Dream Team",
    Group_Description:
      "Design enthusiasts unite! Share, learn, and create together!",
    Img_One: "user-01.jpg",
    Img_Two: "user-02.jpg",
    Img_Three: "user-03.jpg",
    Img_Four: "user-04.jpg",
    Img_Five: "user-05.jpg",
    Members: "35+",
    Total_Chat_Count: "105",
    Created_Date: "02 Sep 2024, 10:00 AM",
  },
  {
    Group_Img: "group-02.jpg",
    Group_Name: "The Meme Team",
    Group_Description: "Welcome to the Meme Team! Share and enjoy memes!",
    Img_One: "user-06.jpg",
    Img_Two: "user-07.jpg",
    Img_Three: "user-08.jpg",
    Img_Four: "user-09.jpg",
    Img_Five: "user-10.jpg",
    Members: "40+",
    Total_Chat_Count: "120",
    Created_Date: "14 Sep 2024, 11:30 AM",
  },
  {
    Group_Img: "group-03.jpg",
    Group_Name: "Tech Talk Tribe",
    Group_Description: "Share insights, ask questions, and explore new trends!",
    Img_One: "user-03.jpg",
    Img_Two: "user-02.jpg",
    Img_Three: "user-01.jpg",
    Img_Four: "user-08.jpg",
    Img_Five: "user-05.jpg",
    Members: "20+",
    Total_Chat_Count: "200",
    Created_Date: "28 Sep 2024, 08:15 AM",
  },
  {
    Group_Img: "group-04.jpg",
    Group_Name: "Game Changers",
    Group_Description: "Game on! Share tips, play together, and have fun!",
    Img_One: "user-04.jpg",
    Img_Two: "user-09.jpg",
    Img_Three: "user-06.jpg",
    Img_Four: "user-01.jpg",
    Img_Five: "user-02.jpg",
    Members: "15+",
    Total_Chat_Count: "250",
    Created_Date: "12 Oct 2024, 06:40 PM",
  },
  {
    Group_Img: "group-05.jpg",
    Group_Name: "The Chill Zone",
    Group_Description: "Chill vibes only! Relax, chat, and unwind.",
    Img_One: "user-01.jpg",
    Img_Two: "user-02.jpg",
    Img_Three: "user-03.jpg",
    Img_Four: "user-04.jpg",
    Img_Five: "user-09.jpg",
    Members: "30+",
    Total_Chat_Count: "300",
    Created_Date: "20 Oct 2024, 04:18 PM",
  },
  {
    Group_Img: "group-06.jpg",
    Group_Name: "Squad Goals",
    Group_Description: "Squad goals! Connect, plan, and have a blast together.",
    Img_One: "user-05.jpg",
    Img_Two: "user-08.jpg",
    Img_Three: "user-06.jpg",
    Img_Four: "user-10.jpg",
    Img_Five: "user-04.jpg",
    Members: "25+",
    Total_Chat_Count: "180",
    Created_Date: "30 Oct 2024, 07:25 AM",
  },
  {
    Group_Img: "group-07.jpg",
    Group_Name: "Scholars Society",
    Group_Description:
      "Share knowledge, discuss ideas, and support each other.",
    Img_One: "user-01.jpg",
    Img_Two: "user-02.jpg",
    Img_Three: "user-03.jpg",
    Img_Four: "user-04.jpg",
    Img_Five: "user-05.jpg",
    Members: "28+",
    Total_Chat_Count: "200",
    Created_Date: "01 Nov 2024, 08:50 AM",
  },
  {
    Group_Img: "group-08.jpg",
    Group_Name: "Travel Tribe",
    Group_Description:
      '"Explore together! Share travel tips, stories, and adventures"',
    Img_One: "user-03.jpg",
    Img_Two: "user-08.jpg",
    Img_Three: "user-01.jpg",
    Img_Four: "user-04.jpg",
    Img_Five: "user-03.jpg",
    Members: "35+",
    Total_Chat_Count: "320",
    Created_Date: "10 Nov 2024, 11:20 AM",
  },
  {
    Group_Img: "group-09.jpg",
    Group_Name: "Fitness Fanatics",
    Group_Description:
      "Get fit together! Share workouts, tips, and motivation.",
    Img_One: "user-01.jpg",
    Img_Two: "user-02.jpg",
    Img_Three: "user-03.jpg",
    Img_Four: "user-09.jpg",
    Img_Five: "user-07.jpg",
    Members: "40+",
    Total_Chat_Count: "400",
    Created_Date: "17 Nov 2024, 04:50 PM",
  },
  {
    Group_Img: "group-10.jpg",
    Group_Name: "Movie Buffs",
    Group_Description:
      "Movie buffs unite! Discuss, review, and enjoy films together.",
    Img_One: "user-05.jpg",
    Img_Two: "user-02.jpg",
    Img_Three: "user-03.jpg",
    Img_Four: "user-09.jpg",
    Img_Five: "user-07.jpg",
    Members: "50+",
    Total_Chat_Count: "210",
    Created_Date: "12 Dec 2024, 09:30 AM",
  },
];

const rowSelection = {
  onChange: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
};


export default {
  data() {
    return {
        data,
        columns,
        rowSelection,
    };
  },
};
</script>

<template>
  <div class="custom-datatable-filter table-responsive">
    <a-table
      class="table datatable thead-light"
      :columns="columns"
      :data-source="data"
      :row-selection="rowSelection"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'Group_Name'">
          <div class="d-flex align-items-center">
            <a href="javascript:void(0)" class="avatar avatar-md">
              <img
                :src="require(`@/assets/admin/img/group-chat/${record.Group_Img}`)"
                class="img-fluid rounded-circle"
                alt="img"
              />
            </a>
            <div class="ms-2 profile-name">
              <p class="text-dark mb-0">
                <a href="#">{{ record.Group_Name }}</a>
              </p>
            </div>
          </div>
        </template>
        <template v-if="column.key === 'Members'">
          <div class="avatar-list-stacked avatar-group-sm">
            <span class="avatar border-0">
              <img
                :src="require(`@/assets/admin/img/users/${record.Img_One}`)"
                class="rounded-circle"
                alt="img"
              />
            </span>
            <span class="avatar border-0">
              <img
                :src="require(`@/assets/admin/img/users/${record.Img_Two}`)"
                class="rounded-circle"
                alt="img"
              />
            </span>
            <span class="avatar border-0">
              <img
                :src="require(`@/assets/admin/img/users/${record.Img_Three}`)"
                class="rounded-circle"
                alt="img"
              />
            </span>
            <span class="avatar border-0">
              <img
                :src="require(`@/assets/admin/img/users/${record.Img_Four}`)"
                class="rounded-circle"
                alt="img"
              />
            </span>
            <span class="avatar border-0">
              <img
                :src="require(`@/assets/admin/img/users/${record.Img_Five}`)"
                class="rounded-circle"
                alt="img"
              />
            </span>
            <span
              class="avatar group-counts bg-primary rounded-circle border-0 fs-10"
            >
              35+
            </span>
          </div>
        </template>
        <template v-if="column.key === 'Action'">
          <a href="#" data-bs-toggle="modal" data-bs-target="#delete-modal">
            <span class="file-icon"><i class="ti ti-trash"></i></span>
          </a>
        </template>
      </template>
    </a-table>
  </div>
</template>