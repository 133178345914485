<template>
    <div class="chat-users-wrap">
        <div class="chat-list" >
            <router-link to="chat" class="chat-user-list">
                <div class="avatar avatar-lg online me-2">
                    <img src="@/assets/img/profiles/avatar-01.jpg" class="rounded-circle border border-warning border-2" alt="image">
                </div>
                <div class="chat-user-info">
                    <div class="chat-user-msg">
                        <h6>Mark Villiams</h6>
                        <p><span class="animate-typing">is typing
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <span class="dot"></span>
                            </span>
                        </p>
                    </div>
                    <div class="chat-user-time">
                        <span class="time">02:40 PM</span>
                        <div class="chat-pin">
                            <i class="ti ti-pin me-2"></i>
                            <span class="count-message fs-12 fw-semibold">12</span>
                        </div>
                    </div>    
                </div>
            </router-link>                        
            <div class="chat-dropdown">
                <a class="#" href="#" data-bs-toggle="dropdown">
                    <i class="ti ti-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end p-3">
                    <li><a class="dropdown-item" href="#"><i class="ti ti-box-align-right me-2"></i>Archive Chat</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-heart me-2"></i>Mark as Favourite</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-check me-2"></i>Mark as Unread</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-pinned me-2"></i>Pin Chats</a></li>
                    <li><a class="dropdown-item" href="#"  data-bs-toggle="modal" data-bs-target="#delete-chat"><i class="ti ti-trash me-2"></i>Delete</a></li>
                </ul>
            </div>     
        </div>    
        <div class="chat-list" > 
            <router-link to="chat" class="chat-user-list">
                <div class="avatar avatar-lg online me-2">
                    <img src="@/assets/img/profiles/avatar-02.jpg" class="rounded-circle" alt="image">
                </div>
                <div class="chat-user-info">
                    <div class="chat-user-msg">
                        <h6>Sarika Jain</h6>
                        <p>Do you know which...</p>
                    </div>
                    <div class="chat-user-time">
                        <span class="time">06:12 AM</span>
                        <div class="chat-pin">
                            <i class="ti ti-pin me-2"></i>
                            <i class="ti ti-checks text-success"></i>
                        </div>
                    </div>    
                </div>
            </router-link>                    
            <div class="chat-dropdown">
                <a class="#" href="#" data-bs-toggle="dropdown">
                    <i class="ti ti-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end p-3">
                    <li><a class="dropdown-item" href="#"><i class="ti ti-box-align-right me-2"></i>Archive Chat</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-heart me-2"></i>Mark as Favourite</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-check me-2"></i>Mark as Unread</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-pinned me-2"></i>Pin Chats</a></li>
                    <li><a class="dropdown-item" href="#"  data-bs-toggle="modal" data-bs-target="#delete-chat"><i class="ti ti-trash me-2"></i>Delete</a></li>
                </ul>
            </div>     
        </div>    
        <div class="chat-list" >
            <router-link to="chat" class="chat-user-list">
                <div class="avatar avatar-lg online me-2">
                    <img src="@/assets/img/profiles/avatar-03.jpg" class="rounded-circle" alt="image">
                </div>
                <div class="chat-user-info">
                    <div class="chat-user-msg">
                        <h6>Clyde Smith</h6>
                        <p>Haha oh man 🔥</p>
                    </div>
                    <div class="chat-user-time">
                        <span class="time">03:15 AM</span>
                        <div class="chat-pin">
                            <i class="ti ti-pin me-2"></i>
                            <span class="count-message fs-12 fw-semibold">55</span>
                        </div>
                    </div>    
                </div>
            </router-link>                    
            <div class="chat-dropdown">
                <a class="#" href="#" data-bs-toggle="dropdown">
                    <i class="ti ti-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end p-3">
                    <li><a class="dropdown-item" href="#"><i class="ti ti-box-align-right me-2"></i>Archive Chat</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-heart me-2"></i>Mark as Favourite</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-check me-2"></i>Mark as Unread</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-pinned me-2"></i>Pin Chats</a></li>
                    <li><a class="dropdown-item" href="#"  data-bs-toggle="modal" data-bs-target="#delete-chat"><i class="ti ti-trash me-2"></i>Delete</a></li>
                </ul>
            </div>     
        </div>    
        <div class="chat-list" >
            <router-link to="chat" class="chat-user-list">
                <div class="avatar avatar-lg bg-pink avatar-rounded me-2">
                    <span class="avatar-title fs-14 fw-medium">AG</span>
                </div>
                <div class="chat-user-info">
                    <div class="chat-user-msg">
                        <h6>Amfr_boys_Group</h6>
                        <p><i class="ti ti-photo me-2"></i>Photo</p>
                    </div>
                    <div class="chat-user-time">
                        <span class="time">Yesterday</span>
                        <div class="chat-pin">
                            <span class="count-message fs-12 fw-semibold">5</span>
                        </div>
                    </div>    
                </div>
            </router-link>                    
            <div class="chat-dropdown">
                <a class="#" href="#" data-bs-toggle="dropdown">
                    <i class="ti ti-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end p-3">
                    <li><a class="dropdown-item" href="#"><i class="ti ti-box-align-right me-2"></i>Archive Chat</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-heart me-2"></i>Mark as Favourite</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-check me-2"></i>Mark as Unread</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-pinned me-2"></i>Pin Chats</a></li>
                    <li><a class="dropdown-item" href="#"  data-bs-toggle="modal" data-bs-target="#delete-chat"><i class="ti ti-trash me-2"></i>Delete</a></li>
                </ul>
            </div>     
        </div>    
        <div class="chat-list" >
            <router-link to="chat" class="chat-user-list">
                <div class="avatar avatar-lg online me-2">
                    <img src="@/assets/img/profiles/avatar-04.jpg" class="rounded-circle" alt="image">
                </div>
                <div class="chat-user-info">
                    <div class="chat-user-msg">
                        <h6>Carla Jenkins</h6>
                        <p class="text-success"><i class="ti ti-video-plus me-2"></i>Incoming Video Call</p>
                    </div>
                    <div class="chat-user-time">
                        <span class="time">Sunday</span>
                        <div class="chat-pin">
                            <i class="ti ti-checks text-success"></i>
                        </div>
                    </div>    
                </div>
            </router-link>                    
            <div class="chat-dropdown">
                <a class="#" href="#" data-bs-toggle="dropdown">
                    <i class="ti ti-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end p-3">
                    <li><a class="dropdown-item" href="#"><i class="ti ti-box-align-right me-2"></i>Archive Chat</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-heart me-2"></i>Mark as Favourite</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-check me-2"></i>Mark as Unread</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-pinned me-2"></i>Pin Chats</a></li>
                    <li><a class="dropdown-item" href="#"  data-bs-toggle="modal" data-bs-target="#delete-chat"><i class="ti ti-trash me-2"></i>Delete</a></li>
                </ul>
            </div>     
        </div>    
        <div class="chat-list" >
            <router-link to="chat" class="chat-user-list">
                <div class="avatar avatar-lg online me-2">
                    <img src="@/assets/img/profiles/avatar-05.jpg" class="rounded-circle" alt="image">
                </div>
                <div class="chat-user-info">
                    <div class="chat-user-msg">
                        <h6>Federico Wells</h6>
                        <p><i class="ti ti-photo me-2"></i>Photo</p>
                    </div>
                    <div class="chat-user-time">
                        <span class="time">Wednesday</span>
                        <div class="chat-pin">
                            <i class="ti ti-pin me-2"></i>
                            <span class="count-message fs-12 fw-semibold">12</span>
                            <i class="bx bx-check-double"></i>
                        </div>
                    </div>    
                </div>
            </router-link>                    
            <div class="chat-dropdown">
                <a class="#" href="#" data-bs-toggle="dropdown">
                    <i class="ti ti-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end p-3">
                    <li><a class="dropdown-item" href="#"><i class="ti ti-box-align-right me-2"></i>Archive Chat</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-heart me-2"></i>Mark as Favourite</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-check me-2"></i>Mark as Unread</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-pinned me-2"></i>Pin Chats</a></li>
                    <li><a class="dropdown-item" href="#"  data-bs-toggle="modal" data-bs-target="#delete-chat"><i class="ti ti-trash me-2"></i>Delete</a></li>
                </ul>
            </div>     
        </div>    
        <div class="chat-list" >
            <router-link to="chat" class="chat-user-list">
                <div class="avatar avatar-lg online me-2">
                    <img src="@/assets/img/profiles/avatar-06.jpg" class="rounded-circle" alt="image">
                </div>
                <div class="chat-user-info">
                    <div class="chat-user-msg">
                        <h6>Edward Lietz</h6>
                        <p><i class="ti ti-file me-2"></i>Document</p>
                    </div>
                    <div class="chat-user-time">
                        <span class="time">02:40 PM</span>
                        <div class="chat-pin">
                            <i class="ti ti-checks text-success"></i>
                        </div>
                    </div>    
                </div>
            </router-link>                    
            <div class="chat-dropdown">
                <a class="#" href="#" data-bs-toggle="dropdown">
                    <i class="ti ti-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end p-3">
                    <li><a class="dropdown-item" href="#"><i class="ti ti-box-align-right me-2"></i>Archive Chat</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-heart me-2"></i>Mark as Favourite</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-check me-2"></i>Mark as Unread</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-pinned me-2"></i>Pin Chats</a></li>
                    <li><a class="dropdown-item" href="#"  data-bs-toggle="modal" data-bs-target="#delete-chat"><i class="ti ti-trash me-2"></i>Delete</a></li>
                </ul>
            </div>     
        </div>  
        <div class="chat-list" >
            <router-link to="chat" class="chat-user-list">
                <div class="avatar avatar-lg bg-skyblue online avatar-rounded me-2">
                    <span class="avatar-title fs-14 fw-medium">GU</span>
                </div>
                <div class="chat-user-info">
                    <div class="chat-user-msg">
                        <h6>Gustov_family</h6>
                        <p>Please Check<span class="text-primary ms-1">@rev</span></p>
                    </div>
                    <div class="chat-user-time">
                        <span class="time">24 Jul 2024</span>
                        <div class="chat-pin">
                            <i class="ti ti-heart-filled text-warning me-2"></i>
                            <span class="count-message fs-12 fw-semibold">25</span>
                        </div>
                    </div>    
                </div>
            </router-link>                    
            <div class="chat-dropdown">
                <a class="#" href="#" data-bs-toggle="dropdown">
                    <i class="ti ti-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end p-3">
                    <li><a class="dropdown-item" href="#"><i class="ti ti-box-align-right me-2"></i>Archive Chat</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-heart me-2"></i>Mark as Favourite</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-check me-2"></i>Mark as Unread</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-pinned me-2"></i>Pin Chats</a></li>
                    <li><a class="dropdown-item" href="#"  data-bs-toggle="modal" data-bs-target="#delete-chat"><i class="ti ti-trash me-2"></i>Delete</a></li>
                </ul>
            </div>     
        </div>    
        <div class="chat-list" >
            <router-link to="chat" class="chat-user-list">
                <div class="avatar avatar-lg online me-2">
                    <img src="@/assets/img/profiles/avatar-07.jpg" class="rounded-circle" alt="image">
                </div>
                <div class="chat-user-info">
                    <div class="chat-user-msg">
                        <h6>Estell Gibson</h6>
                        <p class="text-danger"><i class="ti ti-video-off me-2"></i>Missed Video Call</p>
                    </div>
                    <div class="chat-user-time">
                        <span class="time">02:40 PM</span>
                        <div class="chat-pin">
                            <i class="ti ti-heart-filled text-warning me-2"></i>
                            <i class="ti ti-checks text-success"></i>
                        </div>
                    </div>    
                </div>
            </router-link>                    
            <div class="chat-dropdown">
                <a class="#" href="#" data-bs-toggle="dropdown">
                    <i class="ti ti-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end p-3">
                    <li><a class="dropdown-item" href="#"><i class="ti ti-box-align-right me-2"></i>Archive Chat</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-heart me-2"></i>Mark as Favourite</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-check me-2"></i>Mark as Unread</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-pinned me-2"></i>Pin Chats</a></li>
                    <li><a class="dropdown-item" href="#"  data-bs-toggle="modal" data-bs-target="#delete-chat"><i class="ti ti-trash me-2"></i>Delete</a></li>
                </ul>
            </div>     
        </div>   
        <div class="chat-list" >
            <router-link to="chat" class="chat-user-list">
                <div class="avatar avatar-lg online me-2">
                    <img src="@/assets/img/profiles/avatar-08.jpg" class="rounded-circle" alt="image">
                </div>
                <div class="chat-user-info">
                    <div class="chat-user-msg">
                        <h6>Sharon Ford</h6>
                        <p>Hi How are you 🔥</p>
                    </div>
                    <div class="chat-user-time">
                        <span class="time">02:40 PM</span>
                        <div class="chat-pin">
                            <span class="count-message fs-12 fw-semibold">25</span>
                        </div>
                    </div>    
                </div>
            </router-link>                    
            <div class="chat-dropdown">
                <a class="#" href="#" data-bs-toggle="dropdown">
                    <i class="ti ti-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end p-3">
                    <li><a class="dropdown-item" href="#"><i class="ti ti-box-align-right me-2"></i>Archive Chat</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-heart me-2"></i>Mark as Favourite</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-check me-2"></i>Mark as Unread</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-pinned me-2"></i>Pin Chats</a></li>
                    <li><a class="dropdown-item" href="#"  data-bs-toggle="modal" data-bs-target="#delete-chat"><i class="ti ti-trash me-2"></i>Delete</a></li>
                </ul>
            </div>     
        </div>   
        <div class="chat-list" >
            <router-link to="chat" class="chat-user-list">
                <div class="avatar avatar-lg online me-2">
                    <img src="@/assets/img/profiles/avatar-09.jpg" class="rounded-circle" alt="image">
                </div>
                <div class="chat-user-info">
                    <div class="chat-user-msg">
                        <h6>Thomas Rethman</h6>
                        <p>Do you know which...</p>
                    </div>
                    <div class="chat-user-time">
                        <span class="time">02:40 PM</span>
                        <div class="chat-pin">
                            <i class="ti ti-heart-filled text-warning"></i>
                        </div>
                    </div>    
                </div>
            </router-link>                    
            <div class="chat-dropdown">
                <a class="#" href="#" data-bs-toggle="dropdown">
                    <i class="ti ti-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end p-3">
                    <li><a class="dropdown-item" href="#"><i class="ti ti-box-align-right me-2"></i>Archive Chat</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-heart me-2"></i>Mark as Favourite</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-check me-2"></i>Mark as Unread</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-pinned me-2"></i>Pin Chats</a></li>
                    <li><a class="dropdown-item" href="#"  data-bs-toggle="modal" data-bs-target="#delete-chat"><i class="ti ti-trash me-2"></i>Delete</a></li>
                </ul>
            </div>     
        </div>   
        <div class="chat-list" >
            <router-link to="chat" class="chat-user-list">
                <div class="avatar avatar-lg online me-2">
                    <img src="@/assets/img/profiles/avatar-10.jpg" class="rounded-circle" alt="image">
                </div>
                <div class="chat-user-info">
                    <div class="chat-user-msg">
                        <h6>Wilbur Martinez</h6>
                        <p>Haha oh man 🔥</p>
                    </div>
                    <div class="chat-user-time">
                        <span class="time">02:40 PM</span>
                        <div class="chat-pin">
                            <i class="ti ti-pin me-2"></i>
                            <i class="ti ti-checks text-success"></i>
                        </div>
                    </div>    
                </div>
            </router-link>                    
            <div class="chat-dropdown">
                <a class="#" href="#" data-bs-toggle="dropdown">
                    <i class="ti ti-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end p-3">
                    <li><a class="dropdown-item" href="#"><i class="ti ti-box-align-right me-2"></i>Archive Chat</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-heart me-2"></i>Mark as Favourite</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-check me-2"></i>Mark as Unread</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-pinned me-2"></i>Pin Chats</a></li>
                    <li><a class="dropdown-item" href="#"  data-bs-toggle="modal" data-bs-target="#delete-chat"><i class="ti ti-trash me-2"></i>Delete</a></li>
                </ul>
            </div>     
        </div>   
        <div class="chat-list" >
            <router-link to="chat" class="chat-user-list">
                <div class="avatar avatar-lg online me-2">
                    <img src="@/assets/img/profiles/avatar-11.jpg" class="rounded-circle" alt="image">
                </div>
                <div class="chat-user-info">
                    <div class="chat-user-msg">
                        <h6>Danielle Baker</h6>
                        <p><i class="ti ti-map-pin-plus me-2"></i>Location</p>
                    </div>
                    <div class="chat-user-time">
                        <span class="time">02:40 PM</span>
                        <div class="chat-pin">
                            <i class="ti ti-checks text-success"></i>
                        </div>
                    </div>    
                </div>
            </router-link>                    
            <div class="chat-dropdown">
                <a class="#" href="#" data-bs-toggle="dropdown">
                    <i class="ti ti-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end p-3">
                    <li><a class="dropdown-item" href="#"><i class="ti ti-box-align-right me-2"></i>Archive Chat</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-heart me-2"></i>Mark as Favourite</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-check me-2"></i>Mark as Unread</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-pinned me-2"></i>Pin Chats</a></li>
                    <li><a class="dropdown-item" href="#"  data-bs-toggle="modal" data-bs-target="#delete-chat"><i class="ti ti-trash me-2"></i>Delete</a></li>
                </ul>
            </div>     
        </div>   
        <div class="chat-list" >
            <router-link to="chat" class="chat-user-list">
                <div class="avatar avatar-lg online me-2">
                    <img src="@/assets/img/profiles/avatar-13.jpg" class="rounded-circle" alt="image">
                </div>
                <div class="chat-user-info">
                    <div class="chat-user-msg">
                        <h6>Morkel Jerrin</h6>
                        <p><i class="ti ti-video me-2"></i>Video</p>
                    </div>
                    <div class="chat-user-time">
                        <span class="time">02:40 PM</span>
                        <div class="chat-pin">
                            <i class="ti ti-heart-filled text-warning me-2"></i>
                            <span class="count-message fs-12 fw-semibold">25</span>
                        </div>
                    </div>    
                </div>
            </router-link>                    
            <div class="chat-dropdown">
                <a class="#" href="#" data-bs-toggle="dropdown">
                    <i class="ti ti-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end p-3">
                    <li><a class="dropdown-item" href="#"><i class="ti ti-box-align-right me-2"></i>Archive Chat</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-heart me-2"></i>Mark as Favourite</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-check me-2"></i>Mark as Unread</a></li>
                    <li><a class="dropdown-item" href="#"><i class="ti ti-pinned me-2"></i>Pin Chats</a></li>
                    <li><a class="dropdown-item" href="#"  data-bs-toggle="modal" data-bs-target="#delete-chat"><i class="ti ti-trash me-2"></i>Delete</a></li>
                </ul>
            </div>     
        </div>      
    </div>
</template>



