<script>
export default {
  data() {
    return {
      Name: "Group List",
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>

  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
        <div class="d-md-flex d-block align-items-center justify-content-between mb-4">
          <div class="my-auto">
            <h4 class="page-title mb-1">Group</h4>
            <nav>
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item">
                  <router-link to="/admin-template/index"><i class="ti ti-home text-primary"></i></router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Group</li>
              </ol>
            </nav>
          </div>
          <div class="d-flex my-xl-auto right-content align-items-center flex-wrap">
            <div class="dropdown me-2 mb-2">
              <a href="javascript:void(0);"
                class="dropdown-toggle btn fw-medium d-inline-flex align-items-center"
                data-bs-toggle="dropdown">
                <i class="ti ti-file-export me-2"></i>Export
              </a>
              <ul class="dropdown-menu  dropdown-menu-end p-3">
                <li>
                  <a href="javascript:void(0);" class="dropdown-item rounded-1"><i
                      class="ti ti-file-type-pdf me-1"></i>Export as PDF</a>
                </li>
                <li>
                  <a href="javascript:void(0);" class="dropdown-item rounded-1"><i
                      class="ti ti-file-type-xls me-1"></i>Export as Excel </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      <!-- Page Header -->

      <!-- User List -->
      <div class="card">
        <admin-top :Name="Name"></admin-top>

        <!-- Group List -->
        <div class="card-body p-0">
          <admin-group-table></admin-group-table>
          <!-- /Cities List -->
        </div>
      </div>
      <!-- /Group List -->
    </div>
  </div>

  <admin-group-modal></admin-group-modal>
</template>
