<script>
import AdminSidebarData from '@/assets/json/admin-sidebar.json';

export default {
    data() {
        return {
            AdminSidebarData: AdminSidebarData,
            showSubRoute: null,
            openMenuItem: null,
            route_array: [],
        };
    },
    methods: {
        expandSubMenus(menu) {
            // Save the menu value to sessionStorage
            sessionStorage.setItem('menuValue', menu.menuValue);

            // Loop through all sidebar data to manage the sub menus
            this.AdminSidebarData.forEach(mainMenu => {
                mainMenu.menu.forEach(resMenu => {
                if (resMenu.menuValue === menu.menuValue) {
                    // Toggle the current submenu
                    menu.showSubRoute = !menu.showSubRoute;
                } else {
                    // Close other submenus
                    resMenu.showSubRoute = false;
                }
                });
            });
        },
        // expandSubMenus(menu) {
        //     // Collapse all other submenus when expanding a new one
        //     this.AdminSidebarData.forEach(item => {
        //         if (item.menu && Array.isArray(item.menu)) {
        //             item.menu.forEach((subMenu) => {
        //                 if (subMenu !== menu) {
        //                     subMenu.showSubRoute = false; // Collapse all others
        //                 }
        //             });
        //         }
        //     });
        //     menu.showSubRoute = !menu.showSubRoute;
        //     localStorage.setItem('openSubMenu', JSON.stringify(this.AdminSidebarData));
        // },
        openMenu(menu) {
            // Toggle between opening/closing the menu
            this.openMenuItem = this.openMenuItem === menu ? null : menu;
            localStorage.setItem('openMenuItem', this.openMenuItem ? this.openMenuItem.menuValue : null);
        },
        openSubmenuOne(subMenus) {
            // Toggle between opening/closing the second-level submenu
            this.showSubRoute = this.showSubRoute === subMenus ? null : subMenus;
            localStorage.setItem('showSubRoute', this.showSubRoute ? this.showSubRoute.menuValue : null);
        },
        getCurrentPath() {
            this.route_array = this.$route.path.split("/");
            return this.$route.path;
        },
        resetMenuState() {
            // Clear the active submenu and menu items when navigating to a new page
            this.openMenuItem = null;
            this.showSubRoute = null;
        },
    },
    mounted() {
        // Retrieve state from localStorage
        const savedOpenMenuItem = localStorage.getItem('openMenuItem');
        const savedShowSubRoute = localStorage.getItem('showSubRoute');
        
        // Reopen saved menus on page reload
        if (savedOpenMenuItem) {
            this.openMenuItem = this.AdminSidebarData.find(item => item.menuValue === savedOpenMenuItem);
        }
        if (savedShowSubRoute) {
            this.showSubRoute = this.AdminSidebarData.find(item => item.menuValue === savedShowSubRoute);
        }
    },
    watch: {
        '$route'() {
            // Reset the menu and submenu states on route change
            this.resetMenuState();
        }
    },
    computed: {
        currentPath() {
            return this.getCurrentPath();
        },
        isMenuActive() {
            return (menu) => {
                if (menu.menuValue === "Forms") {
                    return (
                        this.$route.path.startsWith("/admin-template/forms") ||
                        this.$route.path === menu.active_link ||
                        this.$route.path === menu.active_link1 ||
                        this.$route.path === menu.active_link2
                    );
                } else {
                    return (
                        this.$route.path === menu.route ||
                        this.$route.path === menu.active_link ||
                        this.$route.path === menu.active_link1 ||
                        this.$route.path === menu.active_link2 ||
                        this.$route.path === menu.active_link3 ||
                        this.$route.path === menu.active_link4 ||
                        this.$route.path === menu.active_link5 ||
                        this.$route.path === menu.active_link6 ||
                        this.$route.path === menu.active_link7 ||
                        this.$route.path === menu.active_link8 ||
                        this.$route.path === menu.active_link9 ||
                        this.$route.path === menu.active_link10 ||
                        this.$route.path === menu.active_link11 ||
                        this.$route.path === menu.active_link12 ||
                        this.$route.path === menu.active_link13 ||
                        this.$route.path === menu.active_link14 ||
                        this.$route.path === menu.active_link15 ||
                        this.$route.path === menu.active_link16 ||
                        this.$route.path === menu.active_link17 ||
                        this.$route.path === menu.active_link18 ||
                        this.$route.path === menu.active_link19 ||
                        this.$route.path === menu.active_link20
                    );
                  6
                }
            };
        },
    },
};
</script>


<template>
    <ul class="menu-top">
        <template v-for="item in AdminSidebarData" :key="item.tittle">
            <li v-if="item.title === true">
                <h6 class="submenu-hdr">
                    <span>{{ item.tittle }}</span>
                </h6>
            </li>
            <template v-for="menu in item.menu" :key="menu.menuValue">
                <!-- Normal Menu Items -->
                <li v-if="!menu.hasSubRoute" :class="{ active: isMenuActive(menu) }"
                :style="{ 'display' : menu.menuValue === 'Forms' ? 'none' : 'block', }">
                    <router-link v-if="menu.route" :to="menu.route">
                        <i :class="'ti ' + menu.Icon"></i>
                        <span>{{ menu.menuValue }}</span>
                    </router-link>
                </li>

                <li v-else class="submenu">
                    <a
                    href="javascript:void(0)"
                    @click="expandSubMenus(menu)"
                    :class="{
                        'subdrop': menu.showSubRoute,
                        'active' : route_array[1] === menu.active_link
                    }"
                    >
                    <i :class="'ti ' + menu.Icon"></i>
                    <span>{{ menu.menuValue }}</span>
                    <span class="menu-arrow"></span>
                    </a>

                    <ul :class="menu.showSubRoute ? 'd-block' : 'd-none'">
                    <li v-for="(subMenu, index) in menu.subMenus" :key="index">
                        <router-link v-if="subMenu.route" :to="subMenu.route"
                                :class="{
                                    'active' : currentPath === subMenu.active_link ||
                                    currentPath === subMenu.active_link1 ||
                                    currentPath === subMenu.active_link2 ||
                                    currentPath === subMenu.active_link3 ||
                                    currentPath === subMenu.active_link4 ||
                                    currentPath === subMenu.active_link5 ||
                                    currentPath === subMenu.active_link6 ||
                                    currentPath === subMenu.active_link7 ||
                                    currentPath === subMenu.active_link8 ||
                                    currentPath === subMenu.active_link9 ||
                                    currentPath === subMenu.active_link10 ||
                                    currentPath === subMenu.active_link11 ||
                                    currentPath === subMenu.active_link12 ||
                                    currentPath === subMenu.active_link13 ||
                                    currentPath === subMenu.active_link14 ||
                                    currentPath === subMenu.active_link15 ||
                                    currentPath === subMenu.active_link16 ||
                                    currentPath === subMenu.active_link17 ||
                                    currentPath === subMenu.active_link18 ||
                                    currentPath === subMenu.active_link19 ||
                                    currentPath === subMenu.active_link20,
                                }">
                                <i class="me-2" :class="'ti ' + subMenu.Icon"></i>
                                {{ subMenu.menuValue }}
                            </router-link>
                    </li>
                    </ul>
                </li>

                <!-- Submenu with Two Levels -->
                <li v-if="menu.hasSubRouteTwo" class="submenu">
                    <a href="javascript:void(0);" @click="openMenu(menu)" 
                    :class="{ 'subdrop': openMenuItem === menu, 'active' : isMenuActive(menu) }">
                        <i :class="'ti ' + menu.Icon"></i>
                        <span>{{ menu.menuValue }}</span>
                        <span class="menu-arrow"></span>
                    </a>
                    <ul :class="{ 'd-block': openMenuItem === menu, 'd-none': openMenuItem !== menu }">
                        <template v-for="subMenus in menu.subMenus" :key="subMenus.menuValue">
                            <li v-if="!subMenus.customSubmenuTwo" class="submenu submenu-two">
                                <router-link :to="subMenus.route" @click="expandSubMenus(subMenus)">
                                    {{ subMenus.menuValue }}
                                </router-link>
                            </li>
                            <li v-else-if="subMenus.customSubmenuTwo" class="submenu submenu-two">
                                <a href="javascript:void(0);" @click="openSubmenuOne(subMenus)" 
                                :class="{ 'subdrop': showSubRoute === subMenus, 'active' : route_array[1] === subMenus.active_link, }">
                                    {{ subMenus.menuValue }}
                                    <span class="menu-arrow"></span>
                                </a>
                                <ul :class="{ 'd-block': showSubRoute === subMenus, 'd-none': showSubRoute !== subMenus }">
                                    <li v-for="subMenuTwo in subMenus.subMenusTwo" :key="subMenuTwo.menuValue">
                                        <router-link :to="subMenuTwo.route">
                                            {{ subMenuTwo.menuValue }}
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                        </template>
                    </ul>
                </li>
            </template>
        </template>
    </ul>
</template>



