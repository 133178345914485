<template>
  <!-- Favourites Info -->
  <div
    class="chat-offcanvas fav-canvas offcanvas offcanvas-end"
    data-bs-scroll="true"
    data-bs-backdrop="false"
    tabindex="-1"
    id="contact-favourite"
  >
    <div class="offcanvas-header">
      <h4 class="offcanvas-title">
        <a
          href="javascript:void(0);"
          data-bs-toggle="offcanvas"
          data-bs-target="#contact-profile"
          data-bs-dismiss="offcanvas"
          ><i class="ti ti-arrow-left me-2"></i></a
        >Favourites
      </h4>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        <i class="ti ti-x"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="favourite-chats">
        <div class="text-end mb-4">
          <a href="javascript:void(0);" class="btn btn-light"
            ><i class="ti ti-heart-minus me-2"></i>Mark all Unfavourite</a
          >
        </div>
        <div class="chats">
          <div class="chat-avatar">
            <img
              src="@/assets/img/profiles/avatar-06.jpg"
              class="rounded-circle"
              alt="image"
            />
          </div>
          <div class="chat-content">
            <div class="chat-profile-name">
              <h6>
                Edward Lietz<i class="ti ti-circle-filled fs-7 mx-2"></i
                ><span class="chat-time">02:39 PM</span
                ><span class="msg-read success"><i class="ti ti-checks"></i></span>
              </h6>
            </div>
            <div class="chat-info">
              <div class="message-content">
                Thanks!!!, I ll Update you Once i check the Examples
              </div>
              <div class="chat-actions">
                <a class="#" href="#" data-bs-toggle="dropdown">
                  <i class="ti ti-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end p-3">
                  <li>
                    <a class="dropdown-item" href="#"
                      ><i class="ti ti-heart me-2"></i>Unfavourite</a
                    >
                  </li>
                  <li>
                    <a class="dropdown-item" href="#"
                      ><i class="ti ti-trash me-2"></i>Delete</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <p>Saved on 23 Septemer 2024</p>
          </div>
        </div>
        <div class="chats">
          <div class="chat-avatar">
            <img
              src="@/assets/img/profiles/avatar-04.jpg"
              class="rounded-circle"
              alt="image"
            />
          </div>
          <div class="chat-content">
            <div class="chat-profile-name">
              <h6>
                Carla Jenkins<i class="ti ti-circle-filled fs-7 mx-2"></i
                ><span class="chat-time">02:45 PM</span
                ><span class="msg-read success"><i class="ti ti-checks"></i></span>
              </h6>
            </div>
            <div class="chat-info">
              <div class="message-content bg-transparent p-0">
                <div class="message-audio">
                  <audio controls>
                    <source src="@/assets/img/audio/audio.mp3" type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              </div>
              <div class="chat-actions">
                <a class="#" href="#" data-bs-toggle="dropdown">
                  <i class="ti ti-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end p-3">
                  <li>
                    <a class="dropdown-item" href="#"
                      ><i class="ti ti-heart me-2"></i>Unfavourite</a
                    >
                  </li>
                  <li>
                    <a class="dropdown-item" href="#"
                      ><i class="ti ti-trash me-2"></i>Delete</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <p>Saved on 26 Septemer 2024</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Favourites Info -->
</template>
