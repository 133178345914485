<script>
import { ref } from "vue";
import sideMenu from "@/views/frontend/layouts/side-menu.vue";

export default {
  components: { sideMenu },
  setup() {
    const activeTab = ref("chat-menu"); // Default to 'chat-menu'

    // Method to set the active tab
    const setActiveTab = (tabId) => {
      activeTab.value = tabId;
    };

    return {
      activeTab,
      setActiveTab,
    };
  },
};
</script>

<template>
  <!-- content -->
  <div class="content main_content">
    <!-- Left Sidebar Menu -->
    <side-menu></side-menu>
    <!-- /Left Sidebar Menu -->

    <!-- sidebar group -->
    <div class="sidebar-group">
      <div class="tab-content">
        <div
          id="chat-menu"
          :class="['tab-pane', 'fade', activeTab === 'chat-menu' ? 'active show' : '']"
        >
          <!-- Chats sidebar -->
          <index-sidebar></index-sidebar>
          <!-- / Chats sidebar -->
        </div>

        <!-- Contact -->
        <div
          id="contact-menu"
          :class="['tab-pane', 'fade', activeTab === 'contact-menu' ? 'active show' : '']"
        >
          <!-- Chats sidebar -->
          <chat-sidebar></chat-sidebar>
          <!-- / Chats sidebar -->
        </div>
        <!-- /Contact -->

        <!-- Group -->
        <div
          :class="['tab-pane', 'fade', activeTab === 'group-menu' ? 'active show' : '']"
          id="group-menu"
        >
          <!-- Chats sidebar -->
          <group-sidebar></group-sidebar>
          <!-- / Chats sidebar -->
        </div>
        <!-- /Group -->

        <!-- Profile -->
        <div
          :class="['tab-pane', 'fade', activeTab === 'profile-menu' ? 'active show' : '']"
          id="profile-menu"
        >
          <!-- Profile sidebar -->
          <profile-sidebar></profile-sidebar>
          <!-- / Profile sidebar -->
        </div>
        <!-- /Profile -->

        <!-- Calls -->
        <div
          :class="['tab-pane', 'fade', activeTab === 'call-menu' ? 'active show' : '']"
          id="call-menu"
        >
          <!-- Call sidebar -->
          <calls-sidebar></calls-sidebar>
          <!-- / Call sidebar -->
        </div>
        <!-- /Calls -->

        <!-- Settings -->
        <div
          :class="['tab-pane', 'fade', activeTab === 'setting-menu' ? 'active show' : '']"
          id="setting-menu"
        >
          <!-- Profile sidebar -->
          <settings-sidebar></settings-sidebar>
          <!-- / Chats sidebar -->
        </div>
        <!-- /Settings -->
      </div>
    </div>
    <!-- /Sidebar group -->

    <!-- Chat -->
    <div class="welcome-content d-flex align-items-center justify-content-center">
      <div class="welcome-info text-center">
        <div class="welcome-box bg-white d-inline-flex align-items-center">
          <span class="avatar avatar-md me-2">
            <img
              src="@/assets/img/profiles/avatar-16.jpg"
              alt="img"
              class="rounded-circle"
            />
          </span>
          <h6>
            Welcome! Salom<img
              src="@/assets/img/icons/emoji-01.svg"
              alt="Image"
              class="ms-2"
            />
          </h6>
        </div>
        <p>Choose a person or group to start chat with them.</p>
        <a
          href="javascript:void(0);"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#new-chat"
          ><i class="ti ti-location me-2"></i>Invite Contacts</a
        >
      </div>
    </div>
    <!-- /Chat -->
  </div>
  <!-- /Content -->

  <modal-index></modal-index>
</template>
