<script>
export default {
  data() {
    return {};
  },
  methods: {
    submitForm() {
      this.$router.push("ban-address");
    },
  },
};
</script>
<template>
  <!-- Add Ban Address -->
  <div class="modal fade" id="add_ban">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add New IP Address</h4>
          <button
            type="button"
            class="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ti ti-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">IP Address</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-12">
                <div>
                  <label class="form-label">Reason</label>
                  <textarea class="form-control"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <div class="d-flex w-100 justify-content-between">
              <a
                href="#"
                class="btn btn-outline-primary me-2 d-flex justify-content-center w-100"
                data-bs-dismiss="modal"
                >Cancel</a
              >
              <button
                type="submit"
                class="btn btn-primary d-flex justify-content-center w-100"
                data-bs-dismiss="modal"
              >
                Add IP Addresss
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- / Add Ban Address -->

  <!-- Edit Ban Address -->
  <div class="modal fade" id="edit_ban">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Edit Ban IP Address</h4>
          <button
            type="button"
            class="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ti ti-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">IP Address</label>
                  <input type="text" class="form-control" value="198.120.16.01" />
                </div>
              </div>
              <div class="col-md-12">
                <div>
                  <label class="form-label">Reason</label>
                  <textarea class="form-control">
Temporarily block to protect user accounts from internet fraudsters</textarea
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <div class="d-flex w-100 justify-content-between">
              <a
                href="#"
                class="btn btn-outline-primary me-2 d-flex justify-content-center w-100"
                data-bs-dismiss="modal"
                >Cancel</a
              >
              <button
                type="submit"
                class="btn btn-primary d-flex justify-content-center w-100"
                data-bs-dismiss="modal"
              >
                Add IP Addresss
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Edit Ban Address -->
</template>
