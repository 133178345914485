<template>
  <!-- Sidebar -->
  <div class="sidebar" id="sidebar">
    <perfect-scrollbar
      class="scroll-area"
      :settings="settings"
      @ps-scroll-y="scrollHanle"
    >
      <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu d-flex flex-column">
          <admin-sidebar-manu></admin-sidebar-manu>
          <ul class="menu-bottom">
            <li>
              <router-link to="/admin-template/index"
                ><i class="ti ti-file-text"></i><span>Documentation</span>
                <span class="version">4.0.2</span></router-link
              >
            </li>
            <li>
              <a href="#"><i class="ti ti-exchange"></i><span>Changelog </span></a>
            </li>
            <li class="submenu">
              <a href="javascript:void(0);"
                ><i class="ti ti-menu-2"></i><span>Multi Level</span
                ><span class="menu-arrow"></span
              ></a>
              <ul>
                <li><a href="javascript:void(0);">Multilevel 1</a></li>
                <li class="submenu submenu-two">
                  <a href="javascript:void(0);"
                    >Multilevel 2<span class="menu-arrow inside-submenu"></span
                  ></a>
                  <ul>
                    <li><a href="javascript:void(0);">Multilevel 2.1</a></li>
                    <li class="submenu submenu-two submenu-three">
                      <a href="javascript:void(0);"
                        >Multilevel 2.2<span
                          class="menu-arrow inside-submenu inside-submenu-two"
                        ></span
                      ></a>
                      <ul>
                        <li><a href="javascript:void(0);">Multilevel 2.2.1</a></li>
                        <li><a href="javascript:void(0);">Multilevel 2.2.2</a></li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li><a href="javascript:void(0);">Multilevel 3</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
  <!-- /Sidebar -->
</template>



<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
export default {
  components: {
    PerfectScrollbar,
  },
  data() {
    return {
      settings: {
        suppressScrollX: true,
      },
    };
  },
  methods: {
    scrollHanle() {},
  },
};
</script>