<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  data() {
    return {
      title: "GDPR Cookies",
      text: "System Settings",
      text1: "GDPR Cookies",
      RightSel: ["Right", "Left"],
      editor: ClassicEditor,
      editorData: "Description",
      editorConfig: {},
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- Profile Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-md-3">
              <system-sidebar></system-sidebar>
            </div>
            <div class="col-md-9">
              <div class="card setting-content mb-0">
                <div class="card-header px-0 mx-3">
                  <h4>GDPR Cookies</h4>
                </div>
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row align-items-center">
                        <div class="col-lg-4">
                          <div class="mb-3">
                            <h6 class="fw-medium">Cookies Position</h6>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="mb-3">
                            <vue-select
                              :options="RightSel"
                              id="rightsel"
                              placeholder="Right"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-lg-4">
                          <div class="mb-3">
                            <h6 class="fw-medium">Agree Button Text</h6>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="mb-3">
                            <input class="form-control" type="text" />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-lg-4">
                          <div class="mb-3">
                            <h6 class="fw-medium">Decline Button Text</h6>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="mb-3">
                            <input class="form-control" type="text" />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-lg-4">
                          <div class="mb-3">
                            <h6 class="fw-medium">Show Decline Button</h6>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-check form-switch mb-0">
                            <input
                              class="form-check-input mb-3"
                              type="checkbox"
                              role="switch"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-lg-4">
                          <div class="mb-3">
                            <h6 class="fw-medium">Link for Cookies Page</h6>
                          </div>
                        </div>
                        <div class="col-lg-8">
                          <div class="mb-3">
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-lg-4">
                          <div class="mb-3">
                            <h6 class="fw-medium">Cookies Consent Text</h6>
                          </div>
                        </div>
                        <div class="col-lg-8">
                          <div class="mb-3">
                            <ckeditor 
                            :editor="editor"
                            v-model="editorData"
                            :config="editorConfig"
                              ></ckeditor>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Profile Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
