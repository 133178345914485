<script>
import { adminDashboard } from "./data";
export default {
  data() {
    return {
      adminDashboard: adminDashboard,
      title: "Dashboard",
      text: "Dashboard",
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <admin-breadcrumb :title="title" :text="text" />
      <!-- Page Header -->

      <dashboard-top></dashboard-top>

      <dashboard-table></dashboard-table>

      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-8 d-flex">
          <div class="card user-details flex-fill">
            <div class="card-header d-flex align-items-center justify-content-between">
              <h5 class="card-title">Attendance</h5>
              <div class="dropdown dashboard-chart">
                <a
                  href="javascript:void(0);"
                  class="bg-white dropdown-toggle"
                  data-bs-toggle="dropdown"
                  ><i class="ti ti-calendar-due me-1"></i>This Year
                </a>
                <ul class="dropdown-menu mt-2 p-3">
                  <li>
                    <a href="javascript:void(0);" class="dropdown-item rounded-1">
                      This Week
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="dropdown-item rounded-1">
                      Last Week
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="dropdown-item rounded-1">
                      Last Week
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <div id="school-area">
                <apexchart
                  type="area"
                  height="206"
                  :options="adminDashboard.AdminDash"
                  :series="adminDashboard.series"
                >
                </apexchart>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-lg-4 d-flex">
          <div class="card user-details flex-fill">
            <div class="card-header">
              <h5>Invited User</h5>
            </div>
            <div class="card-body">
              <div class="user-list">
                <div class="d-flex align-items-center">
                  <router-link to="/admin-template/user/invite-user" class="avatar avatar-md"
                    ><img
                      src="@/assets/admin/img/users/user-05.jpg"
                      class="img-fluid rounded-circle"
                      alt="img"
                  /></router-link>
                  <div class="ms-2 profile-name">
                    <p class="text-dark mb-0">
                      <router-link to="/admin-template/user/invite-user">Federico Wells</router-link>
                    </p>
                  </div>
                </div>
                <div class="check-list">
                  <a href="#"
                    ><span><i class="ti ti-check"></i></span
                  ></a>
                  <a href="#" class="close-btn"
                    ><span><i class="ti ti-x"></i></span
                  ></a>
                </div>
              </div>
              <div class="user-list">
                <div class="d-flex align-items-center">
                  <router-link to="/admin-template/user/invite-user" class="avatar avatar-md"
                    ><img
                      src="@/assets/admin/img/users/user-06.jpg"
                      class="img-fluid rounded-circle"
                      alt="img"
                  /></router-link>
                  <div class="ms-2 profile-name">
                    <p class="text-dark mb-0">
                      <router-link to="/admin-template/user/invite-user">Federico Wells</router-link>
                    </p>
                  </div>
                </div>
                <div class="check-list">
                  <a href="#"
                    ><span><i class="ti ti-check"></i></span
                  ></a>
                  <a href="#" class="close-btn"
                    ><span><i class="ti ti-x"></i></span
                  ></a>
                </div>
              </div>
              <div class="user-list">
                <div class="d-flex align-items-center">
                  <router-link to="/admin-template/user/invite-user" class="avatar avatar-md"
                    ><img
                      src="@/assets/admin/img/users/user-07.jpg"
                      class="img-fluid rounded-circle"
                      alt="img"
                  /></router-link>
                  <div class="ms-2 profile-name">
                    <p class="text-dark mb-0">
                      <router-link to="/admin-template/user/invite-user">Sharon Ford</router-link>
                    </p>
                  </div>
                </div>
                <div class="check-list">
                  <a href="#"
                    ><span><i class="ti ti-check"></i></span
                  ></a>
                  <a href="#" class="close-btn"
                    ><span><i class="ti ti-x"></i></span
                  ></a>
                </div>
              </div>
              <div class="user-list">
                <div class="d-flex align-items-center">
                  <router-link to="/admin-template/user/invite-user" class="avatar avatar-md"
                    ><img
                      src="@/assets/admin/img/users/user-08.jpg"
                      class="img-fluid rounded-circle"
                      alt="img"
                  /></router-link>
                  <div class="ms-2 profile-name">
                    <p class="text-dark mb-0">
                      <router-link to="/admin-template/user/invite-user">Thomas Rethman</router-link>
                    </p>
                  </div>
                </div>
                <div class="check-list">
                  <a href="#"
                    ><span><i class="ti ti-check"></i></span
                  ></a>
                  <a href="#" class="close-btn"
                    ><span><i class="ti ti-x"></i></span
                  ></a>
                </div>
              </div>
              <div class="user-list pb-0">
                <div class="d-flex align-items-center">
                  <router-link to="/admin-template/user/invite-user" class="avatar avatar-md"
                    ><img
                      src="@/assets/admin/img/users/user-09.jpg"
                      class="img-fluid rounded-circle"
                      alt="img"
                  /></router-link>
                  <div class="ms-2 profile-name">
                    <p class="text-dark mb-0">
                      <router-link to="/admin-template/user/invite-user">Wilbur Martinez</router-link>
                    </p>
                  </div>
                </div>
                <div class="check-list">
                  <a href="#"
                    ><span><i class="ti ti-check"></i></span
                  ></a>
                  <a href="#" class="close-btn"
                    ><span><i class="ti ti-x"></i></span
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
