<script>
export default {
  data() {
    return {
      title: "Clear Cache",
      text: "Others Settings",
      text1: "Clear Cache",
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- Social-Authentication Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-xl-3 col-md-4">
              <others-sidebar></others-sidebar>
            </div>
            <div class="col-xl-9 col-md-8">
              <div class="card setting-content mb-0">
                <div class="card-header px-0 mx-3">
                  <h4>Clear Cache</h4>
                </div>
                <div class="card-body pb-0">
                  <div class="cache-content">
                    <p class="fs-14 text-dark mb-3">
                      <span class="me-2"
                        ><i class="ti ti-info-circle text-danger"></i></span
                      >Clearing the cache may improve performance but will remove
                      temporary files, stored preferences, and cached data from websites
                      and applications.
                    </p>
                    <div class="d-flex align-items-center justify-content-end mb-3">
                      <a href="#" class="btn btn-outline-primary me-3">Cancel</a>
                      <a href="#" class="btn btn-primary">Clear Cache</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Social-Authentication Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
