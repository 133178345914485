<template>
  <div class="card-body p-0">
    <div class="custom-datatable-filter table-responsive">
      <a-table
        class="table datatable thead-light"
        :columns="columns"
        :data-source="data"
        :row-selection="rowSelection"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'Name'">
            <div class="d-flex align-items-center">
              <router-link to="/admin-template/staff-details" class="avatar avatar-md"
                ><img
                  :src="require(`@/assets/admin/img/users/${record.Image}`)"
                  class="img-fluid rounded-circle"
                  alt="img"
              /></router-link>
              <div class="ms-2 profile-name">
                <p class="text-dark mb-0">
                  <a href="#">{{ record.Name }}</a>
                </p>
              </div>
            </div>
          </template>
          <template v-if="column.key === 'Action'">
            <div class="d-flex align-items-center">
              <div class="dropdown">
                <a
                  href="#"
                  class="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="ti ti-dots-vertical fs-14"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-right p-3">
                  <li>
                    <a
                      class="dropdown-item rounded-1"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete-modal"
                      ><i class="ti ti-trash me-2"></i>Delete</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </template>
      </a-table>
    </div>
    <!-- /Cities List -->
  </div>
</template>
<script>
const columns = [
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Email Address",
    dataIndex: "EmailAddress",
    sorter: {
      compare: (a, b) => {
        a = a.EmailAddress.toLowerCase();
        b = b.EmailAddress.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Phone Number",
    dataIndex: "PhoneNumber",
    sorter: {
      compare: (a, b) => {
        a = a.PhoneNumber.toLowerCase();
        b = b.PhoneNumber.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Invite Date",
    dataIndex: "InviteDate",
    sorter: {
      compare: (a, b) => {
        a = a.InviteDate.toLowerCase();
        b = b.InviteDate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Country",
    dataIndex: "Country",
    sorter: {
      compare: (a, b) => {
        a = a.Country.toLowerCase();
        b = b.Country.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "Action",
    sorter: false,
  },
];
const data = [
  {
    key: "1",
    Name: "Aaryian Jose",
    EmailAddress: "aaryian@example.com",
    PhoneNumber: "514-245-98315",
    InviteDate: "02 Sep 2024, 10:00 AM",
    Country: "United States",
    Image: "user-01.jpg",
  },
  {
    key: "2",
    Name: "Sarika Jain",
    EmailAddress: "sarika@example.com",
    PhoneNumber: "156-348-85496",
    InviteDate: "14 Sep 2024, 11:30 AM",
    Country: "Canada",
    Image: "user-02.jpg",
  },
  {
    key: "3",
    Name: "Clyde Smith",
    EmailAddress: "clyde@example.com",
    PhoneNumber: "315-975-31849",
    InviteDate: "28 Sep 2024, 08:15 AM",
    Country: "United Kingdom",
    Image: "user-03.jpg",
  },
  {
    key: "4",
    Name: "Carla Jenkins",
    EmailAddress: "carla@example.com",
    PhoneNumber: "325-859-20194",
    InviteDate: "12 Oct 2024, 06:40 PM",
    Country: "France",
    Image: "user-04.jpg",
  },
  {
    key: "5",
    Name: "Federico Wells",
    EmailAddress: "federico@example.com",
    PhoneNumber: "314-829-30175",
    InviteDate: "20 Oct 2024, 04:18 PM",
    Country: "Argentina",
    Image: "user-05.jpg",
  },
  {
    key: "6",
    Name: "Edward Lietz",
    EmailAddress: "edward@example.com",
    PhoneNumber: "219-831-49521",
    InviteDate: "30 Oct 2024, 07:25 AM",
    Country: "Italy",
    Image: "user-06.jpg",
  },
  {
    key: "7",
    Name: "Sharon Ford",
    EmailAddress: "sharon@example.com",
    PhoneNumber: "198-301-75341",
    InviteDate: "01 Nov 2024, 08:50 AM",
    Country: "New Zealand",
    Image: "user-07.jpg",
  },
  {
    key: "8",
    Name: "Thomas Rethman",
    EmailAddress: "thomas@example.com",
    PhoneNumber: "301-978-30986",
    InviteDate: "10 Nov 2024, 11:20 AM",
    Country: "Australia",
    Image: "user-08.jpg",
  },
  {
    key: "9",
    Name: "Wilbur Martinez",
    EmailAddress: "wilbur@example.com",
    PhoneNumber: "383-248-34968",
    InviteDate: "17 Nov 2024, 04:50 PM",
    Country: "Japan",
    Image: "user-09.jpg",
  },
  {
    key: "10",
    Name: "Danielle Baker",
    EmailAddress: "danielle@example.com",
    PhoneNumber: "736-795-34895",
    InviteDate: "12 Dec 2024, 09:30 AM",
    Country: "Argentina",
    Image: "user-10.jpg",
  },
];
const rowSelection = {
  onChange: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
};
export default {
  data() {
    return {
      data,
      columns,
      rowSelection,
    };
  },
};
</script>
