<script>
export default {
  data() {
    return {
      title: "Localization",
      text: "System Settings",
      text1: "Localization",
      TimeSelc: ["12 Hours", "24 Hours"],
      ENglishSp: ["English", "Spanish", "French"],
      LocaTime: ["UTC 5:30", "(UTC+11:00) INR"],
      DateLocal: ["16 Sep 2024", "Sep 16 2024", "2024 Sep 16"],
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- Profile Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-xl-3 col-md-4">
              <system-sidebar></system-sidebar>
            </div>
            <div class="col-xl-9 col-md-8">
              <div class="card setting-content mb-0">
                <div class="card-header px-0 mx-3">
                  <h4>Localization</h4>
                </div>
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="row align-items-center">
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <h6 class="mb-2">Language</h6>
                            <p>Select Language of the Website</p>
                          </div>
                        </div>
                        <div class="col-lg-5">
                          <div class="mb-3">
                            <vue-select
                              :options="ENglishSp"
                              id="englishsp"
                              placeholder="English"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <h6 class="mb-2">Language Switcher</h6>
                            <p>To Display in all the pages</p>
                          </div>
                        </div>
                        <div class="col-lg-5">
                          <div class="mb-3">
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <h6 class="mb-2">Time Zone</h6>
                            <p>Select Time zone in website</p>
                          </div>
                        </div>
                        <div class="col-lg-5">
                          <div class="mb-3">
                            <vue-select
                              :options="LocaTime"
                              id="locatime"
                              placeholder="UTC 5:30"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <h6 class="mb-2">Date Format</h6>
                            <p>Select date format to display in website</p>
                          </div>
                        </div>
                        <div class="col-lg-5">
                          <div class="mb-3">
                            <vue-select
                              :options="DateLocal"
                              id="datelocal"
                              placeholder="16 Sep 2024"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <h6 class="mb-2">Time Format</h6>
                            <p>Select time format to display in website</p>
                          </div>
                        </div>
                        <div class="col-lg-5">
                          <div class="mb-3">
                            <vue-select
                              :options="TimeSelc"
                              id="timeselc"
                              placeholder="12 Hours"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Profile Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
