<template>
  <div ref="mainWrapper" :class="['main-wrapper', { 'd-block': isSignInPage }]">
    <router-view></router-view>
  </div>
</template>

<script>
import { ref, onMounted, computed, watchEffect } from "vue";
import { useRoute } from "vue-router";

export default {
  setup() {
    const mainWrapper = ref(null);
    const route = useRoute();

    // Computed properties to check the current path
    const isAdminTemplatePath = computed(() => {
      return (
        route.path === "/admin-template/" ||
        route.path === "/admin-template/forgot-password" ||
        route.path === "/admin-template/reset-password" ||
        route.path === "/admin-template/reset-password-success"
      );
    });

    const isSignInPage = computed(() => {
      return (
        route.name === "signin-index" ||
        route.name === "reset-password" ||
        route.name === "forgot-password" ||
        route.name === "success-index" ||
        route.name === "otp-index"
      );
    });

    onMounted(() => {
      if (mainWrapper.value) {
        mainWrapper.value.style.visibility = "visible";
      }
    });

    // Watch for changes in the route and update the body class
    watchEffect(() => {
      if (isAdminTemplatePath.value) {
        document.body.classList.add("login-form");
      } else {
        document.body.classList.remove("login-form");
      }
    });

    return {
      isAdminTemplatePath,
      mainWrapper,
      isSignInPage,
    };
  },
};
</script>

<style scoped>
/* Add any styles here */
</style>
