<script>
export default {
  data() {
    return {
      title: "Email Settings",
      text: "System Settings",
      text1: "Email Settings",
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- Profile Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-md-3">
              <system-sidebar></system-sidebar>
            </div>
            <div class="col-md-9">
              <div class="card setting-content mb-0">
                <div class="card-header px-0 mx-3">
                  <h4>Email Settings</h4>
                </div>
                <div class="card-body pb-0">
                  <div class="row gx-3">
                    <div class="col-md-6">
                      <div class="card mb-3">
                        <div class="card-body">
                          <div
                            class="d-flex align-items-center justify-content-between mb-3"
                          >
                            <h6>PHP Mailer</h6>
                            <span class="badge badge-success">Connected</span>
                          </div>
                          <p class="mb-3">
                            Used to send emails safely and easily via PHP code from a web
                            server.
                          </p>
                          <div
                            class="d-flex align-items-center justify-content-between border-top pt-3"
                          >
                            <a
                              href="#"
                              class="btn btn-sm btn-outline-dark d-inline-flex align-items-center"
                              data-bs-toggle="modal"
                              data-bs-target="#php_mail"
                            >
                              <i class="ti ti-settings-cog me-2"></i>View Integration
                            </a>
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="card mb-3">
                        <div class="card-body">
                          <div
                            class="d-flex align-items-center justify-content-between mb-3"
                          >
                            <h6>SMTP</h6>
                            <span class="badge badge-light text-dark">Not Connected</span>
                          </div>
                          <p class="mb-3">
                            Used to send emails safely and easily via PHP code from a web
                            server.
                          </p>
                          <div
                            class="d-flex align-items-center justify-content-between border-top pt-3"
                          >
                            <a
                              href="#"
                              class="btn btn-sm btn-outline-dark d-inline-flex align-items-center"
                              data-bs-toggle="modal"
                              data-bs-target="#smtp_mail"
                            >
                              <i class="ti ti-tool me-2"></i>Connect Now
                            </a>
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="card mb-3">
                        <div class="card-body">
                          <div
                            class="d-flex align-items-center justify-content-between mb-3"
                          >
                            <h6>Send Grid</h6>
                            <span class="badge badge-success">Connected</span>
                          </div>
                          <p class="mb-3">
                            Cloud-based email marketing tool that assists marketers and
                            developers .
                          </p>
                          <div
                            class="d-flex align-items-center justify-content-between border-top pt-3"
                          >
                            <a
                              href="#"
                              class="btn btn-sm btn-outline-dark d-inline-flex align-items-center"
                              ><i class="ti ti-settings-cog me-2"></i>View Integration</a
                            >
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Profile Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
  <email-settings-modal></email-settings-modal>
</template>
