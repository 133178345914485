<template>
  <!-- Contact Info -->
  <div
    class="chat-offcanvas offcanvas offcanvas-end"
    data-bs-scroll="true"
    data-bs-backdrop="false"
    tabindex="-1"
    id="contact-profile"
    aria-labelledby="chatUserMoreLabel"
  >
    <div class="offcanvas-header">
      <h4 class="offcanvas-title" id="chatUserMoreLabel">Contact Info</h4>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        <i class="ti ti-x"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="chat-contact-info">
        <div class="profile-content">
          <div class="contact-profile-info">
            <div class="avatar avatar-xxl online mb-2">
              <img
                src="@/assets/img/profiles/avatar-06.jpg"
                class="rounded-circle"
                alt="img"
              />
            </div>
            <h6>Edward Lietz</h6>
            <p>Last seen at 07:15 PM</p>
          </div>
          <div class="row gx-3">
            <div class="col">
              <a
                class="action-wrap"
                href="javascript:void(0);"
                data-bs-toggle="modal"
                data-bs-target="#voice_call"
              >
                <i class="ti ti-phone"></i>
                <p>Audio</p>
              </a>
            </div>
            <div class="col">
              <a
                class="action-wrap"
                href="javascript:void(0);"
                data-bs-toggle="modal"
                data-bs-target="#video-call"
              >
                <i class="ti ti-video"></i>
                <p>Video</p>
              </a>
            </div>
            <div class="col">
              <a class="action-wrap" href="javascript:void(0);">
                <i class="ti ti-brand-hipchat"></i>
                <p>Chat</p>
              </a>
            </div>
            <div class="col">
              <a class="action-wrap" href="javascript:void(0);">
                <i class="ti ti-search"></i>
                <p>Search</p>
              </a>
            </div>
          </div>
          <div class="content-wrapper">
            <h5 class="sub-title">Profile Info</h5>
            <div class="card">
              <div class="card-body">
                <ul class="list-group profile-item">
                  <li class="list-group-item">
                    <div class="profile-info">
                      <h6>Name</h6>
                      <p>Edward Lietz</p>
                    </div>
                    <div class="profile-icon">
                      <i class="ti ti-user-circle"></i>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="info">
                      <h6>Email Address</h6>
                      <p>info@example.com</p>
                    </div>
                    <div class="icon">
                      <i class="ti ti-mail-heart"></i>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="info">
                      <h6>Phone</h6>
                      <p>555-555-21541</p>
                    </div>
                    <div class="icon">
                      <i class="ti ti-phone-check"></i>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="info">
                      <h6>Bio</h6>
                      <p>Hello, I am using DreamsChat</p>
                    </div>
                    <div class="icon">
                      <i class="ti ti-user-check"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="content-wrapper">
            <h5 class="sub-title">Social Profiles</h5>
            <div class="card">
              <div class="card-body">
                <div class="social-icon">
                  <a href="javascript:void(0);"><i class="ti ti-brand-facebook"></i></a>
                  <a href="javascript:void(0);"><i class="ti ti-brand-twitter"></i></a>
                  <a href="javascript:void(0);"><i class="ti ti-brand-instagram"></i></a>
                  <a href="javascript:void(0);"><i class="ti ti-brand-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="content-wrapper">
            <h5 class="sub-title">Media Details</h5>
            <div class="chat-file">
              <div class="file-item">
                <div class="accordion accordion-flush chat-accordion" id="mediafile">
                  <chat-photo></chat-photo>
                  <chat-video></chat-video>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <a
                        href="#"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#media-links"
                        aria-expanded="false"
                        aria-controls="media-links"
                      >
                        <i class="ti ti-unlink me-2"></i>Links
                      </a>
                    </h2>
                    <div
                      id="media-links"
                      class="accordion-collapse collapse"
                      data-bs-parent="#mediafile"
                    >
                      <div class="accordion-body">
                        <div class="link-item">
                          <span class="link-icon">
                            <img src="@/assets/img/icons/github-icon.svg" alt="icon" />
                          </span>
                          <div class="ms-2">
                            <p>https://segmentfault.com/u/ans</p>
                          </div>
                        </div>
                        <div class="link-item">
                          <span class="link-icon">
                            <img src="@/assets/img/icons/info-icon.svg" alt="icon" />
                          </span>
                          <div class="ms-2">
                            <p>https://segmentfault.com/u/ans</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <a
                        href="#"
                        class="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#media-document"
                        aria-expanded="false"
                        aria-controls="media-document"
                      >
                        <i class="ti ti-unlink me-2"></i>Documents
                      </a>
                    </h2>
                    <div
                      id="media-document"
                      class="accordion-collapse collapse"
                      data-bs-parent="#mediafile"
                    >
                      <div class="accordion-body">
                        <div class="document-item">
                          <div class="d-flex align-items-center">
                            <span class="document-icon">
                              <i class="ti ti-file-zip"></i>
                            </span>
                            <div class="ms-2">
                              <h6>Ecommerce.zip</h6>
                              <p>10.25 MB zip file</p>
                            </div>
                          </div>
                          <a href="javascript:void(0);" class="download-icon">
                            <i class="ti ti-download"></i>
                          </a>
                        </div>
                        <div class="document-item">
                          <div class="d-flex align-items-center">
                            <span class="document-icon">
                              <i class="ti ti-video"></i>
                            </span>
                            <div class="ms-2">
                              <h6>video-1.mp4</h6>
                              <p>20.50 MB video file</p>
                            </div>
                          </div>
                          <a href="javascript:void(0);" class="download-icon">
                            <i class="ti ti-download"></i>
                          </a>
                        </div>
                        <div class="document-item">
                          <div class="d-flex align-items-center">
                            <span class="document-icon">
                              <i class="ti ti-music"></i>
                            </span>
                            <div class="ms-2">
                              <h6>Ecommerce.zip</h6>
                              <p>6.25 MB audio file</p>
                            </div>
                          </div>
                          <a href="javascript:void(0);" class="download-icon">
                            <i class="ti ti-download"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-wrapper other-info">
            <h5 class="sub-title">Common in 4 Groups</h5>
            <div class="card">
              <div class="card-body list-group profile-item">
                <a href="javascript:void(0);" class="list-group-item">
                  <div class="d-flex align-items-center">
                    <div class="avatar avatar-lg bg-skyblue rounded-circle me-2">GU</div>
                    <div class="chat-user-info">
                      <h6>Gustov _family</h6>
                      <p>
                        Mark, Elizabeth, Aaron,
                        <span class="text-primary">More...</span>
                      </p>
                    </div>
                  </div>
                  <span class="link-icon"><i class="ti ti-chevron-right"></i></span>
                </a>
                <a href="javascript:void(0);" class="list-group-item border-0">
                  <div class="d-flex align-items-center">
                    <div class="avatar avatar-lg bg-info rounded-circle me-2">AM</div>
                    <div class="chat-user-info">
                      <h6>AM Technology</h6>
                      <p>
                        Roper, Deborah, David,
                        <span class="text-primary">More.. .</span>
                      </p>
                    </div>
                  </div>
                  <span class="link-icon"><i class="ti ti-chevron-right"></i></span>
                </a>
                <div class="text-center">
                  <a
                    href="javascript:void(0);"
                    class="view-all link-primary d-inline-flex align-items-center justify-content-center"
                  >
                    More Groups<i class="ti ti-arrow-right ms-2"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="content-wrapper other-info mb-0">
            <h5 class="sub-title">Others</h5>
            <div class="card">
              <div class="card-body list-group profile-item">
                <a
                  href="javascript:void(0);"
                  class="list-group-item"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#contact-favourite"
                >
                  <div class="profile-info">
                    <h6><i class="ti ti-graph me-2 text-default"></i>Favorites</h6>
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="badge badge-danger count-message me-1">12</span>
                    <span class="link-icon"><i class="ti ti-chevron-right"></i></span>
                  </div>
                </a>
                <a
                  href="javascript:void(0);"
                  class="list-group-item"
                  data-bs-toggle="modal"
                  data-bs-target="#mute-notification"
                >
                  <div class="profile-info">
                    <h6>
                      <i class="ti ti-volume-off me-2 text-warning"></i>Mute Notifications
                    </h6>
                  </div>
                  <div>
                    <span class="link-icon"><i class="ti ti-chevron-right"></i></span>
                  </div>
                </a>
                <a
                  href="javascript:void(0);"
                  class="list-group-item"
                  data-bs-toggle="modal"
                  data-bs-target="#msg-disapper"
                >
                  <div class="profile-info">
                    <h6>
                      <i class="ti ti-user-off me-2 text-info"></i>Disappearing Messages
                    </h6>
                  </div>
                  <div>
                    <span class="link-icon"><i class="ti ti-chevron-right"></i></span>
                  </div>
                </a>
                <div class="accordion accordion-flush">
                  <div class="accordion-item border-bottom">
                    <h2 class="accordion-header">
                      <a
                        href="#"
                        class="accordion-button px-0 collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#chatuser-encryption"
                        aria-expanded="false"
                        aria-controls="chatuser-collapse1"
                      >
                        <i class="ti ti-shield me-2 text-purple"></i>Encryption
                      </a>
                    </h2>
                    <div id="chatuser-encryption" class="accordion-collapse collapse">
                      <div class="accordion-body p-0 pb-3">
                        <p class="mb-2">Messages are end-to-end encrypted</p>
                        <div class="text-center">
                          <a
                            class="view-all link-primary d-inline-flex align-items-center justify-content-center"
                            href="javascript:void(0);"
                          >
                            Click to learn more<i class="ti ti-arrow-right ms-2"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  href="javascript:void(0);"
                  class="list-group-item"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#group-settings"
                >
                  <div class="profile-info">
                    <h6>
                      <i class="ti ti-settings me-2 text-primary"></i>Group Settings
                    </h6>
                  </div>
                  <div>
                    <span class="link-icon"><i class="ti ti-chevron-right"></i></span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Contact Info -->
</template>
