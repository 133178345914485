<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
export default {
  data() {
    return {
      showReply: false,
      replyMessage: "",
      selectedChat: "All Chats",
      chatVisible: false,
      settings: {
        suppressScrollX: true,
      },
      activeClass: "active",
      showEmojiList: false,
      showEmojiListOne: false,
    };
  },
  methods: {
    scrollHanle() {},
    changeChat(chat) {
      this.selectedChat = chat;
    },
    submitForm() {
      this.$router.push("/chat");
    },
    handleButtonClick() {
      this.showReply = true;
      this.replyMessage = "Thank you for your support";
    },
    closeReply() {
      this.showReply = false;
    },
    toggleEmojiList() {
      this.showEmojiList = !this.showEmojiList;
    },
    toggleEmojiListOne() {
      this.showEmojiListOne = !this.showEmojiListOne;
    },
    showChat() {
      this.chatVisible = true;
    },
    hideChat() {
      this.chatVisible = false;
    },
  },
  components: {
    PerfectScrollbar,
  },
};
</script>
<template>
  <div class="chat chat-messages" id="middle">
    <div>
      <div class="chat-header">
        <div class="user-details">
          <div class="d-xl-none">
            <a class="text-muted chat-close me-2" href="#">
              <i class="fas fa-arrow-left"></i>
            </a>
          </div>
          <div class="avatar avatar-lg online flex-shrink-0">
            <img
              src="@/assets/img/groups/group-01.jpg"
              class="rounded-circle"
              alt="image"
            />
          </div>
          <div class="ms-2 overflow-hidden">
            <h6>The Dream Team</h6>
            <p class="last-seen text-truncate">
              40 Member, <span class="text-success">24 Online</span>
            </p>
          </div>
        </div>
        <div class="chat-options">
          <ul>
            <li>
              <div class="avatar-list-stacked avatar-group-md d-flex">
                <span class="avatar avatar-rounded">
                  <img src="@/assets/img/profiles/avatar-06.jpg" alt="img" />
                </span>
                <span class="avatar avatar-rounded">
                  <img src="@/assets/img/profiles/avatar-01.jpg" alt="img" />
                </span>
                <span class="avatar avatar-rounded">
                  <img src="@/assets/img/profiles/avatar-02.jpg" alt="img" />
                </span>
                <span class="avatar avatar-rounded">
                  <img src="@/assets/img/profiles/avatar-04.jpg" alt="img" />
                </span>
                <a
                  class="avatar bg-primary avatar-rounded text-fixed-white"
                  href="javascript:void(0);"
                >
                  35+
                </a>
              </div>
            </li>
            <a-tooltip placement="bottom">
              <template #title>Search</template>
              <li>
                <a
                  href="javascript:void(0)"
                  class="btn chat-search-btn"
                  @click="showChat"
                >
                  <i class="ti ti-search"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Group Info</template>
              <li>
                <a
                  href="javascript:void(0)"
                  class="btn"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#contact-profile"
                >
                  <i class="ti ti-info-circle"></i>
                </a>
              </li>
            </a-tooltip>
            <li>
              <a class="btn no-bg" href="#" data-bs-toggle="dropdown">
                <i class="ti ti-dots-vertical"></i>
              </a>
              <ul class="dropdown-menu dropdown-menu-end p-3">
                <li>
                  <router-link to="/index" class="dropdown-item"
                    ><i class="ti ti-x me-2"></i>Close Group</router-link
                  >
                </li>
                <li>
                  <a
                    href="#"
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#mute-notification"
                    ><i class="ti ti-volume-off me-2"></i>Mute Notification</a
                  >
                </li>
                <li>
                  <a
                    href="#"
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#disappearing-messages"
                    ><i class="ti ti-clock-hour-4 me-2"></i>Disappearing Message</a
                  >
                </li>
                <li>
                  <a
                    href="#"
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#clear-chat"
                    ><i class="ti ti-clear-all me-2"></i>Clear Message</a
                  >
                </li>
                <li>
                  <a
                    href="#"
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-chat"
                    ><i class="ti ti-trash me-2"></i>Delete Group</a
                  >
                </li>
                <li>
                  <a
                    href="#"
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#report-user"
                    ><i class="ti ti-thumb-down me-2"></i>Report</a
                  >
                </li>
                <li>
                  <a
                    href="#"
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#block-user"
                    ><i class="ti ti-ban me-2"></i>Block</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <!-- Chat Search -->
        <div class="chat-search search-wrap contact-search" :class="{ 'visible-chat': chatVisible }">
          <form>
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Search Contacts" />
              <span class="input-group-text" @click="hideChat"><i class="ti ti-search"></i></span>
            </div>
          </form>
        </div>
        <!-- /Chat Search -->
      </div>
      <div class="chat-body chat-page-group slimscroll">
        <perfect-scrollbar
          class="scroll-areaone"
          :settings="settings"
          @ps-scroll-y="scrollHanle"
        >
          <div class="messages">
            <div class="chats">
              <div class="chat-avatar">
                <img
                  src="@/assets/img/profiles/avatar-06.jpg"
                  class="rounded-circle"
                  alt="image"
                />
              </div>
              <div class="chat-content">
                <div class="chat-profile-name">
                  <h6>
                    Edward Lietz<i class="ti ti-circle-filled fs-7 mx-2"></i
                    ><span class="chat-time">02:39 PM</span
                    ><span class="msg-read success"><i class="ti ti-checks"></i></span>
                  </h6>
                </div>
                <div class="chat-info">
                  <div class="message-content">
                    Hey Design Dynamos! How's everyone doing today?
                    <div class="emoj-group">
                      <ul>
                        <li class="emoj-action">
                          <a href="javascript:void(0);" @click="toggleEmojiList"
                            ><i class="ti ti-mood-smile"></i
                          ></a>
                          <div
                            :style="{ display: showEmojiList ? 'block' : 'none' }"
                            class="emoj-group-list"
                          >
                            <ul>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-02.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-05.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-06.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-07.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-08.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-03.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-10.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-09.svg" alt="Icon"
                                /></a>
                              </li>
                              <li class="add-emoj">
                                <a href="javascript:void(0);"
                                  ><i class="ti ti-plus"></i
                                ></a>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <a href="#"><i class="ti ti-arrow-forward-up"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="chat-actions">
                    <a class="#" href="#" data-bs-toggle="dropdown">
                      <i class="ti ti-dots-vertical"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end p-3">
                      <li>
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#contact-message"
                          ><i class="ti ti-info-circle me-2"></i>Message Info</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#" @click="handleButtonClick"
                          ><i class="ti ti-arrow-back-up me-2"></i>Reply</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-arrow-forward-up-double me-2"></i>Forward</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-file-export me-2"></i>Copy</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-heart me-2"></i>Mark as Favourite</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-edit me-2"></i>Edit</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-trash me-2"></i>Delete Group</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="chats chats-right">
              <div class="chat-content">
                <div class="chat-profile-name text-end">
                  <h6>
                    You<i class="ti ti-circle-filled fs-7 mx-2"></i
                    ><span class="chat-time">02:39 PM</span
                    ><span class="msg-read success"><i class="ti ti-checks"></i></span>
                  </h6>
                </div>
                <div class="chat-info">
                  <div class="chat-actions">
                    <a class="#" href="#" data-bs-toggle="dropdown">
                      <i class="ti ti-dots-vertical"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end p-3">
                      <li>
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#contact-message"
                          ><i class="ti ti-info-circle me-2"></i>Message Info</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#" @click="handleButtonClick"
                          ><i class="ti ti-arrow-back-up me-2"></i>Reply</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-arrow-forward-up-double me-2"></i>Forward</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-heart me-2"></i>Mark as Favourite</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-edit me-2"></i>Edit</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-trash me-2"></i>Delete Group</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="message-content">
                    Hey Edward! Doing well. Just finished up a client meeting. How's
                    everyone else?
                    <div class="emoj-group">
                      <ul>
                        <li class="emoj-action">
                          <a href="javascript:void(0);" @click="toggleEmojiList"
                            ><i class="ti ti-mood-smile"></i
                          ></a>
                          <div
                            :style="{ display: showEmojiList ? 'block' : 'none' }"
                            class="emoj-group-list"
                          >
                            <ul>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-02.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-05.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-06.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-07.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-08.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-03.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-10.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-09.svg" alt="Icon"
                                /></a>
                              </li>
                              <li class="add-emoj">
                                <a href="javascript:void(0);"
                                  ><i class="ti ti-plus"></i
                                ></a>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <a href="#"><i class="ti ti-arrow-forward-up"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="emonji-wrap">
                  <a href="javascript:void(0);"
                    ><img
                      src="@/assets/img/icons/emonji-02.svg"
                      class="me-2"
                      alt="icon"
                    />24</a
                  >
                  <a href="javascript:void(0);"
                    ><img
                      src="@/assets/img/icons/emonji-03.svg"
                      class="me-2"
                      alt="icon"
                    />15</a
                  >
                  <a href="javascript:void(0);"
                    ><img
                      src="@/assets/img/icons/emonji-04.svg"
                      class="me-2"
                      alt="icon"
                    />15</a
                  >
                </div>
              </div>
              <div class="chat-avatar">
                <img
                  src="@/assets/img/profiles/avatar-17.jpg"
                  class="rounded-circle dreams_chat"
                  alt="image"
                />
              </div>
            </div>
            <div class="chats">
              <div class="chat-avatar">
                <img
                  src="@/assets/img/profiles/avatar-01.jpg"
                  class="rounded-circle"
                  alt="image"
                />
              </div>
              <div class="chat-content">
                <div class="chat-profile-name">
                  <h6>
                    Aaryian Jose<i class="ti ti-circle-filled fs-7 mx-2"></i
                    ><span class="chat-time">02:40 PM</span
                    ><span class="msg-read success"><i class="ti ti-checks"></i></span>
                  </h6>
                </div>
                <div class="message-content">
                  Hi all! I'm great, just wrapping up the wireframe for the new project.
                  Excited to share it with you guys!
                </div>
                <div class="emonji-wrap">
                  <a href="javascript:void(0);"
                    ><img
                      src="@/assets/img/icons/emonji-02.svg"
                      class="me-2"
                      alt="icon"
                    />24</a
                  >
                  <a href="javascript:void(0);"
                    ><img
                      src="@/assets/img/icons/emonji-03.svg"
                      class="me-2"
                      alt="icon"
                    />15</a
                  >
                  <a href="javascript:void(0);"
                    ><img
                      src="@/assets/img/icons/emonji-04.svg"
                      class="me-2"
                      alt="icon"
                    />15</a
                  >
                </div>
              </div>
            </div>
            <div class="chats">
              <div class="chat-avatar">
                <img
                  src="@/assets/img/profiles/avatar-02.jpg"
                  class="rounded-circle"
                  alt="image"
                />
              </div>
              <div class="chat-content">
                <div class="chat-profile-name">
                  <h6>
                    Aaryian Jose<i class="ti ti-circle-filled fs-7 mx-2"></i
                    ><span class="chat-time">02:40 PM</span
                    ><span class="msg-read success"><i class="ti ti-checks"></i></span>
                  </h6>
                </div>
                <div class="message-content">
                  <span class="text-primary">@Aaryian Jose,</span> can you share the
                  wireframe here? Would love to give feedback before our meeting later.
                </div>
              </div>
            </div>
            <div class="chats">
              <div class="chat-avatar">
                <img
                  src="@/assets/img/profiles/avatar-01.jpg"
                  class="rounded-circle"
                  alt="image"
                />
              </div>
              <div class="chat-content">
                <div class="chat-profile-name">
                  <h6>
                    Aaryian Jose<i class="ti ti-circle-filled fs-7 mx-2"></i
                    ><span class="chat-time">02:39 PM</span
                    ><span class="msg-read success"><i class="ti ti-checks"></i></span>
                  </h6>
                </div>
                <div class="chat-info">
                  <div class="message-content">
                    <div class="file-attach">
                      <span class="file-icon">
                        <i class="ti ti-files"></i>
                      </span>
                      <div class="ms-2 overflow-hidden">
                        <h6 class="mb-1">Ecommerce.zip</h6>
                        <p>14.23 KB</p>
                      </div>
                      <a href="javascript:void(0);" class="download-icon">
                        <i class="ti ti-download"></i>
                      </a>
                    </div>
                    <div class="emoj-group">
                      <ul>
                        <li class="emoj-action">
                          <a href="javascript:void(0);" @click="toggleEmojiList"
                            ><i class="ti ti-mood-smile"></i
                          ></a>
                          <div
                            :style="{ display: showEmojiList ? 'block' : 'none' }"
                            class="emoj-group-list"
                          >
                            <ul>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-02.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-05.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-06.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-07.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-08.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-03.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-10.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-09.svg" alt="Icon"
                                /></a>
                              </li>
                              <li class="add-emoj">
                                <a href="javascript:void(0);"
                                  ><i class="ti ti-plus"></i
                                ></a>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <a href="#"><i class="ti ti-arrow-forward-up"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="chat-actions">
                    <a class="#" href="#" data-bs-toggle="dropdown">
                      <i class="ti ti-dots-vertical"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end p-3">
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#contact-message"
                          ><i class="ti ti-info-circle me-2"></i>Message Info</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#" @click="handleButtonClick"
                          ><i class="ti ti-arrow-back-up me-2"></i>Reply</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-arrow-forward-up-double me-2"></i>Forward</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-heart me-2"></i>Mark as Favourite</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-edit me-2"></i>Edit</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-trash me-2"></i>Delete Group</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="chats chats-right">
              <div class="chat-content">
                <div class="chat-profile-name justify-content-end">
                  <h6>
                    You<i class="ti ti-circle-filled fs-7 mx-2"></i
                    ><span class="chat-time">02:39 PM</span
                    ><span class="msg-read success"><i class="ti ti-checks"></i></span>
                  </h6>
                </div>
                <div class="message-content">
                  Send me background images if any for our new project??
                  <div class="emoj-group wrap-emoji-group">
                    <ul>
                      <li class="emoj-action">
                        <a href="javascript:void(0);" @click="toggleEmojiList"
                          ><i class="ti ti-mood-smile"></i
                        ></a>
                        <div
                          :style="{ display: showEmojiList ? 'block' : 'none' }"
                          class="emoj-group-list"
                        >
                          <ul>
                            <li>
                              <a href="javascript:void(0);"
                                ><img src="@/assets/img/icons/emonji-02.svg" alt="Icon"
                              /></a>
                            </li>
                            <li>
                              <a href="javascript:void(0);"
                                ><img src="@/assets/img/icons/emonji-05.svg" alt="Icon"
                              /></a>
                            </li>
                            <li>
                              <a href="javascript:void(0);"
                                ><img src="@/assets/img/icons/emonji-06.svg" alt="Icon"
                              /></a>
                            </li>
                            <li>
                              <a href="javascript:void(0);"
                                ><img src="@/assets/img/icons/emonji-07.svg" alt="Icon"
                              /></a>
                            </li>
                            <li>
                              <a href="javascript:void(0);"
                                ><img src="@/assets/img/icons/emonji-08.svg" alt="Icon"
                              /></a>
                            </li>
                            <li class="add-emoj">
                              <a href="javascript:void(0);"><i class="ti ti-plus"></i></a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <a href="javascript:void(0);"
                          ><i class="ti ti-arrow-forward-up"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="chat-avatar">
                <img
                  src="@/assets/img/profiles/avatar-17.jpg"
                  class="rounded-circle dreams_chat"
                  alt="image"
                />
              </div>
            </div>
            <div class="chats">
              <div class="chat-avatar">
                <img
                  src="@/assets/img/profiles/avatar-06.jpg"
                  class="rounded-circle"
                  alt="image"
                />
              </div>
              <div class="chat-content">
                <div class="chat-profile-name">
                  <h6>
                    Edward Lietz<i class="ti ti-circle-filled fs-7 mx-2"></i
                    ><span class="chat-time">02:39 PM</span
                    ><span class="msg-read success"><i class="ti ti-checks"></i></span>
                  </h6>
                </div>
                <div class="chat-info">
                  <div class="message-content">
                    <group-image></group-image>
                    <div class="emoj-group">
                      <ul>
                        <li class="emoj-action">
                          <a href="javascript:void(0);" @click="toggleEmojiList"
                            ><i class="ti ti-mood-smile"></i
                          ></a>
                          <div
                            :style="{ display: showEmojiList ? 'block' : 'none' }"
                            class="emoj-group-list"
                          >
                            <ul>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-02.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-05.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-06.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-07.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-08.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-03.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-10.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-09.svg" alt="Icon"
                                /></a>
                              </li>
                              <li class="add-emoj">
                                <a href="javascript:void(0);"
                                  ><i class="ti ti-plus"></i
                                ></a>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <a href="#"><i class="ti ti-arrow-forward-up"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="chat-actions">
                    <a class="#" href="#" data-bs-toggle="dropdown">
                      <i class="ti ti-dots-vertical"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end p-3">
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#contact-message"
                          ><i class="ti ti-info-circle me-2"></i>Message Info</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#" @click="handleButtonClick"
                          ><i class="ti ti-arrow-back-up me-2"></i>Reply</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-arrow-forward-up-double me-2"></i>Forward</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-heart me-2"></i>Mark as Favourite</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-trash me-2"></i>Delete</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-check me-2"></i>Mark as Unread</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-edit me-2"></i>Edit</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-trash me-2"></i>Delete Group</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="chat-line">
              <span class="chat-date">Yesterday</span>
            </div>
            <div class="chats">
              <div class="chat-avatar">
                <img
                  src="@/assets/img/profiles/avatar-04.jpg"
                  class="rounded-circle"
                  alt="image"
                />
              </div>
              <div class="chat-content">
                <div class="chat-profile-name">
                  <h6>
                    Sarika Jain<i class="ti ti-circle-filled fs-7 mx-2"></i
                    ><span class="chat-time">02:39 PM</span
                    ><span class="msg-read success"><i class="ti ti-checks"></i></span>
                  </h6>
                </div>
                <div class="chat-info">
                  <div class="message-content">
                    <a href="javascript:void(0);" class="link-info">@all</a> if anyone can
                    you share final output video of current project?
                    <div class="emoj-group">
                      <ul>
                        <li class="emoj-action">
                          <a href="javascript:void(0);" @click="toggleEmojiList"
                            ><i class="ti ti-mood-smile"></i
                          ></a>
                          <div
                            :style="{ display: showEmojiList ? 'block' : 'none' }"
                            class="emoj-group-list"
                          >
                            <ul>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-02.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-05.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-06.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-07.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-08.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-03.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-10.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-09.svg" alt="Icon"
                                /></a>
                              </li>
                              <li class="add-emoj">
                                <a href="javascript:void(0);"
                                  ><i class="ti ti-plus"></i
                                ></a>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <a href="#"><i class="ti ti-arrow-forward-up"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="chat-actions">
                    <a class="#" href="#" data-bs-toggle="dropdown">
                      <i class="ti ti-dots-vertical"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end p-3">
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#contact-message"
                          ><i class="ti ti-info-circle me-2"></i>Message Info</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#" @click="handleButtonClick"
                          ><i class="ti ti-arrow-back-up me-2"></i>Reply</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-arrow-forward-up-double me-2"></i>Forward</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-heart me-2"></i>Mark as Favourite</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-edit me-2"></i>Edit</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-trash me-2"></i>Delete Group</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="chats">
              <div class="chat-avatar">
                <img
                  src="@/assets/img/profiles/avatar-06.jpg"
                  class="rounded-circle"
                  alt="image"
                />
              </div>
              <div class="chat-content">
                <div class="chat-profile-name">
                  <h6>
                    Edward Lietz<i class="ti ti-circle-filled fs-7 mx-2"></i
                    ><span class="chat-time">02:39 PM</span
                    ><span class="msg-read success"><i class="ti ti-checks"></i></span>
                  </h6>
                </div>
                <div class="chat-info">
                  <div class="message-content">
                    <div class="message-video">
                      <video width="400" controls>
                        <source src="@/assets/img/video/video.mp4" type="video/mp4" />
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                    <div class="emoj-group">
                      <ul>
                        <li class="emoj-action">
                          <a href="javascript:void(0);" @click="toggleEmojiList"
                            ><i class="ti ti-mood-smile"></i
                          ></a>
                          <div
                            :style="{ display: showEmojiList ? 'block' : 'none' }"
                            class="emoj-group-list"
                          >
                            <ul>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-02.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-05.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-06.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-07.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-08.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-03.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-10.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-09.svg" alt="Icon"
                                /></a>
                              </li>
                              <li class="add-emoj">
                                <a href="javascript:void(0);"
                                  ><i class="ti ti-plus"></i
                                ></a>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <a href="#"><i class="ti ti-arrow-forward-up"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="chat-actions">
                    <a class="#" href="#" data-bs-toggle="dropdown">
                      <i class="ti ti-dots-vertical"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end p-3">
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#contact-message"
                          ><i class="ti ti-info-circle me-2"></i>Message Info</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#" @click="handleButtonClick"
                          ><i class="ti ti-arrow-back-up me-2"></i>Reply</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-arrow-forward-up-double me-2"></i>Forward</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-heart me-2"></i>Mark as Favourite</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-edit me-2"></i>Edit</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-trash me-2"></i>Delete Group</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="chats chats-right">
              <div class="chat-content">
                <div class="chat-profile-name text-end">
                  <h6>
                    You<i class="ti ti-circle-filled fs-7 mx-2"></i
                    ><span class="chat-time">02:39 PM</span
                    ><span class="msg-read success"><i class="ti ti-checks"></i></span>
                  </h6>
                </div>
                <div class="chat-info">
                  <div class="chat-actions">
                    <a class="#" href="#" data-bs-toggle="dropdown">
                      <i class="ti ti-dots-vertical"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end p-3">
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#contact-message"
                          ><i class="ti ti-info-circle me-2"></i>Message Info</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#" @click="handleButtonClick"
                          ><i class="ti ti-arrow-back-up me-2"></i>Reply</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-arrow-forward-up-double me-2"></i>Forward</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-heart me-2"></i>Mark as Favourite</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-edit me-2"></i>Edit</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-trash me-2"></i>Delete Group</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="message-content">
                    Thanks for Sharing!!! Can we have a call
                    <div class="emoj-group">
                      <ul>
                        <li class="emoj-action">
                          <a href="javascript:void(0);" @click="toggleEmojiList"
                            ><i class="ti ti-mood-smile"></i
                          ></a>
                          <div
                            :style="{ display: showEmojiList ? 'block' : 'none' }"
                            class="emoj-group-list"
                          >
                            <ul>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-02.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-05.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-06.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-07.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-08.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-03.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-10.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-09.svg" alt="Icon"
                                /></a>
                              </li>
                              <li class="add-emoj">
                                <a href="javascript:void(0);"
                                  ><i class="ti ti-plus"></i
                                ></a>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <a href="#"><i class="ti ti-arrow-forward-up"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat-avatar">
                <img
                  src="@/assets/img/profiles/avatar-17.jpg"
                  class="rounded-circle dreams_chat"
                  alt="image"
                />
              </div>
            </div>
            <div class="chats">
              <div class="chat-avatar">
                <img
                  src="@/assets/img/profiles/avatar-05.jpg"
                  class="rounded-circle"
                  alt="image"
                />
              </div>
              <div class="chat-content">
                <div class="chat-profile-name">
                  <h6>
                    Federico Wells<i class="ti ti-circle-filled fs-7 mx-2"></i
                    ><span class="chat-time">02:39 PM</span
                    ><span class="msg-read success"><i class="ti ti-checks"></i></span>
                  </h6>
                </div>
                <div class="chat-info">
                  <div class="message-content">
                    <div class="chat-profile-name">
                      <h6>You</h6>
                    </div>
                    <div class="message-reply">
                      Thanks for Sharing!!! Can we have a call??
                    </div>
                    Yes Please
                    <div class="emoj-group">
                      <ul>
                        <li class="emoj-action">
                          <a href="javascript:void(0);" @click="toggleEmojiList"
                            ><i class="ti ti-mood-smile"></i
                          ></a>
                          <div
                            :style="{ display: showEmojiList ? 'block' : 'none' }"
                            class="emoj-group-list"
                          >
                            <ul>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-02.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-05.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-06.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-07.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-08.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-03.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-10.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-09.svg" alt="Icon"
                                /></a>
                              </li>
                              <li class="add-emoj">
                                <a href="javascript:void(0);"
                                  ><i class="ti ti-plus"></i
                                ></a>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <a href="#"><i class="ti ti-arrow-forward-up"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="chat-actions">
                    <a class="#" href="#" data-bs-toggle="dropdown">
                      <i class="ti ti-dots-vertical"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end p-3">
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#contact-message"
                          ><i class="ti ti-info-circle me-2"></i>Message Info</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#" @click="handleButtonClick"
                          ><i class="ti ti-arrow-back-up me-2"></i>Reply</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-arrow-forward-up-double me-2"></i>Forward</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-heart me-2"></i>Mark as Favourite</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-edit me-2"></i>Edit</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-trash me-2"></i>Delete Group</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="chats">
              <div class="chat-avatar">
                <img
                  src="@/assets/img/profiles/avatar-05.jpg"
                  class="rounded-circle"
                  alt="image"
                />
              </div>
              <div class="chat-content">
                <div class="chat-profile-name">
                  <h6>
                    Federico Wells<i class="ti ti-circle-filled fs-7 mx-2"></i
                    ><span class="chat-time">02:39 PM</span
                    ><span class="msg-read success"><i class="ti ti-checks"></i></span>
                  </h6>
                </div>
                <div class="chat-info">
                  <div class="message-content">
                    <div class="file-attach">
                      <div class="d-flex align-items-center">
                        <span class="file-icon bg-danger text-white">
                          <i class="ti ti-phone-call"></i>
                        </span>
                        <div class="ms-2 overflow-hidden">
                          <h6 class="mb-1 text-truncate">Missed Audio Call</h6>
                          <p>10 Min 23 Sec</p>
                        </div>
                      </div>
                      <a href="javascript:void(0);" class="download-icon">
                        <i class="ti ti-download"></i>
                      </a>
                    </div>
                    <div class="emoj-group">
                      <ul>
                        <li class="emoj-action">
                          <a href="javascript:void(0);" @click="toggleEmojiList"
                            ><i class="ti ti-mood-smile"></i
                          ></a>
                          <div
                            :style="{ display: showEmojiList ? 'block' : 'none' }"
                            class="emoj-group-list"
                          >
                            <ul>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-02.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-05.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-06.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-07.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-08.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-03.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-10.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-09.svg" alt="Icon"
                                /></a>
                              </li>
                              <li class="add-emoj">
                                <a href="javascript:void(0);"
                                  ><i class="ti ti-plus"></i
                                ></a>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <a href="#"><i class="ti ti-arrow-forward-up"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="chat-actions">
                    <a class="#" href="#" data-bs-toggle="dropdown">
                      <i class="ti ti-dots-vertical"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end p-3">
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#contact-message"
                          ><i class="ti ti-info-circle me-2"></i>Message Info</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#" @click="handleButtonClick"
                          ><i class="ti ti-arrow-back-up me-2"></i>Reply</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-arrow-forward-up-double me-2"></i>Forward</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-heart me-2"></i>Mark as Favourite</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-edit me-2"></i>Edit</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-trash me-2"></i>Delete Group</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="chats chats-right">
              <div class="chat-content">
                <div class="chat-profile-name text-end">
                  <h6>
                    You<i class="ti ti-circle-filled fs-7 mx-2"></i
                    ><span class="chat-time">02:39 PM</span
                    ><span class="msg-read success"><i class="ti ti-checks"></i></span>
                  </h6>
                </div>
                <div class="chat-info">
                  <div class="chat-actions">
                    <a class="#" href="#" data-bs-toggle="dropdown">
                      <i class="ti ti-dots-vertical"></i>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end p-3">
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#contact-message"
                          ><i class="ti ti-info-circle me-2"></i>Message Info</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#" @click="handleButtonClick"
                          ><i class="ti ti-arrow-back-up me-2"></i>Reply</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-arrow-forward-up-double me-2"></i>Forward</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-heart me-2"></i>Mark as Favourite</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-edit me-2"></i>Edit</a
                        >
                      </li>
                      <li>
                        <a class="dropdown-item" href="#"
                          ><i class="ti ti-trash me-2"></i>Delete Group</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="message-content">
                    <div class="file-attach">
                      <span class="file-icon bg-success text-white">
                        <i class="ti ti-phone-incoming"></i>
                      </span>
                      <div class="ms-2 overflow-hidden">
                        <h6 class="mb-1">Audio Call Ended</h6>
                        <p>07 Min 34 Sec</p>
                      </div>
                      <a href="javascript:void(0);" class="download-icon">
                        <i class="ti ti-download"></i>
                      </a>
                    </div>
                    <div class="emoj-group">
                      <ul>
                        <li class="emoj-action">
                          <a href="javascript:void(0);" @click="toggleEmojiList"
                            ><i class="ti ti-mood-smile"></i
                          ></a>
                          <div
                            :style="{ display: showEmojiList ? 'block' : 'none' }"
                            class="emoj-group-list"
                          >
                            <ul>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-02.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-05.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-06.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-07.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-08.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-03.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-10.svg" alt="Icon"
                                /></a>
                              </li>
                              <li>
                                <a href="javascript:void(0);"
                                  ><img src="@/assets/img/icons/emonji-09.svg" alt="Icon"
                                /></a>
                              </li>
                              <li class="add-emoj">
                                <a href="javascript:void(0);"
                                  ><i class="ti ti-plus"></i
                                ></a>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <a href="#" data-bs-toggle="modal"
                            ><i class="ti ti-arrow-forward-up"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat-avatar">
                <img
                  src="@/assets/img/profiles/avatar-17.jpg"
                  class="rounded-circle dreams_chat"
                  alt="image"
                />
              </div>
            </div>
            <div class="chats">
              <div class="chat-avatar">
                <img
                  src="@/assets/img/profiles/avatar-05.jpg"
                  class="rounded-circle"
                  alt="image"
                />
              </div>
              <div class="chat-content">
                <div class="chat-profile-name">
                  <h6>
                    Federico Wells<i class="ti ti-circle-filled fs-7 mx-2"></i
                    ><span class="chat-time">02:39 PM</span
                    ><span class="msg-read success"><i class="ti ti-checks"></i></span>
                  </h6>
                </div>
                <div class="message-content">
                  <span class="animate-typing"
                    >is typing
                    <span class="dot"></span>
                    <span class="dot"></span>
                    <span class="dot"></span>
                  </span>
                  <div class="emoj-group">
                    <ul>
                      <li class="emoj-action">
                        <a href="javascript:void(0);" @click="toggleEmojiList"
                          ><i class="ti ti-mood-smile"></i
                        ></a>
                        <div
                          :style="{ display: showEmojiList ? 'block' : 'none' }"
                          class="emoj-group-list"
                        >
                          <ul>
                            <li>
                              <a href="javascript:void(0);"
                                ><img src="@/assets/img/icons/emonji-02.svg" alt="Icon"
                              /></a>
                            </li>
                            <li>
                              <a href="javascript:void(0);"
                                ><img src="@/assets/img/icons/emonji-05.svg" alt="Icon"
                              /></a>
                            </li>
                            <li>
                              <a href="javascript:void(0);"
                                ><img src="@/assets/img/icons/emonji-06.svg" alt="Icon"
                              /></a>
                            </li>
                            <li>
                              <a href="javascript:void(0);"
                                ><img src="@/assets/img/icons/emonji-07.svg" alt="Icon"
                              /></a>
                            </li>
                            <li>
                              <a href="javascript:void(0);"
                                ><img src="@/assets/img/icons/emonji-08.svg" alt="Icon"
                              /></a>
                            </li>
                            <li class="add-emoj">
                              <a href="javascript:void(0);"><i class="ti ti-plus"></i></a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <a href="#"><i class="ti ti-arrow-forward-up"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <div class="chat-footer">
      <form class="footer-form">
        <div class="chat-footer-wrap">
          <div class="form-item">
            <a href="#" class="action-circle"><i class="ti ti-microphone"></i></a>
          </div>
          <div class="form-wrap">
            <div v-if="showReply" class="chats reply-chat d-flex">
              <div class="chat-avatar">
                <img
                  src="@/assets/img/profiles/avatar-06.jpg"
                  class="rounded-circle"
                  alt="image"
                />
              </div>
              <div class="chat-content">
                <div class="chat-profile-name">
                  <h6>
                    Edward Lietz<i class="ti ti-circle-filled fs-7 mx-2"></i
                    ><span class="chat-time">02:39 PM</span
                    ><span class="msg-read success"><i class="ti ti-checks"></i></span>
                  </h6>
                </div>
                <div class="chat-info">
                  <div class="message-content">
                    <div class="message-reply">{{ replyMessage }}</div>
                  </div>
                </div>
              </div>
              <a href="#" @click="closeReply" class="close-replay">
                <i class="ti ti-x"></i>
              </a>
            </div>
            <input type="text" class="form-control" placeholder="Type Your Message" />
          </div>
          <div class="form-item emoj-action-foot">
            <a href="#" class="action-circle" @click="toggleEmojiListOne"><i class="ti ti-mood-smile"></i></a>
            <div class="emoj-group-list-foot down-emoji-circle" :style="{ display: showEmojiListOne ? 'block' : 'none' }">
              <ul>
                <li>
                  <a href="javascript:void(0);"
                    ><img src="@/assets/img/icons/emonji-02.svg" alt="Icon"
                  /></a>
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><img src="@/assets/img/icons/emonji-05.svg" alt="Icon"
                  /></a>
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><img src="@/assets/img/icons/emonji-06.svg" alt="Icon"
                  /></a>
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><img src="@/assets/img/icons/emonji-07.svg" alt="Icon"
                  /></a>
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><img src="@/assets/img/icons/emonji-08.svg" alt="Icon"
                  /></a>
                </li>
                <li class="add-emoj">
                  <a href="javascript:void(0);"><i class="ti ti-plus"></i></a>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="form-item position-relative d-flex align-items-center justify-content-center"
          >
            <a href="#" class="action-circle file-action position-absolute">
              <i class="ti ti-folder"></i>
            </a>
            <input
              type="file"
              class="open-file position-relative"
              name="files"
              id="files"
            />
          </div>
          <div class="form-item">
            <a href="#" data-bs-toggle="dropdown">
              <i class="ti ti-dots-vertical"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end p-3">
              <a href="#" class="dropdown-item"
                ><i class="ti ti-file-text me-2"></i>Document</a
              >
              <a href="#" class="dropdown-item"
                ><i class="ti ti-camera-selfie me-2"></i>Camera</a
              >
              <a href="#" class="dropdown-item"
                ><i class="ti ti-photo-up me-2"></i>Gallery</a
              >
              <a href="#" class="dropdown-item"><i class="ti ti-music me-2"></i>Audio</a>
              <a href="#" class="dropdown-item"
                ><i class="ti ti-map-pin-share me-2"></i>Location</a
              >
              <a href="#" class="dropdown-item"
                ><i class="ti ti-user-check me-2"></i>Contact</a
              >
            </div>
          </div>
          <div class="form-btn">
            <button class="btn btn-primary" type="submit">
              <i class="ti ti-send"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
