<script>
export default {
  data() {
    return {
      title: "Profile Settings",
      text: "General Settings",
      text1: "Profile Settings",
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- General Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-xl-3 col-md-4">
              <general-sidebar></general-sidebar>
            </div>
            <!-- Profile Settings -->
            <div class="col-xl-9 col-md-8">
              <div class="card setting-content mb-0">
                <div class="card-header px-0 mx-3">
                  <h4>Profile Settings</h4>
                </div>
                <div class="card-body pb-0">
                  <div class="d-flex align-items-center mb-3">
                    <div class="avatar avatar-xxl me-3">
                      <img
                        src="@/assets/admin/img/users/user-08.jpg"
                        class="rounded img-fluid"
                        alt="user"
                      />
                    </div>
                    <div>
                      <p class="text-dark fw-medium mb-0">Upload Project Logo</p>
                      <p class="fs-13 mb-2">Image support png, jpeg and gif under 10MB</p>
                      <div class="d-flex align-items-center">
                        <div class="profile-uploader d-flex align-items-center">
                          <div class="drag-upload-btn btn mb-0">
                            Upload
                            <input type="file" class="form-control image-sign" multiple />
                          </div>
                          <a
                            href="javascript:void(0);"
                            class="btn btn-md btn-outline-primary"
                            >Remove</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="mb-3">
                        <label class="form-label">First Name</label>
                        <input type="text" class="form-control" />
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="mb-3">
                        <label class="form-label">Last Name</label>
                        <input type="text" class="form-control" />
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="mb-3">
                        <label class="form-label">Email Address</label>
                        <input type="text" class="form-control" />
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="mb-3">
                        <label class="form-label">Phone Number</label>
                        <input type="text" class="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer mx-3 px-0">
                  <div class="d-flex align-items-center justify-content-end m-0">
                    <a href="#" class="btn btn-outline-primary me-2">Cancel</a>
                    <a href="#" class="btn btn-primary">Save</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Profile Settings -->
          </div>
        </div>
      </div>
      <!-- /General Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
