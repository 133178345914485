<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card d-inline-flex setting-header mb-3">
        <div class="card-body d-flex align-items-center flex-wrap row-gap-2 p-0">
          <router-link
            to="/admin-template/settings/profile-settings"
            :class="{
              active:
                $route.path === '/admin-template/settings/profile-settings' ||
                $route.path === '/admin-template/settings/change-password' ||
                $route.path === '/admin-template/settings/notification-settings',
            }"
            class="ps-3"
          >
            <i class="ti ti-settings-cog me-2"></i>General Settings
          </router-link>
          <router-link
            to="/admin-template/app-settings/company-settings"
            :class="{
              active:
                $route.path === '/admin-template/app-settings/authentication-settings' ||
                $route.path === '/admin-template/app-settings/social-auth' ||
                $route.path === '/admin-template/app-settings/chat-settings' ||
                $route.path === '/admin-template/app-settings/video-audio-settings' ||
                $route.path === '/admin-template/app-settings/integrations' ||
                $route.path === '/admin-template/app-settings/custom-fields',
            }"
          >
            <i class="ti ti-apps me-2"></i>App Settings
          </router-link>
          <router-link
            to="/admin-template/system-settings/localization-settings"
            :class="{
              active:
                $route.path === '/admin-template/system-settings/email-settings' ||
                $route.path === '/admin-template/system-settings/sms-settings' ||
                $route.path === '/admin-template/system-settings/chat-settings' ||
                $route.path === '/admin-template/system-settings/otp' ||
                $route.path === '/admin-template/system-settings/language' ||
                $route.path === '/admin-template/system-settings/language-web' ||
                $route.path === '/admin-template/system-settings/add-language' ||
                $route.path === '/admin-template/system-settings/gdpr',
            }"
          >
            <i class="ti ti-device-ipad-horizontal-cog me-2"></i>System Settings
          </router-link>
          <router-link
            to="/admin-template/theme-settings/appearance-settings"
            :class="{
              active:
                $route.path === '/admin-template/theme-settings/appearance-settings',
            }"
          >
            <i class="ti ti-layers-subtract me-2"></i>Theme Settings
          </router-link>
          <router-link
            to="/admin-template/others-settings/storage"
            :class="{
              active:
                $route.path === '/admin-template/others-settings/backup' ||
                $route.path === '/admin-template/others-settings/clear-cache' ||
                $route.path === '/admin-template/others-settings/ban-address'
            }"
            class="pe-3"
          >
            <i class="ti ti-settings-2 me-2"></i>Other Settings
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
