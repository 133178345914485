<template>
  <div class="card mb-3 mb-md-0">
    <div class="card-body setting-sidebar">
      <div class="d-flex">
        <router-link
          to="/admin-template/app-settings/company-settings"
          class="rounded flex-fill"
          ><i class="ti ti-building me-2"></i>Company Settings</router-link
        >
      </div>
      <div class="d-flex">
        <router-link to="authentication-settings" class="rounded flex-fill"
          ><i class="ti ti-forms me-2"></i>Authentication</router-link
        >
      </div>
      <div class="d-flex">
        <router-link to="social-auth" class="rounded flex-fill"
          ><i class="ti ti-social me-2"></i>Social Authentication
        </router-link>
      </div>
      <div class="d-flex">
        <router-link to="chat-settings" class="rounded flex-fill"
          ><i class="ti ti-message-circle-cog me-2"></i>Chat Settings
        </router-link>
      </div>
      <div class="d-flex">
        <router-link to="video-audio-settings" class="rounded flex-fill"
          ><i class="ti ti-settings-automation me-2"></i>Video/Audio Settings</router-link
        >
      </div>
      <div class="d-flex">
        <router-link to="custom-fields" class="rounded flex-fill"
          ><i class="ti ti-text-plus me-2"></i>Custom Fields</router-link
        >
      </div>
      <div class="d-flex">
        <router-link to="integrations" class="rounded flex-fill"
          ><i class="ti ti-plug-connected me-2"></i>Integrations</router-link
        >
      </div>
    </div>
  </div>
</template>
