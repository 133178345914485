<script>
export default {
  data() {
    return {
      InputType: ["Select", "Text"],
      ModuleSel: ["Select", "User"],
      StatusAct: ["Active", "Inactive"],
      EditInputType: ["Select", "Text"],
      EditModuleSel: ["Select", "User"],
      EditStatusAct: ["Active", "Inactive"],
    };
  },
  methods: {
    submitForm() {
      this.$router.push("custom-fields");
    },
  },
};
</script>
<template>
  <!-- Add New Fields -->
  <div class="modal fade" id="new-field">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Custom Field</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Module</label>
                  <vue-select :options="ModuleSel" id="modulesel" placeholder="Select" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Label</label>
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Default Value</label>
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Input Type</label>
                  <vue-select :options="InputType" id="inputtype" placeholder="Select" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="d-flex mb-3">
                  <label class="form-label me-3">Required</label>
                  <div class="form-check me-3">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="required"
                      id="required1"
                      checked
                    />
                    <label class="form-check-label" for="required1">Yes</label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      name="required"
                      type="radio"
                      id="required2"
                    />
                    <label class="form-check-label" for="required2">No</label>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Status</label>
                  <vue-select :options="StatusAct" id="statuct" placeholder="Select" />
                </div>
              </div>
            </div>
            <div class="row g-3">
              <div class="col-6">
                <a
                  href="#"
                  class="btn btn-outline-primary w-100"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  >Cancel</a
                >
              </div>
              <div class="col-6">
                <button
                  type="submit"
                  class="btn btn-primary w-100"
                  data-bs-dismiss="modal"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add New Fields -->

  <!-- Edit New Fields -->
  <div class="modal fade" id="edit-field">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Edit Custom Field</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ti ti-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Module</label>
                  <vue-select
                    :options="EditModuleSel"
                    id="editmodulesel"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Label</label>
                  <input class="form-control" type="text" value="Middle Name" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Default Value</label>
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Input Type</label>
                  <vue-select
                    :options="EditInputType"
                    id="editinputtype"
                    placeholder="Text"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="d-flex mb-3">
                  <label class="form-label me-3">Required</label>
                  <div class="form-check me-3">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="required"
                      id="required1"
                      checked
                    />
                    <label class="form-check-label" for="required1">Yes</label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      name="required"
                      type="radio"
                      id="required2"
                    />
                    <label class="form-check-label" for="required2">No</label>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Status</label>
                  <vue-select
                    :options="EditStatusAct"
                    id="editstatuct"
                    placeholder="Active"
                  />
                </div>
              </div>
            </div>
            <div class="row g-3">
              <div class="col-6">
                <a
                  href="#"
                  class="btn btn-outline-primary w-100"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  >Cancel</a
                >
              </div>
              <div class="col-6">
                <button
                  type="submit"
                  class="btn btn-primary w-100"
                  data-bs-dismiss="modal"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit New Fields -->
</template>
