<template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
    <swiper :slides-per-view="4" @swiper="onSwiper" @slideChange="onSlideChange">
      <SwiperSlide v-for="item in IndexSwiper" :key="item.id">
        <router-link to="/chat" class="chat-status text-center">
          <template v-if="item.Logo">
            <div class="avatar avatar-lg online bg-primary avatar-rounded">
              <span class="avatar-title fs-14 fw-medium">KG</span>
            </div>
          </template>
          <template v-if="!item.Logo">
            <div class="avatar avatar-lg online d-block">
            <img
              :src="require(`@/assets/img/profiles/${item.Image}`)"
              alt="Image"
              class="rounded-circle"
            />
          </div>
          </template>          
          
          <p>{{ item.Name }}</p>
        </router-link>
      </SwiperSlide>
    </swiper>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import IndexSwiper from "@/assets/json/index-swiper.json";

// Import Swiper styles
import "swiper/css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = () => {};
    const onSlideChange = () => {};
    return {
      onSwiper,
      onSlideChange,
      IndexSwiper: IndexSwiper,
    };
  },
};
</script>
