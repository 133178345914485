<script>
export default {
  data() {
    return {
      CountGerm: ["Select", "United States", "Germany", "Canada", "Australia"],
      CountGermEdit: ["Select", "United States", "Germany", "Canada", "Australia"],
    };
  },
  methods: {
    submitForm() {
      this.$router.push("user");
    },
  },
};
</script>
<template>
  <!-- Add user -->
  <div class="modal fade" id="add_user">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add New User</h4>
          <button
            type="button"
            class="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ti ti-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex align-items-center row-gap-2 mb-3 upload-frames">
                  <div class="outer-frames">
                    <a href="#" id="uploadLink">
                      <div class="frames"></div>
                      <span class="frame-add rounded-circle"
                        ><i class="ti ti-plus"></i
                      ></span>
                    </a>
                    <input
                      type="file"
                      id="imageInput"
                      accept="image/*"
                      style="display: none"
                    />
                  </div>
                  <div class="profile-upload">
                    <div class="profile-content">
                      <p class="fs-14">Upload Image</p>
                      <span>Image should be below 4 mb</span>
                    </div>
                    <div class="profile-uploader d-flex align-items-center">
                      <div class="drag-upload-btn mb-0">
                        Upload
                        <input type="file" class="form-control image-sign" multiple />
                      </div>
                      <a
                        href="javascript:void(0);"
                        class="btn btn-md btn-outline-primary mb-0"
                        >Remove</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">First Name</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Last Name</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Email Address</label>
                  <input type="email" class="form-control" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Phone Number</label>
                  <input type="email" class="form-control" />
                </div>
              </div>
              <div class="col-md-12">
                <label class="form-label">Country</label>
                <vue-select :options="CountGerm" id="countgerm" placeholder="Select" />
              </div>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <div class="d-flex w-100 justify-content-between">
              <a
                href="#"
                class="btn btn-outline-primary me-2 d-flex justify-content-center w-100"
                data-bs-dismiss="modal"
                >Cancel</a
              >
              <button
                type="submit"
                class="btn btn-primary d-flex justify-content-center w-100"
              >
                Add User
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Add user -->

  <!-- Edit user -->
  <div class="modal fade" id="edit_user">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Edit User</h4>
          <button
            type="button"
            class="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ti ti-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex align-items-center row-gap-2 mb-3 upload-frames">
                  <div class="outer-frames">
                    <a href="#">
                      <div class="frames d-flex justify-content-center align-items-end">
                        <img
                          src="@/assets/admin/img/users/user-01.jpg"
                          alt="image"
                          class="img-fluid avatar avatar-xl rounded-circle"
                        />
                      </div>
                      <span class="frame-add rounded-circle"
                        ><i class="ti ti-plus"></i
                      ></span>
                    </a>
                  </div>
                  <div class="profile-upload">
                    <div class="profile-content">
                      <p class="fs-14">Upload Image</p>
                      <span>Image should be below 4 mb</span>
                    </div>
                    <div class="profile-uploader d-flex align-items-center">
                      <div class="drag-upload-btn mb-0">
                        Upload
                        <input type="file" class="form-control image-sign" multiple="" />
                      </div>
                      <a
                        href="javascript:void(0);"
                        class="btn btn-md btn-outline-primary mb-0"
                        >Remove</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">First Name</label>
                  <input type="text" class="form-control" value="Aaryian " />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Last Name</label>
                  <input type="text" class="form-control" value="Jose" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Email Address</label>
                  <input type="email" class="form-control" value="aaryian@example.com" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Phone Number</label>
                  <input type="email" class="form-control" value="514-245-98315" />
                </div>
              </div>
              <div class="col-md-12">
                <label class="form-label">Country</label>
                <vue-select
                  :options="CountGermEdit"
                  id="countgermedit"
                  placeholder="United States"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <div class="d-flex w-100 justify-content-between">
              <a
                href="#"
                class="btn btn-outline-primary me-2 d-flex justify-content-center w-100"
                data-bs-dismiss="modal"
                >Cancel</a
              >
              <button
                type="submit"
                class="btn btn-primary d-flex justify-content-center w-100"
              >
                Save Changes
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Edit user -->

  <!-- Delete Modal -->
  <div class="modal fade" id="delete-modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="submitForm">
          <div class="modal-body text-center">
            <span class="delete-icon">
              <i class="ti ti-trash-x"></i>
            </span>
            <h4>Confirm Deletion</h4>
            <p>
              You want to delete all the marked items, this cant be undone once you
              delete.
            </p>
            <div class="d-flex justify-content-center">
              <a
                href="javascript:void(0);"
                class="btn btn-light me-3"
                data-bs-dismiss="modal"
                >Cancel</a
              >
              <button type="submit" class="btn btn-danger">Yes, Delete</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Delete Modal -->
</template>
