<template>
  <div class="container-fluid">
    <div class="login-wrapper">
      <header class="logo-header">
        <router-link to="index" class="logo-brand">
          <img
            src="@/assets/admin/img/full-logo.svg"
            alt="Logo"
            class="img-fluid logo-dark"
          />
        </router-link>
      </header>
      <div class="login-inbox">
        <div class="log-auth">
          <div class="success-pass d-flex align-items-center justify-content-center mb-2">
            <img src="@/assets/admin/img/success.png" alt="Success" class="img-fluid" />
          </div>
          <div class="login-auth-wrap">
            <div class="login-content-head">
              <h3>Reset Password Success</h3>
              <p class="text-center">
                Your new password has been successfully saved.<br />
                Now you can login with your new password
              </p>
            </div>
          </div>
          <router-link
            to="/admin-template/"
            class="btn btn-primary w-100 btn-size justify-content-center"
            >Login</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
