<script>
import { ref } from "vue";

export default {
  setup() {
    const activeTab = ref("group-menu"); // Default to 'chat-menu'

    // Method to set the active tab
    const setActiveTab = (tabId) => {
      activeTab.value = tabId;
    };

    return {
      activeTab,
      setActiveTab,
    };
  },
  mounted() {
    document.querySelectorAll(".chat-user-list").forEach(function (element) {
      element.addEventListener("click", function () {
        if (window.innerWidth <= 992) {
          const showChat = document.querySelector(".chat-messages");
          if (showChat) {
            showChat.classList.add("show");
          }
        }
      });
    });
    document.querySelectorAll(".chat-close").forEach(function (element) {
      element.addEventListener("click", function () {
        if (window.innerWidth <= 992) {
          const hideChat = document.querySelector(".chat-messages");
          if (hideChat) {
            hideChat.classList.remove("show");
          }
        }
      });
    });
  },
};
</script>

<template>
  <div class="content main_content">
    <!-- Left Sidebar Menu -->
    <div class="sidebar-menu">
      <div class="logo">
        <router-link to="index" class="logo-normal"
          ><img src="@/assets/img/logo.svg" alt="Logo"
        /></router-link>
      </div>
      <div class="menu-wrap">
        <div class="main-menu">
          <ul class="nav">
            <a-tooltip placement="right">
              <template #title>Chats</template>
              <li>
                <router-link
                  to="index"
                  @click.prevent="setActiveTab('chat-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#chat-menu"
                >
                  <i class="ti ti-message-2-heart"></i>
                </router-link>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Contacts</template>
              <li>
                <a
                  href="#"
                  @click.prevent="setActiveTab('contact-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#contact-menu"
                >
                  <i class="ti ti-user-shield"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Groups</template>
              <li>
                <a
                  href="#"
                  class="active"
                  @click.prevent="setActiveTab('group-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#group-menu"
                >
                  <i class="ti ti-users-group"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Status</template>
              <li>
                <router-link
                  to="status"
                  @click.prevent="setActiveTab('status-menu')"
                >
                  <i class="ti ti-circle-dot"></i>
                </router-link>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Calls</template>
              <li>
                <a
                  href="#"
                  @click.prevent="setActiveTab('call-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#call-menu"
                >
                  <i class="ti ti-phone-call"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Profile</template>
              <li>
                <a
                  href="#"
                  @click.prevent="setActiveTab('profile-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-menu"
                >
                  <i class="ti ti-user-circle"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Settings</template>
              <li>
                <a
                  href="#"
                  @click.prevent="setActiveTab('setting-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#setting-menu"
                >
                  <i class="ti ti-settings"></i>
                </a>
              </li>
            </a-tooltip>
          </ul>
        </div>
        <profile-menu></profile-menu>
      </div>
    </div>
    <!-- /Left Sidebar Menu -->

    <!-- sidebar group -->
    <div class="sidebar-group">
      <div class="tab-content">
        <div
          id="chat-menu"
          :class="[
            'tab-pane',
            'fade',
            activeTab === 'chat-menu' ? 'active show' : '',
          ]"
        >
          <!-- Chats sidebar -->
          <index-sidebar></index-sidebar>
          <!-- / Chats sidebar -->
        </div>

        <!-- Contact -->
        <div
          id="contact-menu"
          :class="[
            'tab-pane',
            'fade',
            activeTab === 'contact-menu' ? 'active show' : '',
          ]"
        >
          <!-- Chats sidebar -->
          <chat-sidebar></chat-sidebar>
          <!-- / Chats sidebar -->
        </div>
        <!-- /Contact -->

        <!-- Group -->
        <div
          :class="[
            'tab-pane',
            'fade',
            activeTab === 'group-menu' ? 'active show' : '',
          ]"
          id="group-menu"
        >
          <!-- Chats sidebar -->
          <group-sidebar></group-sidebar>
          <!-- / Chats sidebar -->
        </div>
        <!-- /Group -->

        <!-- Profile -->
        <div
          :class="[
            'tab-pane',
            'fade',
            activeTab === 'profile-menu' ? 'active show' : '',
          ]"
          id="profile-menu"
        >
          <!-- Profile sidebar -->
          <profile-sidebar></profile-sidebar>
          <!-- / Profile sidebar -->
        </div>
        <!-- /Profile -->

        <!-- Calls -->
        <div
          :class="[
            'tab-pane',
            'fade',
            activeTab === 'call-menu' ? 'active show' : '',
          ]"
          id="call-menu"
        >
          <!-- Call sidebar -->
          <calls-sidebar></calls-sidebar>
          <!-- / Call sidebar -->
        </div>
        <!-- /Calls -->

        <!-- Settings -->
        <div
          :class="[
            'tab-pane',
            'fade',
            activeTab === 'setting-menu' ? 'active show' : '',
          ]"
          id="setting-menu"
        >
          <!-- Profile sidebar -->
          <settings-sidebar></settings-sidebar>
          <!-- / Chats sidebar -->
        </div>
        <!-- /Settings -->
      </div>
    </div>
    <!-- /Sidebar group -->

    <!-- Chat -->
    <group-content></group-content>
    <!-- /Chat -->

    <!-- Group Info -->
    <group-info></group-info>
    <!-- /Group Info -->

    <!-- Favourites Info -->
    <div
      class="chat-offcanvas fav-canvas offcanvas offcanvas-end"
      data-bs-scroll="true"
      data-bs-backdrop="false"
      tabindex="-1"
      id="contact-favourite"
    >
      <div class="offcanvas-header">
        <h4 class="offcanvas-title">
          <a
            href="javascript:void(0);"
            data-bs-toggle="offcanvas"
            data-bs-target="#contact-profile"
            data-bs-dismiss="offcanvas"
            ><i class="ti ti-arrow-left me-2"></i></a
          >Favourites
        </h4>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <i class="ti ti-x"></i>
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="favourite-chats">
          <div class="text-end mb-4">
            <a href="javascript:void(0);" class="btn btn-light"
              ><i class="ti ti-heart-minus me-2"></i>Mark all Unfavourite</a
            >
          </div>
          <div class="chats">
            <div class="chat-avatar">
              <img
                src="@/assets/img/profiles/avatar-06.jpg"
                class="rounded-circle"
                alt="image"
              />
            </div>
            <div class="chat-content">
              <div class="chat-profile-name">
                <h6>
                  Edward Lietz<i class="ti ti-circle-filled fs-7 mx-2"></i
                  ><span class="chat-time">02:39 PM</span
                  ><span class="msg-read success"
                    ><i class="ti ti-checks"></i
                  ></span>
                </h6>
              </div>
              <div class="chat-info">
                <div class="message-content">
                  Thanks!!!, I ll Update you Once i check the Examples
                </div>
                <div class="chat-actions">
                  <a class="#" href="#" data-bs-toggle="dropdown">
                    <i class="ti ti-dots-vertical"></i>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-end p-3">
                    <li>
                      <a class="dropdown-item" href="#"
                        ><i class="ti ti-heart me-2"></i>Unfavourite</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="#"
                        ><i class="ti ti-trash me-2"></i>Delete</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <p>Saved on 23 Septemer 2024</p>
            </div>
          </div>
          <div class="chats">
            <div class="chat-avatar">
              <img
                src="@/assets/img/profiles/avatar-04.jpg"
                class="rounded-circle"
                alt="image"
              />
            </div>
            <div class="chat-content">
              <div class="chat-profile-name">
                <h6>
                  Carla Jenkins<i class="ti ti-circle-filled fs-7 mx-2"></i
                  ><span class="chat-time">02:45 PM</span
                  ><span class="msg-read success"
                    ><i class="ti ti-checks"></i
                  ></span>
                </h6>
              </div>
              <div class="chat-info">
                <div class="message-content bg-transparent p-0">
                  <div class="message-audio">
                    <audio controls>
                      <source
                        src="@/assets/img/audio/audio.mp3"
                        type="audio/mpeg"
                      />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                </div>
                <div class="chat-actions">
                  <a class="#" href="#" data-bs-toggle="dropdown">
                    <i class="ti ti-dots-vertical"></i>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-end p-3">
                    <li>
                      <a class="dropdown-item" href="#"
                        ><i class="ti ti-heart me-2"></i>Unfavourite</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="#"
                        ><i class="ti ti-trash me-2"></i>Delete</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <p>Saved on 26 Septemer 2024</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Favourites Info -->

    <!-- Message Info -->
    <div
      class="chat-offcanvas fav-canvas offcanvas offcanvas-end"
      data-bs-scroll="true"
      data-bs-backdrop="false"
      tabindex="-1"
      id="contact-message"
    >
      <div class="offcanvas-header">
        <h4 class="offcanvas-title">
          <a
            href="javascript:void(0);"
            data-bs-toggle="offcanvas"
            data-bs-dismiss="offcanvas"
            ><i class="ti ti-arrow-left me-2"></i></a
          >Message Info
        </h4>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <i class="ti ti-x"></i>
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="info-chats">
          <div class="text-end mb-4">
            <a href="javascript:void(0);" class="btn btn-light"
              ><i class="ti ti-heart-minus me-2"></i>Mark all Unfavourite</a
            >
          </div>
          <div class="chats chats-right">
            <div class="chat-content">
              <div class="chat-info">
                <div class="message-content">
                  Hey Edward! Doing well. Just finished up a client meeting.
                  How's everyone else?
                </div>
              </div>
            </div>
            <div class="chat-avatar">
              <img
                src="@/assets/img/profiles/avatar-17.jpg"
                class="rounded-circle dreams_chat"
                alt="image"
              />
            </div>
          </div>
          <h6 class="mb-4">
            <i class="ti ti-checks text-success me-2"></i>Read By
          </h6>
          <div class="d-flex align-items-center mb-4">
            <span class="avatar avatar-lg online">
              <img
                src="@/assets/img/profiles/avatar-06.jpg"
                alt="img"
                class="rounded-circle"
              />
            </span>
            <div class="ms-2 overflow-hidden">
              <h6 class="text-truncate fw-normal mb-1">Edward Lietz</h6>
              <p>02:40 PM</p>
            </div>
          </div>
          <div class="d-flex align-items-center mb-4">
            <span class="avatar avatar-lg online">
              <img
                src="@/assets/img/profiles/avatar-01.jpg"
                alt="img"
                class="rounded-circle"
              />
            </span>
            <div class="ms-2 overflow-hidden">
              <h6 class="text-truncate fw-normal mb-1">Aaryian Jose</h6>
              <p>02:40 PM</p>
            </div>
          </div>
          <div class="d-flex align-items-center mb-4">
            <span class="avatar avatar-lg online">
              <img
                src="@/assets/img/profiles/avatar-02.jpg"
                alt="img"
                class="rounded-circle"
              />
            </span>
            <div class="ms-2 overflow-hidden">
              <h6 class="text-truncate fw-normal mb-1">Sarika Jain</h6>
              <p>02:40 PM</p>
            </div>
          </div>
          <div class="d-flex align-items-center mb-0">
            <span class="avatar avatar-lg online">
              <img
                src="@/assets/img/profiles/avatar-03.jpg"
                alt="img"
                class="rounded-circle"
              />
            </span>
            <div class="ms-2 overflow-hidden">
              <h6 class="text-truncate fw-normal mb-1">Clyde Smith</h6>
              <p>02:40 PM</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Message Info -->
    <modal-index></modal-index>
  </div>
</template>
