<script>
export default {
  data() {
    return {
      title: "Video/Audio Settings",
      text: "App Settings",
      text1: "Video/Audio Settings",
      VideoAudioSet: ["Agora"],
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- App Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-xl-3 col-md-4">
              <div class="card mb-3 mb-md-0">
                <app-sidebar></app-sidebar>
              </div>
            </div>
            <!-- Video Audio Settings -->
            <div class="col-xl-9 col-md-8">
              <div class="card setting-content mb-0">
                <div class="card-header px-0 mx-3">
                  <h4>Video/Audio Settings</h4>
                </div>
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="row row-gap-2 mb-3 py-2">
                        <div class="col-md-6">
                          <h6 class="fw-medium">Enable Video Calling</h6>
                        </div>
                        <div class="col-md-6">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row row-gap-2 mb-3 py-2">
                        <div class="col-md-6">
                          <h6 class="fw-medium">Enable Audio Calling</h6>
                        </div>
                        <div class="col-md-6">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row row-gap-2 mb-3">
                        <div class="col-md-6 d-flex">
                          <div class="d-flex align-items-center">
                            <h6 class="fw-medium">Video Calling Provider</h6>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <vue-select
                            :options="VideoAudioSet"
                            id="videoaudioset"
                            placeholder="Agora"
                          />
                        </div>
                      </div>
                      <div class="row row-gap-2 mb-3">
                        <div class="col-md-6 d-flex">
                          <div class="d-flex align-items-center">
                            <h6 class="fw-medium">Maximum Call Length in Minutes</h6>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Video Audio Settings -->
          </div>
        </div>
      </div>
      <!-- /App Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
