<template>
  <div class="card mb-3 mb-md-0">
    <div class="card-body setting-sidebar">
      <div class="d-flex">
        <router-link to="storage" class="rounded flex-fill"
          ><i class="ti ti-server-cog me-2"></i>Storage</router-link
        >
      </div>
      <div class="d-flex">
        <router-link to="backup" class="rounded flex-fill"
          ><i class="ti ti-arrow-back-up me-2"></i>Backup & Restore</router-link
        >
      </div>
      <div class="d-flex">
        <router-link to="clear-cache" class="rounded flex-fill"
          ><i class="ti ti-clear-all me-2"></i>Clear Cache</router-link
        >
      </div>
      <div class="d-flex">
        <router-link to="ban-address" class="rounded flex-fill"
          ><i class="ti ti-ban me-2"></i>Ban IP Address</router-link
        >
      </div>
    </div>
  </div>
</template>
