<script>
import { ref } from 'vue'

export default {
    setup() {
        const activeTab = ref('chat-menu') // Default to 'chat-menu'

        // Method to set the active tab
        const setActiveTab = (tabId) => {
            activeTab.value = tabId
        }

        return {
            activeTab,
            setActiveTab
        }
    }
}
</script>

<template>
    <div class="sidebar-menu">
        <div class="logo">
            <router-link to="index" class="logo-normal"><img src="@/assets/img/logo.svg" alt="Logo"></router-link>
        </div>
        <div class="menu-wrap">
            <div class="main-menu">
                <ul class="nav">
                    <a-tooltip placement="right">
                        <template #title>Chats</template>
                        <li>
                            <router-link to="index" @click.prevent="setActiveTab('chat-menu')" data-bs-toggle="tab" data-bs-target="#chat-menu">
                                <i class="ti ti-message-2-heart"></i>
                            </router-link>
                        </li>
                    </a-tooltip>
                    <a-tooltip placement="right">
                        <template #title>Contacts</template>
                        <li> 
                            <a href="#" @click.prevent="setActiveTab('contact-menu')" data-bs-toggle="tab" data-bs-target="#contact-menu">
                                <i class="ti ti-user-shield"></i>
                            </a>
                        </li>
                    </a-tooltip>
                    <a-tooltip placement="right">
                        <template #title>Groups</template>
                        <li>
                            <a href="#" @click.prevent="setActiveTab('group-menu')" data-bs-toggle="tab" data-bs-target="#group-menu">
                                <i class="ti ti-users-group"></i>
                            </a>
                        </li>
                    </a-tooltip>
                    <a-tooltip placement="right">
                        <template #title>Status</template>
                        <li>
                            <router-link to="status" @click.prevent="setActiveTab('status-menu')">
                                <i class="ti ti-circle-dot"></i>
                            </router-link>
                        </li>
                    </a-tooltip>
                    <a-tooltip placement="right">
                        <template #title>Calls</template>
                        <li>
                            <a href="#" @click.prevent="setActiveTab('call-menu')" data-bs-toggle="tab" data-bs-target="#call-menu">
                                <i class="ti ti-phone-call"></i>
                            </a>
                        </li>
                    </a-tooltip>
                    <a-tooltip placement="right">
                        <template #title>Profile</template>
                        <li>
                            <a href="#" @click.prevent="setActiveTab('profile-menu')" data-bs-toggle="tab" data-bs-target="#profile-menu">
                                <i class="ti ti-user-circle"></i>
                            </a>
                        </li>
                    </a-tooltip>
                    <a-tooltip placement="right">
                        <template #title>Settings</template>
                        <li>
                            <a href="#" @click.prevent="setActiveTab('setting-menu')" data-bs-toggle="tab" data-bs-target="#setting-menu">
                                <i class="ti ti-settings"></i>
                            </a>
                        </li>
                    </a-tooltip>
                </ul>
            </div>
            <profile-menu></profile-menu>
        </div>
    </div>
</template>

