<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
export default {
  data() {
    return {
        selectedChat: 'All Chats',
        settings: {
            suppressScrollX: true,
        },
    };
  },
  methods: {
    scrollHanle() {},
    changeChat(chat) {
      this.selectedChat = chat;
    },
    submitForm() {
      this.$router.push("/chat");
    },
  },
  components: {
    PerfectScrollbar,
  },
};
</script>

<template>
    <div id="chats" class="sidebar-content active slimscroll">
        <perfect-scrollbar
            class="scroll-area"
            :settings="settings"
            @ps-scroll-y="scrollHanle"
        >
            <div class="slimscroll">

                <div class="chat-search-header">                            
                    <div class="header-title d-flex align-items-center justify-content-between">
                        <h4 class="mb-3">Chats</h4>
                        <div class="d-flex align-items-center mb-3">
                            <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#new-chat" class="add-icon btn btn-primary p-0 d-flex align-items-center justify-content-center fs-16 me-2"><i class="ti ti-plus"></i></a>
                            <div class="dropdown">
                                <a href="javascript:void(0);" data-bs-toggle="dropdown" class="fs-16 text-default">
                                    <i class="ti ti-dots-vertical"></i>
                                </a>
                                <ul class="dropdown-menu p-3">
                                    <li><a class="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#invite"><i class="ti ti-send me-2"></i>Invite Others</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- Chat Search -->
                    <div class="search-wrap">
                        <form @submit.prevent="submitForm">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Search For Contacts or Messages">
                                <span class="input-group-text"><i class="ti ti-search"></i></span>
                            </div>
                        </form>
                    </div>
                    <!-- /Chat Search --> 
                </div>       

                <!-- Online Contacts -->
                <div class="top-online-contacts">
                    <div class="d-flex align-items-center justify-content-between">
                        <h5 class="mb-3">Recent Chats</h5>
                        <div class="dropdown mb-3">
                            <a href="#" class="text-default" data-bs-toggle="dropdown" aria-expanded="false"><i class="ti ti-dots-vertical"></i></a>
                            <ul class="dropdown-menu dropdown-menu-end p-3">
                                <li><a class="dropdown-item mb-1" href="#"><i class="ti ti-eye-off me-2"></i>Hide Recent</a></li>
                                <li><a class="dropdown-item" href="#"><i class="feather-users me-2"></i>Active Contacts</a></li>
                            </ul>
                        </div>
                    </div>
                    <index-swiper></index-swiper>
                </div>
                <!-- /Online Contacts -->

                <div class="sidebar-body chat-body" id="chatsidebar">
                
                    <!-- Left Chat Title -->
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <h5 class="chat-title">{{selectedChat}}</h5>
                        <div class="dropdown">
                            <a href="#" class="text-default fs-16" data-bs-toggle="dropdown" aria-expanded="false"><i class="ti ti-filter"></i></a>
                            <ul class="dropdown-menu dropdown-menu-end p-3" id="innerTab" role="tablist">
                                <li role="presentation">
                                    <a class="dropdown-item" :class="{ active: selectedChat === 'All Chats' }" href="#"
                                    @click="changeChat('All Chats')">All Chats</a>
                                </li>
                                <li role="presentation">
                                    <a class="dropdown-item" :class="{ active: selectedChat === 'Favourite Chats' }" href="#"
                                    @click="changeChat('Favourite Chats')">Favourite Chats</a>
                                </li>
                                <li role="presentation">
                                    <a class="dropdown-item" :class="{ active: selectedChat === 'Pinned Chats' }" href="#"
                                    @click="changeChat('Pinned Chats')">Pinned Chats</a>
                                </li>
                                <li role="presentation">
                                    <a class="dropdown-item" :class="{ active: selectedChat === 'Archive Chats' }" href="#"
                                    @click="changeChat('Archive Chats')">Archive Chats</a>
                                </li>
                                <li role="presentation">
                                    <a class="dropdown-item" :class="{ active: selectedChat === 'Trash' }" href="#"
                                    @click="changeChat('Trash')">Trash</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- /Left Chat Title -->
                    
                    <div class="tab-content" id="innerTabContent">
                        <div v-if="selectedChat === 'All Chats'" class="tab-pane fade" :class="['tab-pane', 'fade', { 'show active': selectedChat === 'All Chats' }]">
                            <all-chat-sidebar></all-chat-sidebar>
                        </div>
                        <div v-if="selectedChat === 'Favourite Chats'" class="tab-pane fade" :class="['tab-pane', 'fade', { 'show active': selectedChat === 'Favourite Chats' }]">
                            <favourite-chats-sidebar></favourite-chats-sidebar>
                        </div>
                        <div v-if="selectedChat === 'Pinned Chats'" class="tab-pane fade" :class="['tab-pane', 'fade', { 'show active': selectedChat === 'Pinned Chats' }]">
                            <pinned-chats-sidebar></pinned-chats-sidebar>
                        </div>
                        <div v-if="selectedChat === 'Archive Chats'" class="tab-pane fade" :class="['tab-pane', 'fade', { 'show active': selectedChat === 'Archive Chats' }]">
                            <archive-chats-sidebar></archive-chats-sidebar>
                        </div>
                        <div v-if="selectedChat === 'Trash'" class="tab-pane fade" :class="['tab-pane', 'fade', { 'show active': selectedChat === 'Trash' }]">
                            <trash-sidebar></trash-sidebar>
                        </div>
                    </div>                
                    
                </div>

            </div>
        </perfect-scrollbar>
    </div>
</template>