<template>
  <!-- Group Info -->
  <div
    class="chat-offcanvas offcanvas offcanvas-end"
    data-bs-scroll="true"
    data-bs-backdrop="false"
    tabindex="-1"
    id="contact-profile"
  >
    <div class="offcanvas-header">
      <h4 class="offcanvas-title">Group Info</h4>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      >
        <i class="ti ti-x"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="chat-contact-info">
        <div class="profile-content">
          <div class="contact-profile-info">
            <div class="avatar avatar-xxl online mb-2">
              <img
                src="@/assets/img/profiles/avatar-06.jpg"
                class="rounded-circle"
                alt="img"
              />
            </div>
            <h6>The Dream Team</h6>
            <p>Group - 40 Participants</p>
          </div>
          <div class="row gx-3">
            <div class="col">
              <a
                class="action-wrap"
                href="javascript:void(0);"
                data-bs-toggle="modal"
                data-bs-target="#group_voice"
              >
                <i class="ti ti-phone"></i>
                <p>Audio</p>
              </a>
            </div>
            <div class="col">
              <a
                class="action-wrap"
                href="javascript:void(0);"
                data-bs-toggle="modal"
                data-bs-target="#group_video"
              >
                <i class="ti ti-video"></i>
                <p>Video</p>
              </a>
            </div>
            <div class="col">
              <a class="action-wrap" href="javascript:void(0);">
                <i class="ti ti-brand-hipchat"></i>
                <p>Chat</p>
              </a>
            </div>
            <div class="col">
              <a class="action-wrap" href="javascript:void(0);">
                <i class="ti ti-search"></i>
                <p>Search</p>
              </a>
            </div>
          </div>
          <div class="content-wrapper">
            <h5 class="sub-title">Profile Info</h5>
            <div class="card">
              <div class="card-body">
                <div
                  class="d-flex profile-info-content justify-content-between align-items-center border-bottom pb-3 mb-3"
                >
                  <div>
                    <h6 class="fs-14">Group Description</h6>
                    <p class="fs-16">Innovate. Create. Inspire.</p>
                  </div>
                  <a href="javascript:void(0);" class="link-icon"
                    ><i class="ti ti-edit-circle"></i
                  ></a>
                </div>
                <p class="fs-12">
                  Group created by Edward Lietz, on 18 Feb 2022 at 1:32 pm
                </p>
              </div>
            </div>
          </div>
          <div class="content-wrapper">
            <h5 class="sub-title">Social Profiles</h5>
            <div class="card">
              <div class="card-body">
                <div class="social-icon">
                  <a href="javascript:void(0);"><i class="ti ti-brand-facebook"></i></a>
                  <a href="javascript:void(0);"><i class="ti ti-brand-twitter"></i></a>
                  <a href="javascript:void(0);"><i class="ti ti-brand-instagram"></i></a>
                  <a href="javascript:void(0);"><i class="ti ti-brand-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="content-wrapper">
            <h5 class="sub-title">Media Details</h5>
            <div class="file-item action-wrap">
              <div class="accordion accordion-flush chat-accordion" id="mediafile">
                <group-photo></group-photo>
                <group-video></group-video>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <a
                      href="#"
                      class="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#media-links"
                      aria-expanded="false"
                      aria-controls="media-links"
                    >
                      <i class="ti ti-unlink me-2"></i>Links
                    </a>
                  </h2>
                  <div
                    id="media-links"
                    class="accordion-collapse collapse"
                    data-bs-parent="#mediafile"
                  >
                    <div class="accordion-body">
                      <div class="link-item">
                        <span class="link-icon">
                          <img src="@/assets/img/icons/github-icon.svg" alt="icon" />
                        </span>
                        <div class="ms-2">
                          <p>https://segmentfault.com/u/ans</p>
                        </div>
                      </div>
                      <div class="link-item">
                        <span class="link-icon">
                          <img src="@/assets/img/icons/info-icon.svg" alt="icon" />
                        </span>
                        <div class="ms-2">
                          <p>https://segmentfault.com/u/ans</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <a
                      href="#"
                      class="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#media-document"
                      aria-expanded="false"
                      aria-controls="media-document"
                    >
                      <i class="ti ti-unlink me-2"></i>Documents
                    </a>
                  </h2>
                  <div
                    id="media-document"
                    class="accordion-collapse collapse"
                    data-bs-parent="#mediafile"
                  >
                    <div class="accordion-body">
                      <div class="document-item">
                        <div class="d-flex align-items-center">
                          <span class="document-icon">
                            <i class="ti ti-file-zip"></i>
                          </span>
                          <div class="ms-2">
                            <h6>Ecommerce.zip</h6>
                            <p>10.25 MB zip file</p>
                          </div>
                        </div>
                        <a href="javascript:void(0);" class="download-icon">
                          <i class="ti ti-download"></i>
                        </a>
                      </div>
                      <div class="document-item">
                        <div class="d-flex align-items-center">
                          <span class="document-icon">
                            <i class="ti ti-video"></i>
                          </span>
                          <div class="ms-2">
                            <h6>video-1.mp4</h6>
                            <p>20.50 MB video file</p>
                          </div>
                        </div>
                        <a href="javascript:void(0);" class="download-icon">
                          <i class="ti ti-download"></i>
                        </a>
                      </div>
                      <div class="document-item">
                        <div class="d-flex align-items-center">
                          <span class="document-icon">
                            <i class="ti ti-music"></i>
                          </span>
                          <div class="ms-2">
                            <h6>Ecommerce.zip</h6>
                            <p>6.25 MB audio file</p>
                          </div>
                        </div>
                        <a href="javascript:void(0);" class="download-icon">
                          <i class="ti ti-download"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-wrapper other-info mb-0">
            <h5 class="sub-title">Others</h5>
            <div class="card">
              <div class="card-body list-group profile-item">
                <a
                  href="javascript:void(0);"
                  class="list-group-item"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#contact-favourite"
                >
                  <div class="profile-info">
                    <h6><i class="ti ti-graph me-2 text-default"></i>Favorites</h6>
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="badge badge-danger count-message me-1">12</span>
                    <span class="link-icon"><i class="ti ti-chevron-right"></i></span>
                  </div>
                </a>
                <a
                  href="javascript:void(0);"
                  class="list-group-item"
                  data-bs-toggle="modal"
                  data-bs-target="#mute-notification"
                >
                  <div class="profile-info">
                    <h6>
                      <i class="ti ti-volume-off me-2 text-warning"></i>Mute Notifications
                    </h6>
                  </div>
                  <div>
                    <span class="link-icon"><i class="ti ti-chevron-right"></i></span>
                  </div>
                </a>
                <a
                  href="javascript:void(0);"
                  class="list-group-item"
                  data-bs-toggle="modal"
                  data-bs-target="#msg-disapper"
                >
                  <div class="profile-info">
                    <h6>
                      <i class="ti ti-user-off me-2 text-info"></i>Disappearing Messages
                    </h6>
                  </div>
                  <div>
                    <span class="link-icon"><i class="ti ti-chevron-right"></i></span>
                  </div>
                </a>
                <div class="accordion accordion-flush">
                  <div class="accordion-item border-bottom">
                    <h2 class="accordion-header">
                      <a
                        href="#"
                        class="accordion-button px-0 collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#chatuser-encryption"
                        aria-expanded="false"
                        aria-controls="chatuser-collapse1"
                      >
                        <i class="ti ti-shield me-2 text-purple"></i>Encryption
                      </a>
                    </h2>
                    <div id="chatuser-encryption" class="accordion-collapse collapse">
                      <div class="accordion-body p-0 pb-3">
                        <p class="mb-2">Messages are end-to-end encrypted</p>
                        <div class="text-center">
                          <a
                            class="view-all link-primary d-inline-flex align-items-center justify-content-center"
                            href="javascript:void(0);"
                          >
                            Click to learn more<i class="ti ti-arrow-right ms-2"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  href="javascript:void(0);"
                  class="list-group-item"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#group-settings"
                >
                  <div class="profile-info">
                    <h6>
                      <i class="ti ti-settings me-2 text-primary"></i>Group Settings
                    </h6>
                  </div>
                  <div>
                    <span class="link-icon"><i class="ti ti-chevron-right"></i></span>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="content-wrapper other-info">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="sub-title">40 Participants</h5>
              <a href="javascript:void(0);" class="link-icon"
                ><i class="ti ti-search"></i
              ></a>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="card mb-3">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center overflow-hidden">
                        <span class="avatar avatar-lg online flex-shrink-0">
                          <img
                            src="@/assets/img/profiles/avatar-02.jpg"
                            alt="img"
                            class="rounded-circle"
                          />
                        </span>
                        <div class="ms-2 overflow-hidden">
                          <h6 class="text-truncate mb-1">Sarika Jain</h6>
                          <p>Busy</p>
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <span class="badge badge-primary-transparent me-2">Admin</span>
                        <div class="dropdown">
                          <a href="#" data-bs-toggle="dropdown">
                            <i class="ti ti-dots-vertical"></i>
                          </a>
                          <ul class="dropdown-menu dropdown-menu-end p-3">
                            <li>
                              <a class="dropdown-item" href="#"
                                ><i class="ti ti-logout-2 me-2"></i>Exit Group</a
                              >
                            </li>
                            <li>
                              <a class="dropdown-item" href="#"
                                ><i class="ti ti-trash me-2"></i>Delete</a
                              >
                            </li>
                            <li>
                              <a class="dropdown-item" href="#"
                                ><i class="ti ti-pinned me-2"></i>Unpin Chat</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-3">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center overflow-hidden">
                        <span class="avatar avatar-lg online flex-shrink-0">
                          <img
                            src="@/assets/img/profiles/avatar-17.jpg"
                            alt="img"
                            class="rounded-circle"
                          />
                        </span>
                        <div class="ms-2 overflow-hidden">
                          <h6 class="text-truncate mb-1">Edward Lietz</h6>
                          <p>Available</p>
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <span class="badge badge-primary-transparent me-2">Admin</span>
                        <div class="dropdown">
                          <a href="#" data-bs-toggle="dropdown">
                            <i class="ti ti-dots-vertical"></i>
                          </a>
                          <ul class="dropdown-menu dropdown-menu-end p-3">
                            <li>
                              <a class="dropdown-item" href="#"
                                ><i class="ti ti-logout-2 me-2"></i>Exit Group</a
                              >
                            </li>
                            <li>
                              <a class="dropdown-item" href="#"
                                ><i class="ti ti-trash me-2"></i>Delete</a
                              >
                            </li>
                            <li>
                              <a class="dropdown-item" href="#"
                                ><i class="ti ti-pinned me-2"></i>Unpin Chat</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-3">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center overflow-hidden">
                        <span class="avatar avatar-lg online flex-shrink-0">
                          <img
                            src="@/assets/img/profiles/avatar-03.jpg"
                            alt="img"
                            class="rounded-circle"
                          />
                        </span>
                        <div class="ms-2 overflow-hidden">
                          <h6 class="text-truncate mb-1">Clyde Smith</h6>
                          <p>Available</p>
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="dropdown">
                          <a href="#" data-bs-toggle="dropdown">
                            <i class="ti ti-dots-vertical"></i>
                          </a>
                          <ul class="dropdown-menu dropdown-menu-end p-3">
                            <li>
                              <a class="dropdown-item" href="#"
                                ><i class="ti ti-logout-2 me-2"></i>Exit Group</a
                              >
                            </li>
                            <li>
                              <a class="dropdown-item" href="#"
                                ><i class="ti ti-trash me-2"></i>Delete</a
                              >
                            </li>
                            <li>
                              <a class="dropdown-item" href="#"
                                ><i class="ti ti-pinned me-2"></i>Unpin Chat</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-3">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center overflow-hidden">
                        <span class="avatar avatar-lg online flex-shrink-0">
                          <img
                            src="@/assets/img/profiles/avatar-05.jpg"
                            alt="img"
                            class="rounded-circle"
                          />
                        </span>
                        <div class="ms-2 overflow-hidden">
                          <h6 class="text-truncate mb-1">Federico Wells</h6>
                          <p>Available</p>
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="dropdown">
                          <a href="#" data-bs-toggle="dropdown">
                            <i class="ti ti-dots-vertical"></i>
                          </a>
                          <ul class="dropdown-menu dropdown-menu-end p-3">
                            <li>
                              <a class="dropdown-item" href="#"
                                ><i class="ti ti-logout-2 me-2"></i>Exit Group</a
                              >
                            </li>
                            <li>
                              <a class="dropdown-item" href="#"
                                ><i class="ti ti-trash me-2"></i>Delete</a
                              >
                            </li>
                            <li>
                              <a class="dropdown-item" href="#"
                                ><i class="ti ti-pinned me-2"></i>Unpin Chat</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <a
                    class="view-all link-primary d-inline-flex align-items-center justify-content-center"
                    href="javascript:void(0);"
                  >
                    View All(35 more)<i class="ti ti-arrow-right ms-2"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="content-wrapper other-info mb-0">
            <div class="card mb-0">
              <div class="card-body list-group profile-item">
                <a
                  href="javascript:void(0);"
                  class="list-group-item"
                  data-bs-toggle="modal"
                  data-bs-target="#group-logout"
                >
                  <div class="profile-info">
                    <h6><i class="ti ti-logout-2 me-2 text-danger"></i>Exit Group</h6>
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="link-icon"><i class="ti ti-chevron-right"></i></span>
                  </div>
                </a>
                <a
                  href="javascript:void(0);"
                  class="list-group-item"
                  data-bs-toggle="modal"
                  data-bs-target="#report-group"
                >
                  <div class="profile-info">
                    <h6><i class="ti ti-thumb-down me-2 text-danger"></i>Report User</h6>
                  </div>
                  <div>
                    <span class="link-icon"><i class="ti ti-chevron-right"></i></span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Group Info -->
</template>
