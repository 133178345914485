import { createApp } from 'vue';
import { router } from '@/router';
import App from "@/App.vue";
import {BootstrapVue3, BToastPlugin} from 'bootstrap-vue-3'
import VueEasyLightbox from "vue-easy-lightbox";
import DatePicker from 'vue3-datepicker';
import StarRating from 'vue-star-rating'
import VueSelect from 'vue3-select2-component';
import Antd from 'ant-design-vue';
import PrimeVue from 'primevue/config';
import VueApexCharts from "vue3-apexcharts";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VueFeather from 'vue-feather';
import FlagIcon from 'vue-flag-icon';




// *************** Layout Components ***************
import SideMenu from '@/views/frontend/layouts/side-menu.vue'
import IndexSidebar from '@/views/frontend/layouts/index-sidebar.vue'
import AllChatSidebar from '@/views/frontend/layouts/all-chat-sidebar.vue'
import FavouriteChatsSidebar from '@/views/frontend/layouts/favourite-chats-sidebar.vue'
import PinnedChatsSidebar from '@/views/frontend/layouts/pinned-chats-sidebar.vue'
import ArchiveChatsSidebar from '@/views/frontend/layouts/archive-chats-sidebar.vue'
import TrashSidebar from '@/views/frontend/layouts/trash-sidebar.vue'
import ChatSidebar from '@/views/frontend/layouts/chat-sidebar.vue'
import GroupSidebar from '@/views/frontend/layouts/group-sidebar.vue'
import ProfileSidebar from '@/views/frontend/layouts/profile-sidebar.vue'
import CallsSidebar from '@/views/frontend/layouts/calls-sidebar.vue'
import SettingsSidebar from '@/views/frontend/layouts/settings-sidebar.vue'
import StatusSidebar from '@/views/frontend/layouts/status-sidebar.vue'
import ProfileMenu from '@/views/frontend/layouts/profile-menu.vue'
import Chat_Photo from '@/views/frontend/layouts/chat-photo.vue'
import Chat_Video from '@/views/frontend/layouts/chat-video.vue'
import Group_Photo from '@/views/frontend/layouts/group-photo.vue'
import Group_Video from '@/views/frontend/layouts/group-video.vue'


// *************** Page Components ***************

import IndexSwiper from '@/views/frontend/pages/index/index-swiper.vue'
import Chat_Content from '@/views/frontend/pages/chat/chat-content.vue'
import Group_Content from '@/views/frontend/pages/group-chat/group-content.vue'
import Calls_Content from '@/views/frontend/pages/all-calls/calls-content.vue'
import Contact_info from '@/views/frontend/pages/chat/contact-info.vue'
import Group_info from '@/views/frontend/pages/group-chat/group-info.vue'
import Group_image from '@/views/frontend/pages/group-chat/group-image.vue'
import Fav_info from '@/views/frontend/pages/chat/fav-info.vue'
import ChatImage from '@/views/frontend/pages/chat/chat-image.vue'



// *************** Model Components *************** //
import ModalIndex from '@/components/modal/modal-index.vue'


// *************** Admin Layouts *************** //

import Admin_Header from '@/views/admin/layouts/admin-header.vue';
import Admin_Sidebar from '@/views/admin/layouts/admin-sidebar.vue';
import AdminSidebarMenu from '@/views/admin/layouts/sidebar-menu.vue'
import SideSettings from '@/views/admin/layouts/side-settings.vue';

// *************** Admin Model *************** //

import Admin_Breadcrumb from '@/components/admin/breadcrumb/admin-breadcrumb.vue';
import User_Breadcrumb from '@/components/admin/breadcrumb/user-breadcrumb.vue';
import AdminChatModal from '@/components/modal/chat-modal.vue'
import AdminGroupModal from '@/components/modal/admin-group-modal.vue';
import AbuseMessageModal from '@/components/modal/abuse-message-modal.vue'
import AdminStoriesModal from '@/components/modal/admin-stories-modal.vue';
import Social_Auth_Modal from '@/components/admin/modal/social-auth-modal.vue'
import Custom_Fields_Modal from '@/components/admin/modal/custom-fields-modal.vue'
import Integrations_Modal from '@/components/admin/modal/integrations-modal.vue'
import Email_Settings_Modal from '@/components/admin/modal/email-settings-modal.vue'
import Sms_Settings_Modal from '@/components/admin/modal/sms-settings-modal.vue'
import Storage_Settings_Modal from '@/components/admin/modal/storage-settings-modal.vue'
import Ban_Address_Modal from '@/components/admin/modal/ban-address-modal.vue'
import Report_Modal from '@/components/admin/modal/report-modal.vue'
import User_Modal from '@/components/admin/modal/user-modal.vue'

// *************** Admin Pages *************** //

import Dashboard_Top from '@/views/admin/pages/dashboard/dashboard-top.vue';
import User_Top from '@/views/admin/pages/user/user-top.vue';
import Dashboard_Table from '@/views/admin/pages/dashboard/dashboard-table.vue';
import User_Table from '@/views/admin/pages/user/user/user-table.vue';
import Block_Table from '@/views/admin/pages/user/block-user/block-table.vue';
import Report_Table from '@/views/admin/pages/user/report-user/report-table.vue';
import Invite_Table from '@/views/admin/pages/user/invite-user/invite-table.vue';
import Admin_Top from '@/views/admin/pages/admin-top.vue';
import Settings_Top from '@/views/admin/pages/settings/settings-top.vue'
import General_Sidebar from '@/views/admin/pages/settings/general-settings/general-sidebar.vue'
import App_Sidebar from '@/views/admin/pages/settings/app-settings/app-sidebar.vue'
import System_Sidebar from '@/views/admin/pages/settings/system-settings/system-sidebar.vue'
import Others_Sidebar from '@/views/admin/pages/settings/others-settings/others-sidebar.vue'

import AdminChatTable from '@/views/admin/pages/chat/chat-table.vue'
import AdminCallTable from '@/views/admin/pages/call/call-table.vue'
import AdminGroupTable from '@/views/admin/pages/group/group-table.vue'
import AbuseMessageTable from '@/views/admin/pages/abuse-message/abuse-message-table.vue';
import AdminStoriesTable from '@/views/admin/pages/stories/stories-table.vue'


import Inputsize from "@/views/admin/pages/uiinterface/forms/form-elements/formbasic/input-size.vue";
import Form_Validation_One from '@/views/admin/pages/uiinterface/forms/form-validation-one.vue'
import Carouselthree from "@/views/admin/pages/uiinterface/baseui/carousel/carousel-three.vue";
import Carouseltwo from "@/views/admin/pages/uiinterface/baseui/carousel/carousel-two.vue";
import Carouselone from "@/views/admin/pages/uiinterface/baseui/carousel/carousel-one.vue";
import LightBoxOne from "@/views/admin/pages/uiinterface/baseui/lightbox/lightbox-one.vue";
import LightBoxTwo from "@/views/admin/pages/uiinterface/baseui/lightbox/lightbox-two.vue";


import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'ant-design-vue/dist/reset.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'material-icons/css/material-icons.min.css';
import 'material-icons/css/material-icons.css';
import 'pe7-icon/dist/dist/pe-icon-7-stroke.css';
import 'typicons.font/src/font/typicons.css';
import 'weathericons/css/weather-icons.css'
import 'ionicons-npm/css/ionicons.css';
import "boxicons/css/boxicons.min.css";
import '@/assets/css/feather.css';
import '@/assets/admin/css/sticky.css'
import '@/assets/css/tabler-icons.css';





if(window.location.href.includes("/admin-template/")) {
    require('@/assets/admin/scss/main.scss');
  }
  else {
    require('@/assets/scss/main.scss');
}

const app = createApp(App)

// *************** Model Components ***************


// *************** Layout Components ***************

app.component('side-menu', SideMenu )
app.component('index-sidebar', IndexSidebar )
app.component('all-chat-sidebar', AllChatSidebar )
app.component('favourite-chats-sidebar', FavouriteChatsSidebar )
app.component('pinned-chats-sidebar', PinnedChatsSidebar )
app.component('archive-chats-sidebar', ArchiveChatsSidebar )
app.component('trash-sidebar', TrashSidebar )
app.component('chat-sidebar', ChatSidebar )
app.component('group-sidebar', GroupSidebar )
app.component('profile-sidebar', ProfileSidebar )
app.component('calls-sidebar', CallsSidebar )
app.component('settings-sidebar', SettingsSidebar )
app.component('status-sidebar', StatusSidebar )
app.component('profile-menu', ProfileMenu )
app.component('chat-photo', Chat_Photo )
app.component('chat-video', Chat_Video )
app.component('group-photo', Group_Photo )
app.component('group-video', Group_Video )

// *************** Page Components ***************

app.component('chat-content',Chat_Content)
app.component('group-content',Group_Content)
app.component('calls-content',Calls_Content)
app.component('contact-info',Contact_info)
app.component('group-info',Group_info)
app.component('group-image',Group_image)
app.component('fav-info',Fav_info)
app.component('chat-image',ChatImage)

app.component('modal-index', ModalIndex)
app.component('index-swiper', IndexSwiper )

// *************** Admin Layouts *************** //
app.component('admin-sidebar',Admin_Sidebar)
app.component('admin-header',Admin_Header)
app.component('admin-sidebar-manu',AdminSidebarMenu)
app.component('side-settings', SideSettings)

// *************** Admin Modal *************** //
app.component('admin-breadcrumb',Admin_Breadcrumb)
app.component('user-breadcrumb',User_Breadcrumb)
app.component('admin-chat-modal', AdminChatModal )
app.component('admin-group-modal', AdminGroupModal)
app.component('abuse-message-modal', AbuseMessageModal)
app.component('admin-stories-modal', AdminStoriesModal)
app.component('social-auth-modal',Social_Auth_Modal)
app.component('custom-fields-modal',Custom_Fields_Modal)
app.component('integrations-modal',Integrations_Modal)
app.component('email-settings-modal',Email_Settings_Modal)
app.component('sms-settings-modal',Sms_Settings_Modal)
app.component('storage-settings-modal',Storage_Settings_Modal)
app.component('ban-address-modal',Ban_Address_Modal)
app.component('report-modal',Report_Modal)
app.component('user-modal',User_Modal)

// *************** Admin Pages *************** //
app.component('dashboard-top',Dashboard_Top)
app.component('dashboard-table',Dashboard_Table)
app.component('user-table',User_Table)
app.component('block-table',Block_Table)
app.component('report-table',Report_Table)
app.component('invite-table',Invite_Table)
app.component('admin-top',Admin_Top)
app.component('settings-top',Settings_Top)
app.component('general-sidebar',General_Sidebar)
app.component('app-sidebar',App_Sidebar)
app.component('system-sidebar',System_Sidebar)
app.component('others-sidebar',Others_Sidebar)
app.component('user-top',User_Top)
app.component('admin-chat-table', AdminChatTable)
app.component('admin-call-table', AdminCallTable)
app.component('admin-group-table', AdminGroupTable)
app.component('abuse-message-table', AbuseMessageTable)
app.component('admin-stories-table', AdminStoriesTable)


app.component("input-size", Inputsize);
app.component('form-validation-one',Form_Validation_One)
app.component("carousel-one", Carouselone);
app.component("carousel-two", Carouseltwo);
app.component("carousel-three", Carouselthree);
app.component("lightbox-one", LightBoxOne);
app.component("lightbox-two", LightBoxTwo);



app.component('vue-select', VueSelect)
app.component('date-picker', DatePicker)
app.component('star-rating', StarRating)
app.component(VueFeather.name, VueFeather)
app.use(FlagIcon)

.use(Antd)
.use(BootstrapVue3)
.use(BToastPlugin)
app.use(PrimeVue)
app.use(CKEditor)
app.use(VueApexCharts);
app.use(VueEasyLightbox);
app.use(router).mount('#app');

