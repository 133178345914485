<script>
export default {
  data() {
    return {
      title: "Users",
      text: "Users",
      text1: "Users",
      Name: "Users List",
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- User List -->
      <div class="card">
        <user-top :Name="Name"></user-top>

        <!-- User List -->
        <user-table></user-table>
      </div>
      <!-- /User List -->
    </div>
  </div>
  <!-- /Page Wrapper -->
  <user-modal></user-modal>
</template>
