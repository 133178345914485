<script>
export default {
  data() {
    return {
      title: "Invited User",
      text: "Users",
      text1: "Invited User",
      Name: "Invited Users List",
    };
  },
  methods: {
    submitForm() {
      this.$router.push("invite-user");
    },
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- User List -->
      <div class="card">
        <user-top :Name="Name"></user-top>

        <!-- Invaite-users List -->
        <invite-table></invite-table>
        <!-- /Invaite-users List-->
      </div>
      <!-- /User List -->
    </div>
  </div>
  <!-- /Page Wrapper -->
  <!-- Delete Modal -->
  <div class="modal fade" id="delete-modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="submitForm">
          <div class="modal-body text-center">
            <span class="delete-icon">
              <i class="ti ti-trash-x"></i>
            </span>
            <h4>Confirm Deletion</h4>
            <p>
              You want to delete all the marked items, this cant be undone once you
              delete.
            </p>
            <div class="d-flex justify-content-center">
              <a
                href="javascript:void(0);"
                class="btn btn-light me-3"
                data-bs-dismiss="modal"
                >Cancel</a
              >
              <button type="submit" class="btn btn-danger">Yes, Delete</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Delete Modal -->
</template>
