<script>
export default {
  data() {
    return {
      title: "Ban IP Address",
      text: "Others Settings",
      text1: "Ban IP Address",
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- Social-Authentication Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-xl-3 col-md-4">
              <others-sidebar></others-sidebar>
            </div>
            <div class="col-xl-9 col-md-8">
              <div class="card setting-content mb-0">
                <div class="card-header px-0 mx-3">
                  <div class="d-flex align-items-center justify-content-between">
                    <h4>Ban IP Address</h4>
                    <a
                      href="#"
                      class="btn btn-primary btn-sm d-flex align-items-center"
                      data-bs-toggle="modal"
                      data-bs-target="#add_ban"
                      ><i class="ti ti-circle-plus me-2"></i>Add New IP Address</a
                    >
                  </div>
                </div>
                <div class="card-body pb-0">
                  <div class="row bx-3">
                    <div class="col-lg-6">
                      <div class="card mb-3">
                        <div class="card-body">
                          <div
                            class="d-flex align-items-center justify-content-between border-bottom mb-2 pb-2"
                          >
                            <div class="d-flex align-items-center">
                              <span class="d-inline-flex me-2"
                                ><i class="ti ti-ban"></i
                              ></span>
                              <p class="fs-14 fw-medium text-dark">198.120.16.01</p>
                            </div>
                            <div class="d-flex align-items-center">
                              <a
                                href="#"
                                class="me-2"
                                data-bs-toggle="modal"
                                data-bs-target="#edit_ban"
                                ><span class="text-gray"><i class="ti ti-edit"></i></span
                              ></a>
                              <a href="#"
                                ><span class="text-gray"><i class="ti ti-trash"></i></span
                              ></a>
                            </div>
                          </div>
                          <div>
                            <p>
                              <span class="me-2"><i class="ti ti-info-circle"></i></span
                              >Temporarily block to protect user accounts from internet
                              fraudsters
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="card mb-3">
                        <div class="card-body">
                          <div
                            class="d-flex align-items-center justify-content-between border-bottom mb-2 pb-2"
                          >
                            <div class="d-flex align-items-center">
                              <span class="d-inline-flex me-2"
                                ><i class="ti ti-ban"></i
                              ></span>
                              <p class="fs-14 fw-medium text-dark">198.160.11.20</p>
                            </div>
                            <div class="d-flex align-items-center">
                              <a
                                href="#"
                                class="me-2"
                                data-bs-toggle="modal"
                                data-bs-target="#edit_ban"
                                ><span class="text-gray"><i class="ti ti-edit"></i></span
                              ></a>
                              <a href="#"
                                ><span class="text-gray"><i class="ti ti-trash"></i></span
                              ></a>
                            </div>
                          </div>
                          <div>
                            <p>
                              <span class="me-2"><i class="ti ti-info-circle"></i></span
                              >Unauthorized access attempts, or other signs of a potential
                              security
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="card mb-3">
                        <div class="card-body">
                          <div
                            class="d-flex align-items-center justify-content-between border-bottom mb-2 pb-2"
                          >
                            <div class="d-flex align-items-center">
                              <span class="d-inline-flex me-2"
                                ><i class="ti ti-ban"></i
                              ></span>
                              <p class="fs-14 fw-medium text-dark">198.123.10.2</p>
                            </div>
                            <div class="d-flex align-items-center">
                              <a
                                href="#"
                                class="me-2"
                                data-bs-toggle="modal"
                                data-bs-target="#edit_ban"
                                ><span class="text-gray"><i class="ti ti-edit"></i></span
                              ></a>
                              <a href="#"
                                ><span class="text-gray"><i class="ti ti-trash"></i></span
                              ></a>
                            </div>
                          </div>
                          <div>
                            <p>
                              <span class="me-2"><i class="ti ti-info-circle"></i></span
                              >Attempts to scrape large amounts of HR data from the system
                              without authorization.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="card mb-3">
                        <div class="card-body">
                          <div
                            class="d-flex align-items-center justify-content-between border-bottom mb-2 pb-2"
                          >
                            <div class="d-flex align-items-center">
                              <span class="d-inline-flex me-2"
                                ><i class="ti ti-ban"></i
                              ></span>
                              <p class="fs-14 fw-medium text-dark">198.110.01.05</p>
                            </div>
                            <div class="d-flex align-items-center">
                              <a
                                href="#"
                                class="me-2"
                                data-bs-toggle="modal"
                                data-bs-target="#edit_ban"
                                ><span class="text-gray"><i class="ti ti-edit"></i></span
                              ></a>
                              <a href="#"
                                ><span class="text-gray"><i class="ti ti-trash"></i></span
                              ></a>
                            </div>
                          </div>
                          <div>
                            <p>
                              <span class="me-2"><i class="ti ti-info-circle"></i></span
                              >Found downloading or uploading inappropriate content
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Social-Authentication Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
  <ban-address-modal></ban-address-modal>
</template>
