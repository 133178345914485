<script>
export default {
  data() {
    return {
    }
  },
};
</script>

<template>
  <div class="content main_content">
    <!-- Left Sidebar Menu -->
    <div class="sidebar-menu">
      <div class="logo">
        <router-link to="/index" class="logo-normal"
          ><img src="@/assets/img/logo.svg" alt="Logo"
        /></router-link>
      </div>
      <div class="menu-wrap">
        <div class="main-menu">
          <ul class="nav">
            <a-tooltip placement="right">
              <template #title>Chats</template>
              <li>
                <router-link
                  to="/index"
                  class="active"
                  data-bs-toggle="tab"
                  data-bs-target="#chat-menu"
                >
                  <i class="ti ti-message-2-heart"></i>
                </router-link>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Contacts</template>
              <li>
                <a href="#" data-bs-toggle="tab" data-bs-target="#contact-menu">
                  <i class="ti ti-user-shield"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Groups</template>
              <li>
                <a href="#" data-bs-toggle="tab" data-bs-target="#group-menu">
                  <i class="ti ti-users-group"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Status</template>
              <li>
                <router-link to="/status">
                  <i class="ti ti-circle-dot"></i>
                </router-link>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Calls</template>
              <li>
                <a href="#" data-bs-toggle="tab" data-bs-target="#call-menu">
                  <i class="ti ti-phone-call"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Profile</template>
              <li>
                <a href="#" data-bs-toggle="tab" data-bs-target="#profile-menu">
                  <i class="ti ti-user-circle"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Settings</template>
              <li>
                <a href="#" data-bs-toggle="tab" data-bs-target="#setting-menu">
                  <i class="ti ti-settings"></i>
                </a>
              </li>
            </a-tooltip>
          </ul>
        </div>
        <profile-menu></profile-menu>
      </div>
    </div>
    <!-- /Left Sidebar Menu -->

    <!-- sidebar group -->
    <div class="sidebar-group">
      <div class="tab-content">
        <div class="tab-pane fade active show" id="chat-menu">
          <!-- Chats sidebar -->
          <index-sidebar></index-sidebar>
          <!-- / Chats sidebar -->
        </div>

        <!-- Contact -->
        <div class="tab-pane fade" id="contact-menu">
          <!-- Chats sidebar -->
          <chat-sidebar></chat-sidebar>
          <!-- / Chats sidebar -->
        </div>
        <!-- /Contact -->

        <!-- Group -->
        <div class="tab-pane fade" id="group-menu">
          <!-- Chats sidebar -->
          <group-sidebar></group-sidebar>
          <!-- / Chats sidebar -->
        </div>
        <!-- /Group -->

        <!-- Profile -->
        <div class="tab-pane fade" id="profile-menu">
          <!-- Profile sidebar -->
          <profile-sidebar></profile-sidebar>
          <!-- / Profile sidebar -->
        </div>
        <!-- /Profile -->

        <!-- Calls -->
        <div class="tab-pane fade" id="call-menu">
          <!-- Call sidebar -->
          <calls-sidebar></calls-sidebar>
          <!-- / Call sidebar -->
        </div>
        <!-- /Calls -->

        <!-- Settings -->
        <div class="tab-pane fade" id="setting-menu">
          <!-- Profile sidebar -->
          <settings-sidebar></settings-sidebar>
          <!-- / Chats sidebar -->
        </div>
        <!-- /Settings -->
      </div>
    </div>
    <!-- /Sidebar group -->

    <!-- Chat -->
    <chat-content></chat-content>
    <!-- /Chat -->
    <contact-info></contact-info>
    <fav-info></fav-info>
    <modal-index></modal-index>
  </div>
</template>
