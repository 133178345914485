<script>
export default {
  data() {
    return {
      title: "Integrations",
      text: "App Settings",
      text1: "Integrations",
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- Integration Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-xl-3 col-md-4">
              <app-sidebar></app-sidebar>
            </div>
            <div class="col-xl-9 col-md-8">
              <div class="card setting-content mb-0">
                <div class="card-header px-0 mx-3">
                  <h4>Integrations</h4>
                </div>
                <div class="card-body pb-0">
                  <div class="company-img pt-0">
                    <div class="row gx-3">
                      <div class="col-xl-6 col-sm-12 d-flex">
                        <div class="card social-auth flex-fill mb-3">
                          <div class="card-body">
                            <div class="header-section">
                              <div class="company-img-title">
                                <div class="social-icons">
                                  <span
                                    ><img
                                      src="@/assets/admin/img/Settings/google-captcha.svg"
                                      alt="icons"
                                      class="img-fluid"
                                  /></span>
                                  <h6>Google Captcha</h6>
                                </div>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </div>
                              <p>
                                Google CAPTCHA blocks bots by verifying user interactions.
                              </p>
                            </div>
                            <div class="body-footer">
                              <div class="footer-content d-flex">
                                <a
                                  href="#"
                                  class="btn btn-sm btn-white fs-13 fw-medium d-flex align-items-center"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add_captcha"
                                >
                                  <i class="ti ti-settings-cog me-2"></i> View
                                  Integration</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-6 col-sm-12 d-flex">
                        <div class="card social-auth flex-fill mb-3">
                          <div class="card-body">
                            <div class="header-section">
                              <div class="company-img-title">
                                <div class="social-icons">
                                  <span
                                    ><img
                                      src="@/assets/admin/img/Settings/agora-settings.svg"
                                      alt="icons"
                                      class="img-fluid"
                                  /></span>
                                  <h6>Agora Settings</h6>
                                </div>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </div>
                              <p>
                                Agora provides real-time communication APIs for video,
                                voice, and messaging applications.
                              </p>
                            </div>
                            <div class="body-footer">
                              <div class="footer-content d-flex">
                                <a
                                  href="#"
                                  class="btn btn-sm btn-white fs-13 fw-medium d-flex align-items-center"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add_agora"
                                >
                                  <i class="ti ti-settings-cog me-2"></i> View
                                  Integration</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-6 col-sm-12 d-flex">
                        <div class="card social-auth flex-fill mb-3">
                          <div class="card-body">
                            <div class="header-section">
                              <div class="company-img-title">
                                <div class="social-icons">
                                  <span
                                    ><img
                                      src="@/assets/admin/img/Settings/firebase-settings.svg"
                                      alt="icons"
                                      class="img-fluid"
                                  /></span>
                                  <h6>Firebase Settings</h6>
                                </div>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </div>
                              <p>
                                Firebase is a platform for app development with tools for
                                databases, authentication, and analytics.
                              </p>
                            </div>
                            <div class="body-footer">
                              <div class="footer-content d-flex">
                                <a
                                  href="#"
                                  class="btn btn-sm btn-white fs-13 fw-medium d-flex align-items-center"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add_fire"
                                >
                                  <i class="ti ti-settings-cog me-2"></i> View
                                  Integration</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Integration Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
  <integrations-modal></integrations-modal>
</template>
