<script>
export default {
  data() {
    return {
      title: "Language",
      text: "System Settings",
      text1: "Language",
      LanguaSele: ["Select Language", "English", "Spanish"],
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- Profile Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-xl-3 col-md-4">
              <system-sidebar></system-sidebar>
            </div>
            <div class="col-xl-9 col-md-8">
              <div class="card setting-content mb-0">
                <div class="card-header px-0 mx-3">
                  <div class="row g-3 align-items-center">
                    <div class="col-md-6 col-sm-4">
                      <h4>Language</h4>
                    </div>
                    <div class="col-md-6 col-sm-8">
                      <div
                        class="d-flex justify-content-sm-end align-items-center flex-wrap row-gap-2"
                      >
                        <div class="me-3">
                          <vue-select
                            :options="LanguaSele"
                            id="languasele"
                            placeholder="Select Language"
                          />
                        </div>
                        <router-link to="add-language" class="btn btn-primary"
                          >Add Language</router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body pb-0">
                  <div class="card mb-3">
                    <div class="card-header">
                      <div class="row align-items-center g-3">
                        <div class="col-sm-8">
                          <h6>Language List</h6>
                        </div>
                        <div class="col-sm-4">
                          <div class="position-relative search-input">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search"
                            />
                            <div class="search-addon">
                              <span><i class="ti ti-search"></i></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <div class="table-responsive">
                        <table class="table">
                          <thead class="thead-light">
                            <tr>
                              <th class="no-sort">
                                <div class="form-check form-check-md">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="select-all"
                                  />
                                </div>
                              </th>
                              <th>Language</th>
                              <th>Code</th>
                              <th>RTL</th>
                              <th>Default</th>
                              <th>Total</th>
                              <th>Done</th>
                              <th>Progress</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div class="form-check form-check-md">
                                  <input class="form-check-input" type="checkbox" />
                                </div>
                              </td>
                              <td>
                                <h6 class="d-flex align-items-center fw-medium">
                                  <img
                                    src="@/assets/admin/img/flag/ar.png"
                                    class="me-2"
                                    alt=""
                                  />
                                  Arabic
                                </h6>
                              </td>
                              <td>en</td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                              <td>1620</td>
                              <td>1296</td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="circle-progress" data-value="80">
                                    <span class="progress-left">
                                      <span class="progress-bar border-warning"></span>
                                    </span>
                                    <span class="progress-right">
                                      <span class="progress-bar border-warning"></span>
                                    </span>
                                  </div>
                                  <div class="progress-value ms-2">80%</div>
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <a
                                    href="#"
                                    class="btn btn-sm btn-icon btn-light border me-2"
                                  >
                                    <i class="ti ti-download"></i>
                                  </a>
                                  <router-link
                                    to="language-web"
                                    class="btn btn-sm border me-2"
                                    >Web</router-link
                                  >
                                  <router-link
                                    to="language-web"
                                    class="btn btn-sm border me-2"
                                    >App</router-link
                                  >
                                  <router-link
                                    to="language-web"
                                    class="btn btn-sm border me-2"
                                    >Admin</router-link
                                  >
                                  <a
                                    href="#"
                                    class="btn btn-sm btn-icon btn-light border"
                                  >
                                    <i class="ti ti-trash"></i>
                                  </a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="form-check form-check-md">
                                  <input class="form-check-input" type="checkbox" />
                                </div>
                              </td>
                              <td>
                                <h6 class="d-flex align-items-center fw-medium">
                                  <img
                                    src="@/assets/admin/img/flag/cn.png"
                                    class="me-2"
                                    alt=""
                                  />
                                  Chinese
                                </h6>
                              </td>
                              <td>zh</td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                  />
                                </div>
                              </td>
                              <td>1620</td>
                              <td>972</td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="circle-progress" data-value="60">
                                    <span class="progress-left">
                                      <span class="progress-bar border-skyblue"></span>
                                    </span>
                                    <span class="progress-right">
                                      <span class="progress-bar border-skyblue"></span>
                                    </span>
                                  </div>
                                  <div class="progress-value ms-2">60%</div>
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <a
                                    href="#"
                                    class="btn btn-sm btn-icon btn-light border me-2"
                                  >
                                    <i class="ti ti-download"></i>
                                  </a>
                                  <router-link
                                    to="language-web"
                                    class="btn btn-sm border me-2"
                                    >Web</router-link
                                  >
                                  <router-link
                                    to="language-web"
                                    class="btn btn-sm border me-2"
                                    >App</router-link
                                  >
                                  <router-link
                                    to="language-web"
                                    class="btn btn-sm border me-2"
                                    >Admin</router-link
                                  >
                                  <a
                                    href="#"
                                    class="btn btn-sm btn-icon btn-light border"
                                  >
                                    <i class="ti ti-trash"></i>
                                  </a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="form-check form-check-md">
                                  <input class="form-check-input" type="checkbox" />
                                </div>
                              </td>
                              <td>
                                <h6 class="d-flex align-items-center fw-medium">
                                  <img
                                    src="@/assets/admin/img/flag/us.png"
                                    class="me-2"
                                    alt=""
                                  />
                                  English
                                </h6>
                              </td>
                              <td>en</td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                  />
                                </div>
                              </td>
                              <td>1620</td>
                              <td>810</td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="circle-progress" data-value="50">
                                    <span class="progress-left">
                                      <span class="progress-bar border-purple"></span>
                                    </span>
                                    <span class="progress-right">
                                      <span class="progress-bar border-purple"></span>
                                    </span>
                                  </div>
                                  <div class="progress-value ms-2">50%</div>
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <a
                                    href="#"
                                    class="btn btn-sm btn-icon btn-light border me-2"
                                  >
                                    <i class="ti ti-download"></i>
                                  </a>
                                  <router-link
                                    to="language-web"
                                    class="btn btn-sm border me-2"
                                    >Web</router-link
                                  >
                                  <router-link
                                    to="language-web"
                                    class="btn btn-sm border me-2"
                                    >App</router-link
                                  >
                                  <router-link
                                    to="language-web"
                                    class="btn btn-sm border me-2"
                                    >Admin</router-link
                                  >
                                  <a
                                    href="#"
                                    class="btn btn-sm btn-icon btn-light border"
                                  >
                                    <i class="ti ti-trash"></i>
                                  </a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="form-check form-check-md">
                                  <input class="form-check-input" type="checkbox" />
                                </div>
                              </td>
                              <td>
                                <h6 class="d-flex align-items-center fw-medium">
                                  <img
                                    src="@/assets/admin/img/flag/in.png"
                                    class="me-2"
                                    alt=""
                                  />
                                  Hindi
                                </h6>
                              </td>
                              <td>hi</td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                  />
                                </div>
                              </td>
                              <td>1620</td>
                              <td>324</td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="circle-progress" data-value="20">
                                    <span class="progress-left">
                                      <span class="progress-bar border-danger"></span>
                                    </span>
                                    <span class="progress-right">
                                      <span class="progress-bar border-danger"></span>
                                    </span>
                                  </div>
                                  <div class="progress-value ms-2">20%</div>
                                </div>
                              </td>
                              <td>
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <a
                                    href="#"
                                    class="btn btn-sm btn-icon btn-light border me-2"
                                  >
                                    <i class="ti ti-download"></i>
                                  </a>
                                  <router-link
                                    to="language-web"
                                    class="btn btn-sm border me-2"
                                    >Web</router-link
                                  >
                                  <router-link
                                    to="language-web"
                                    class="btn btn-sm border me-2"
                                    >App</router-link
                                  >
                                  <router-link
                                    to="language-web"
                                    class="btn btn-sm border me-2"
                                    >Admin</router-link
                                  >
                                  <a
                                    href="#"
                                    class="btn btn-sm btn-icon btn-light border"
                                  >
                                    <i class="ti ti-trash"></i>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Profile Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
