<script>

const columns = [
    {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        sorter: {
            compare: (a, b) => {
                a = a.Name.toLowerCase();
                b = b.Name.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Phone Number",
        dataIndex: "Phone_Number",
        sorter: {
            compare: (a, b) => {
                a = a.Phone_Number.toLowerCase();
                b = b.Phone_Number.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Email Address",
        dataIndex: "Email_Address",
        sorter: {
            compare: (a, b) => {
                a = a.Email_Address.toLowerCase();
                b = b.Email_Address.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Stories Date",
        dataIndex: "Stories_Date",
        sorter: {
            compare: (a, b) => {
                a = a.Stories_Date.toLowerCase();
                b = b.Stories_Date.toLowerCase();
                return a > b ? -1 : b > a ? 1 : 0;
            },
        },
    },
    {
        title: "Action",
        key: "Action",
        sorter: false,
    }
]

const data = [
 {
    "Image": "user-01.jpg",
    "Name": "Aaryian Jose",
    "Phone_Number": "514-245-98315",
    "Email_Address": "aaryian@example.com",
    "Stories_Date": "02 Sep 2024, 10:00 AM"
 },
 {
    "Image": "user-02.jpg",
    "Name": "Sarika Jain",
    "Phone_Number": "156-348-85496",
    "Email_Address": "sarika@example.com",
    "Stories_Date": "14 Sep 2024, 11:30 AM"
 },
 {
    "Image": "user-03.jpg",
    "Name": "Clyde Smith",
    "Phone_Number": "315-975-31849",
    "Email_Address": "clyde@example.com",
    "Stories_Date": "28 Sep 2024, 08:15 AM"
 },
 {
    "Image": "user-04.jpg",
    "Name": "Carla Jenkins",
    "Phone_Number": "325-859-20194",
    "Email_Address": "carla@example.com",
    "Stories_Date": "12 Oct 2024, 06:40 PM"
 },
 {
    "Image": "user-05.jpg",
    "Name": "Federico Wells",
    "Phone_Number": "314-829-30175",
    "Email_Address": "federico@example.com",
    "Stories_Date": "20 Oct 2024, 04:18 PM"
 },
 {
    "Image": "user-06.jpg",
    "Name": "Edward Lietz",
    "Phone_Number": "219-831-49521",
    "Email_Address": "edward@example.com",
    "Stories_Date": "30 Oct 2024, 07:25 AM"
 },
 {
    "Image": "user-07.jpg",
    "Name": "Sharon Ford",
    "Phone_Number": "198-301-75341",
    "Email_Address": "sharon@example.com",
    "Stories_Date": "01 Nov 2024, 08:50 AM"
 },
 {
    "Image": "user-08.jpg",
    "Name": "Thomas Rethman",
    "Phone_Number": "301-978-30986",
    "Email_Address": "thomas@example.com",
    "Stories_Date": "10 Nov 2024, 11:20 AM"
 },
 {
    "Image": "user-09.jpg",
    "Name": "Wilbur Martinez",
    "Phone_Number": "383-248-34968",
    "Email_Address": "wilbur@example.com",
    "Stories_Date": "17 Nov 2024, 04:50 PM"
 },
 {
    "Image": "user-10.jpg",
    "Name": "Danielle Baker",
    "Phone_Number": "736-795-34895",
    "Email_Address": "danielle@example.com",
    "Stories_Date": "12 Dec 2024, 09:30 AM"
 }
]

const rowSelection = {
  onChange: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
};

export default {
    data() {
        return {
            data,
            columns,
            rowSelection,
        }
    },
}
</script>

<template>
    <div class="custom-datatable-filter table-responsive">
        <a-table
        class="table datatable thead-light"
        :columns="columns"
        :data-source="data"
        :row-selection="rowSelection"
        >
            <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'Name'">
                    <div class="d-flex align-items-center">
                        <a href="javascript:void(0);" class="avatar avatar-md"><img
                                :src="require(`@/assets/admin/img/users/${record.Image}`)"
                                class="img-fluid rounded-circle" alt="img"></a>
                        <div class="ms-2 profile-name">
                            <p class="text-dark mb-0"><a href="#">{{record.Name}}</a></p>
                        </div>
                    </div>
                </template>
                <template v-if="column.key === 'Action'">
                    <a href="#" data-bs-toggle="modal" data-bs-target="#delete-modal"><span class="file-icon"><i class="ti ti-trash"></i></span></a>
                </template>
            </template>
        </a-table>
    </div>
</template>