<script>
export default {
  data() {
    return {
      title: "Language",
      text: "System Settings",
      text1: "Language",
      LanguaSele: ["Select Language", "English", "Spanish"],
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- Profile Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-xl-3 col-md-4">
              <system-sidebar></system-sidebar>
            </div>
            <div class="col-xl-9 col-md-8">
              <div class="card setting-content mb-0">
                <div class="card-header px-0 mx-3">
                  <h4>Language</h4>
                </div>
                <div class="card-body pb-0">
                  <div class="card mb-3">
                    <div class="card-header">
                      <div class="row align-items-center g-3">
                        <div class="col-lg-6 col-sm-4">
                          <h6>Language</h6>
                        </div>
                        <div class="col-lg-6 col-sm-8">
                          <div class="d-flex align-items-center justify-content-sm-end">
                            <router-link
                              to="language"
                              class="btn btn-sm btn-primary d-inline-flex align-items-center me-3"
                            >
                              <i class="ti ti-arrow-left me-2"></i>
                              Back to Translations
                            </router-link>
                            <a
                              href="#"
                              class="btn btn-sm btn-outline-dark d-inline-flex align-items-center"
                            >
                              <img
                                src="@/assets/admin/img/flag/ar.png"
                                class="me-2"
                                alt=""
                              />
                              Arabic
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body p-0">
                      <div class="table-responsive">
                        <table class="table">
                          <thead class="thead-light">
                            <tr>
                              <th class="no-sort">
                                <div class="form-check form-check-md">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="select-all"
                                  />
                                </div>
                              </th>
                              <th>Module Name</th>
                              <th>Total</th>
                              <th>Complete</th>
                              <th>Progress</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div class="form-check form-check-md">
                                  <input class="form-check-input" type="checkbox" />
                                </div>
                              </td>
                              <td>
                                <h6 class="fw-medium fs-14">Chat</h6>
                              </td>
                              <td>560</td>
                              <td>560</td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="progress progress-xs" style="width: 120px">
                                    <div
                                      class="progress-bar bg-success rounded"
                                      role="progressbar"
                                      style="width: 100%"
                                      aria-valuenow="100"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                  <span class="d-inline-flex fs-12 ms-2">100%</span>
                                </div>
                              </td>
                              <td>
                                <a href="#" class="btn btn-sm btn-icon border btn-light"
                                  ><i class="ti ti-edit"></i
                                ></a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="form-check form-check-md">
                                  <input class="form-check-input" type="checkbox" />
                                </div>
                              </td>
                              <td>
                                <h6 class="fw-medium fs-14">Group</h6>
                              </td>
                              <td>320</td>
                              <td>262</td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="progress progress-xs" style="width: 120px">
                                    <div
                                      class="progress-bar bg-warning rounded"
                                      role="progressbar"
                                      style="width: 80%"
                                      aria-valuenow="80"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                  <span class="d-inline-flex fs-12 ms-2">80%</span>
                                </div>
                              </td>
                              <td>
                                <a href="#" class="btn btn-sm btn-icon border btn-light"
                                  ><i class="ti ti-edit"></i
                                ></a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="form-check form-check-md">
                                  <input class="form-check-input" type="checkbox" />
                                </div>
                              </td>
                              <td>
                                <h6 class="fw-medium fs-14">Contacts</h6>
                              </td>
                              <td>270</td>
                              <td>180</td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="progress progress-xs" style="width: 120px">
                                    <div
                                      class="progress-bar bg-pink rounded"
                                      role="progressbar"
                                      style="width: 70%"
                                      aria-valuenow="70"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                  <span class="d-inline-flex fs-12 ms-2">70%</span>
                                </div>
                              </td>
                              <td>
                                <a href="#" class="btn btn-sm btn-icon border btn-light"
                                  ><i class="ti ti-edit"></i
                                ></a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="form-check form-check-md">
                                  <input class="form-check-input" type="checkbox" />
                                </div>
                              </td>
                              <td>
                                <h6 class="fw-medium fs-14">Calls</h6>
                              </td>
                              <td>250</td>
                              <td>154</td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="progress progress-xs" style="width: 120px">
                                    <div
                                      class="progress-bar bg-purple rounded"
                                      role="progressbar"
                                      style="width: 40%"
                                      aria-valuenow="40"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                  <span class="d-inline-flex fs-12 ms-2">40%</span>
                                </div>
                              </td>
                              <td>
                                <a href="#" class="btn btn-sm btn-icon border btn-light"
                                  ><i class="ti ti-edit"></i
                                ></a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="form-check form-check-md">
                                  <input class="form-check-input" type="checkbox" />
                                </div>
                              </td>
                              <td>
                                <h6 class="fw-medium fs-14">Settings</h6>
                              </td>
                              <td>220</td>
                              <td>140</td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <div class="progress progress-xs" style="width: 120px">
                                    <div
                                      class="progress-bar bg-skyblue rounded"
                                      role="progressbar"
                                      style="width: 60%"
                                      aria-valuenow="40"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                  <span class="d-inline-flex fs-12 ms-2">60%</span>
                                </div>
                              </td>
                              <td>
                                <a href="#" class="btn btn-sm btn-icon border btn-light"
                                  ><i class="ti ti-edit"></i
                                ></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Profile Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
