<script>
export default {
  data() {
    return {
      title: "Change Password",
      text: "General Settings",
      text1: "Change Password",
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
    };
  },
  methods: {
    toggleShowCurrentPassword() {
      this.showCurrentPassword = !this.showCurrentPassword;
    },
    toggleShowNewPassword() {
      this.showNewPassword = !this.showNewPassword;
    },
    toggleShowConfirmPassword() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- General Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-xl-3 col-md-4">
              <general-sidebar></general-sidebar>
            </div>
            <!-- Change Password -->
            <div class="col-xl-9 col-md-8">
              <div class="card setting-content mb-0">
                <div class="card-header px-0 mx-3">
                  <h4>Change Password</h4>
                </div>
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-10 col-lg-10">
                      <div class="row change-password d-flex align-items-center">
                        <div class="col-md-5">
                          <label class="form-label flex-fill">Current Password</label>
                        </div>
                        <div class="col-md-6">
                          <div class="pass-group flex-fill">
                            <input
                              :type="showCurrentPassword ? 'text' : 'password'"
                              class="pass-inputs form-control"
                              v-model="currentPassword"
                            />
                            <span
                              @click="toggleShowCurrentPassword"
                              class="ti toggle-passwords"
                              :class="showCurrentPassword ? 'ti-eye' : 'ti-eye-off'"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-10 col-lg-10">
                      <div class="row change-password d-flex align-items-center">
                        <div class="col-md-5">
                          <label class="form-label flex-fill">New Password</label>
                        </div>
                        <div class="col-md-6">
                          <div class="pass-group flex-fill">
                            <input
                              :type="showNewPassword ? 'text' : 'password'"
                              class="pass-inputs form-control"
                              v-model="newPassword"
                            />
                            <span
                              @click="toggleShowNewPassword"
                              class="ti toggle-passwords"
                              :class="showNewPassword ? 'ti-eye' : 'ti-eye-off'"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-10 col-lg-10">
                      <div class="row change-password d-flex align-items-center mb-3">
                        <div class="col-md-5">
                          <label class="form-label flex-fill">Confirm Password</label>
                        </div>
                        <div class="col-md-6">
                          <div class="pass-group flex-fill">
                            <input
                              :type="showConfirmPassword ? 'text' : 'password'"
                              class="conform-pass-input form-control"
                              v-model="confirmPassword"
                            />
                            <span
                              @click="toggleShowConfirmPassword"
                              class="ti conform-toggle-password"
                              :class="showConfirmPassword ? 'ti-eye' : 'ti-eye-off'"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer mx-3 px-0">
                  <div class="d-flex align-items-center justify-content-end m-0">
                    <a href="#" class="btn btn-outline-primary me-2">Cancel</a>
                    <a href="#" class="btn btn-primary">Save</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Change Password -->
          </div>
        </div>
      </div>
      <!-- /General Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
