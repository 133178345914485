<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
export default {
  data() {
    return {
        settings: {
            suppressScrollX: true,
        },
    };
  },
  methods: {
    scrollHanle() {},
  },
  components: {
    PerfectScrollbar,
  },
};
</script>

<template>
    <div id="chats" class="sidebar-content active slimscroll">
        <perfect-scrollbar
            class="scroll-area"
            :settings="settings"
            @ps-scroll-y="scrollHanle"
        >
            <div class="slimscroll">

                <div class="chat-search-header">                            
                    <div class="header-title d-flex align-items-center justify-content-between">
                        <h4 class="mb-3">Status</h4>
                        <div class="d-flex align-items-center mb-3">
                            <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#new-status" class="add-icon text-white bg-primary fs-16 d-flex justify-content-center align-items-center">
                                <i class="ti ti-plus"></i>
                            </a>
                        </div>
                    </div>

                    <!-- Chat Search -->
                    <div class="search-wrap">
                        <form action="#">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Search Contacts">
                                <span class="input-group-text"><i class="ti ti-search"></i></span>
                            </div>
                        </form>
                    </div>
                    <!-- /Chat Search --> 
                </div>       


                <div class="sidebar-body chat-body" id="chatsidebar">
                <div class="status-list">
                        <!-- Left Chat Title -->
                        <div class="d-flex  mb-3">
                            <h5>My Status</h5>
                            
                        </div>
                        <!-- /Left Chat Title -->

                        <div class="chat-users-wrap">
                            <div class="position-relative">
                                <router-link to="my-status" class="chat-user-list mb-0">
                                    <div class="avatar avatar-lg idle me-2">
                                        <img src="@/assets/img/profiles/avatar-17.jpg" class="rounded-circle" alt="image">
                                    </div>
                                    <div class="chat-user-info">
                                        <div class="chat-user-msg">
                                            <h6>Rabino Desilva</h6>
                                            <p>Today at 06:25 AM</p>
                                        </div>
                                        
                                    </div>
                                </router-link>                        
                                <div class="chats-dropdown">
                                    <a class="#" href="#" data-bs-toggle="dropdown">
                                        <i class="ti ti-dots-vertical"></i>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-end p-3">
                                        <li><a class="dropdown-item" href="#"><i class="ti ti-share-3 me-2"></i>Share Status</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="ti ti-arrow-forward-up-double me-2"></i>Forward</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="ti ti-trash me-2"></i>Delete</a></li>
                                    </ul>
                                </div>     
                            </div>
                        </div>
                </div>
                <div class="status-list">
                        <!-- Left Chat Title -->
                        <div class="d-flex  mb-3">
                            <h5>Recent Updates</h5>
                            
                        </div>
                        <!-- /Left Chat Title -->

                        <div class="chat-users-wrap">
                            <div class="position-relative">
                                <router-link to="user-status" class="chat-user-list">
                                    <div class="avatar avatar-lg online me-2">
                                        <img src="@/assets/img/profiles/avatar-05.jpg" class="rounded-circle" alt="image">
                                    </div>
                                    <div class="chat-user-info">
                                        <div class="chat-user-msg">
                                            <h6>Federico Wells</h6>
                                            <p>4 hrs ago</p>
                                        </div>
                                        
                                    </div>
                                </router-link>                        
                                <div class="chats-dropdown">
                                    <a class="#" href="#" data-bs-toggle="dropdown">
                                        <i class="ti ti-dots-vertical"></i>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-end p-3">
                                        <li><a class="dropdown-item" href="#"><i class="ti ti-volume-off me-2"></i>Mute</a></li>
                                    </ul>
                                </div>     
                            </div>
                            <div class="position-relative">
                                <router-link to="user-status" class="chat-user-list mb-0">
                                    <div class="avatar avatar-lg online me-2">
                                        <img src="@/assets/img/profiles/avatar-13.jpg" class="rounded-circle" alt="image">
                                    </div>
                                    <div class="chat-user-info">
                                        <div class="chat-user-msg">
                                            <h6>Morkel Jerrin</h6>
                                            <p>Today at 7:15 AM</p>
                                        </div>
                                        
                                    </div>
                                </router-link>                        
                                <div class="chats-dropdown">
                                    <a class="#" href="#" data-bs-toggle="dropdown">
                                        <i class="ti ti-dots-vertical"></i>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-end p-3">
                                        <li><a class="dropdown-item" href="#"><i class="ti ti-volume-off me-2"></i>Mute</a></li>
                                    </ul>
                                </div>     
                            </div>
                        </div>
                    </div>
                <div class="status-list">
                        <!-- Left Chat Title -->
                        <div class="d-flex  mb-3">
                            <h5>Already Seen</h5>
                            
                        </div>
                        <!-- /Left Chat Title -->

                        <div class="chat-users-wrap">
                            <div class="position-relative">
                                <router-link to="user-status" class="chat-user-list">
                                    <div class="avatar avatar-lg online me-2">
                                        <img src="@/assets/img/profiles/avatar-07.jpg" class="rounded-circle" alt="image">
                                    </div>
                                    <div class="chat-user-info">
                                        <div class="chat-user-msg">
                                            <h6>Danielle Baker</h6>
                                            <p>Just Now</p>
                                        </div>
                                        
                                    </div>
                                </router-link>                        
                                <div class="chats-dropdown">
                                    <a class="#" href="#" data-bs-toggle="dropdown">
                                        <i class="ti ti-dots-vertical"></i>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-end p-3">
                                        <li><a class="dropdown-item" href="#"><i class="ti ti-volume-off me-2"></i>Mute</a></li>
                                    </ul>
                                </div>     
                            </div>
                            <div class="position-relative">
                                <router-link to="user-status" class="chat-user-list">
                                    <div class="avatar avatar-lg online me-2">
                                        <img src="@/assets/img/profiles/avatar-06.jpg" class="rounded-circle" alt="image">
                                    </div>
                                    <div class="chat-user-info">
                                        <div class="chat-user-msg">
                                            <h6>Edward Lietz</h6>
                                            <p>2 hrs ago</p>
                                        </div>
                                        
                                    </div>
                                </router-link>                        
                                <div class="chats-dropdown">
                                    <a class="#" href="#" data-bs-toggle="dropdown">
                                        <i class="ti ti-dots-vertical"></i>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-end p-3">
                                        <li><a class="dropdown-item" href="#"><i class="ti ti-volume-off me-2"></i>Mute</a></li>
                                    </ul>
                                </div>     
                            </div>
                            <div class="position-relative">
                                <router-link to="user-status" class="chat-user-list">
                                    <div class="avatar avatar-lg offline me-2">
                                        <img src="@/assets/img/profiles/avatar-02.jpg" class="rounded-circle" alt="image">
                                    </div>
                                    <div class="chat-user-info">
                                        <div class="chat-user-msg">
                                            <h6>Sarika Jain</h6>
                                            <p>Today at 06:15 AM</p>
                                        </div>
                                        
                                    </div>
                                </router-link>                        
                                <div class="chats-dropdown">
                                    <a class="#" href="#" data-bs-toggle="dropdown">
                                        <i class="ti ti-dots-vertical"></i>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-end p-3">
                                        <li><a class="dropdown-item" href="#"><i class="ti ti-volume-off me-2"></i>Mute</a></li>
                                    </ul>
                                </div>     
                            </div>
                            <div class="position-relative">
                                <router-link to="user-status" class="chat-user-list mb-0">
                                    <div class="avatar avatar-lg online me-2">
                                        <img src="@/assets/img/profiles/avatar-10.jpg" class="rounded-circle" alt="image">
                                    </div>
                                    <div class="chat-user-info">
                                        <div class="chat-user-msg">
                                            <h6>Wilbur Martinez</h6>
                                            <p>Today at 04:20 AM</p>
                                        </div>
                                        
                                    </div>
                                </router-link>                        
                                <div class="chats-dropdown">
                                    <a class="#" href="#" data-bs-toggle="dropdown">
                                        <i class="ti ti-dots-vertical"></i>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-end p-3">
                                        <li><a class="dropdown-item" href="#"><i class="ti ti-volume-off me-2"></i>Mute</a></li>
                                    </ul>
                                </div>     
                            </div>
                        </div>
                    </div>
                    
                </div>

            </div>
        </perfect-scrollbar>
    </div>
</template>


