<script>
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: { VueEasyLightbox },
  data() {
    return {
      visible: false,
      currentIndex: 0,
      images: [
        { src: require("@/assets/img/gallery/gallery-01.jpg"), title: "Demo 01" },
        { src: require("@/assets/img/gallery/gallery-02.jpg"), title: "Demo 02" },
        { src: require("@/assets/img/gallery/gallery-03.jpg"), title: "Demo 03" },
        { src: require("@/assets/img/gallery/gallery-04.jpg"), title: "Demo 04" },
        { src: require("@/assets/img/gallery/gallery-05.jpg"), title: "Demo 05" },
      ],
    };
  },
  methods: {
    openLightbox(index) {
      this.currentIndex = index;
      this.visible = true;
    },
  },
};
</script>

<template>
  <div class="chat-img">
    <div class="img-wrap" v-for="(image, index) in images" :key="index">
      <img :src="image.src" alt="img" @click="openLightbox(index)" />
      <div class="img-overlay">
        <a href="javascript:void(0)" @click="openLightbox(index)" title="Demo 01">
          <i class="ti ti-eye"></i>
        </a>
        <a :href="image.src" download><i class="ti ti-download"></i></a>
      </div>
    </div>
  </div>
  <a
    class="gallery-img view-all d-flex align-items-center justify-content-center mt-3"
    href="javascript:void(0)"
    @click="openLightbox(0)"
  >
    View All Images <i class="ti ti-arrow-right ms-2"></i>
  </a>
  <vue-easy-lightbox
    :visible="visible"
    :imgs="images.map((img) => img.src)"
    :index="currentIndex"
    @hide="visible = false"
  />
</template>
