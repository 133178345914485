<template>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <a
        href="#"
        class="accordion-button collapsed"
        data-bs-toggle="collapse"
        data-bs-target="#media-video"
        aria-expanded="false"
        aria-controls="media-video"
      >
        <i class="ti ti-video me-2"></i> Videos
      </a>
    </h2>
    <div id="media-video" class="accordion-collapse collapse" data-bs-parent="#mediafile">
      <div class="accordion-body">
        <div class="chat-video">
          <!-- This image will act as the thumbnail to trigger the Fancybox modal -->
          <a
            href="https://www.youtube.com/embed/Mj9WJJNp5wA"
            data-fancybox
            data-caption=""
            class="fancybox video-img"
          >
            <img src="@/assets/img/video/video.jpg" alt="Video Thumbnail" />
            <span><i class="ti ti-player-play-filled"></i></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
export default {
  mounted() {
    // Initialize Fancybox on elements with the data-fancybox attribute
    Fancybox.bind("[data-fancybox]", {
      infinite: false, // optional: if you have multiple items, set to true if you want infinite scroll
    });
  },
};
</script>
