<template>
  <div class="row justify-content-center">
    <div class="col-md-6 col-xl-3 d-flex">
      <div class="card total-users flex-fill">
        <div class="card-body">
          <div class="total-counts">
            <div class="d-flex align-items-center">
              <span class="total-count-icons"><i class="ti ti-user-share"></i></span>
              <div>
                <p>Total Users</p>
                <h5>200</h5>
              </div>
            </div>
            <div class="percentage">
              <span class="bg-success">+5.63%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xl-3 d-flex">
      <div class="card total-users flex-fill">
        <div class="card-body">
          <div class="total-counts">
            <div class="d-flex align-items-center">
              <span class="bg-dark total-count-icons"
                ><i class="ti ti-users-group"></i
              ></span>
              <div>
                <p>Total Groups</p>
                <h5>150</h5>
              </div>
            </div>
            <div class="percentage">
              <span class="bg-danger">-42.05%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xl-3 d-flex">
      <div class="card total-users flex-fill">
        <div class="card-body">
          <div class="total-counts">
            <div class="d-flex align-items-center">
              <span class="bg-purple total-count-icons"
                ><i class="ti ti-brand-hipchat"></i
              ></span>
              <div>
                <p>Total Chats</p>
                <h5>2,584</h5>
              </div>
            </div>
            <div class="percentage">
              <span class="bg-success">+5.63%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xl-3 d-flex">
      <div class="card total-users flex-fill">
        <div class="card-body">
          <div class="total-counts">
            <div class="d-flex align-items-center">
              <span class="bg-info total-count-icons"
                ><i class="ti ti-circle-dot"></i
              ></span>
              <div>
                <p>Total Stories</p>
                <h5>1,640</h5>
              </div>
            </div>
            <div class="percentage">
              <span class="bg-success">+5.63%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
