<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
export default {
  data() {
    return {
        settings: {
            suppressScrollX: true,
        },
    };
  },
  methods: {
    scrollHanle() {},
  },
  components: {
    PerfectScrollbar,
  },
};
</script>

<template>
    <div class="sidebar-content active slimscroll">
        <perfect-scrollbar
            class="scroll-area"
            :settings="settings"
            @ps-scroll-y="scrollHanle"
        >
            <div class="slimscroll">

                <div class="chat-search-header">                            
                    <div class="header-title d-flex align-items-center justify-content-between">
                        <h4 class="mb-3">Contacts</h4>
                        <div class="d-flex align-items-center mb-3">
                            <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#add-contact" class="add-icon btn btn-primary p-0 d-flex align-items-center justify-content-center fs-16 me-2"><i class="ti ti-plus"></i></a>
                        </div>
                    </div>

                    <!-- Chat Search -->
                    <div class="search-wrap">
                        <form action="#">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Search Contacts">
                                <span class="input-group-text"><i class="ti ti-search"></i></span>
                            </div>
                        </form>
                    </div>
                    <!-- /Chat Search --> 
                </div>   

                <div class="sidebar-body chat-body">
                
                    <!-- Left Chat Title -->
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <h5>All Contacts</h5>
                    </div>
                    <!-- /Left Chat Title -->

                    <div class="chat-users-wrap">
                        <div class="mb-4">
                            <h6 class="mb-2">A</h6>   
                            <div class="chat-list">
                                <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#contact-details" class="chat-user-list">
                                    <div class="avatar avatar-lg online me-2">
                                        <img src="@/assets/img/profiles/avatar-01.jpg" class="rounded-circle" alt="image">
                                    </div>
                                    <div class="chat-user-info">
                                        <div class="chat-user-msg">
                                            <h6>Aaryian Jose</h6>
                                            <p>last seen 5 days ago</p>
                                        </div>
                                    </div>
                                </a>      
                            </div> 
                        </div>
                        <div class="mb-4">
                            <h6 class="mb-2">C</h6>   
                            <div class="chat-list"> 
                                <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#contact-details" class="chat-user-list">
                                    <div class="avatar avatar-lg offline me-2">
                                        <img src="@/assets/img/profiles/avatar-03.jpg" class="rounded-circle" alt="image">
                                    </div>
                                    <div class="chat-user-info">
                                        <div class="chat-user-msg">
                                            <h6>Clyde Smith</h6>
                                            <p>is busy now!</p>
                                        </div>
                                    </div>
                                </a>     
                            </div>  
                            <div class="chat-list">
                                <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#contact-details" class="chat-user-list">
                                    <div class="avatar avatar-lg online me-2">
                                        <img src="@/assets/img/profiles/avatar-04.jpg" class="rounded-circle" alt="image">
                                    </div>
                                    <div class="chat-user-info">
                                        <div class="chat-user-msg">
                                            <h6>Carla Jenkins</h6>
                                            <p>is online now</p>
                                        </div> 
                                    </div>
                                </a>       
                            </div>  
                        </div>
                        <div class="mb-4">
                            <h6 class="mb-2">D</h6>     
                            <div class="chat-list">
                                <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#contact-details" class="chat-user-list">
                                    <div class="avatar avatar-lg away me-2">
                                        <img src="@/assets/img/profiles/avatar-14.jpg" class="rounded-circle" alt="image">
                                    </div>
                                    <div class="chat-user-info">
                                        <div class="chat-user-msg">
                                            <h6>Danielle Baker</h6>
                                            <p>last seen a week ago</p>
                                        </div>
                                    </div>
                                </a>     
                            </div>    
                        </div>
                        <div class="mb-4">
                            <h6 class="mb-2">E</h6>   
                            <div class="chat-list">
                                <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#contact-details" class="chat-user-list">
                                    <div class="avatar avatar-lg online me-2">
                                        <img src="@/assets/img/profiles/avatar-06.jpg" class="rounded-circle" alt="image">
                                    </div>
                                    <div class="chat-user-info">
                                        <div class="chat-user-msg">
                                            <h6>Edward Lietz</h6>
                                            <p>Do you know which App or ...</p>
                                        </div>
                                    </div>
                                </a>    
                            </div>    
                        </div>
                        <div class="mb-2">
                            <h6 class="mb-2">F</h6>   
                            <div class="chat-list">
                                <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#contact-details" class="chat-user-list">
                                    <div class="avatar avatar-lg online me-2">
                                        <img src="@/assets/img/profiles/avatar-07.jpg" class="rounded-circle" alt="image">
                                    </div>
                                    <div class="chat-user-info">
                                        <div class="chat-user-msg">
                                            <h6>Federico Wells</h6>
                                            <p>last seen 10 min ago</p>
                                        </div>
                                    </div>
                                </a>    
                            </div>  
                        </div>
                    </div>
                </div>

            </div>
        </perfect-scrollbar>
    </div>
</template>

