<script>
const columns = [
  {
    title: "Reported By",
    dataIndex: "Reported_By",
    key: "Reported_By",
    sorter: {
      compare: (a, b) => {
        a = a.Reported_By.toLowerCase();
        b = b.Reported_By.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Reported User",
    dataIndex: "Reported_User",
    key: "Reported_User",
    sorter: {
      compare: (a, b) => {
        a = a.Reported_User.toLowerCase();
        b = b.Reported_User.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total Message Count",
    dataIndex: "Total_Message_Count",
    sorter: {
      compare: (a, b) => {
        a = a.Total_Message_Count.toLowerCase();
        b = b.Total_Message_Count.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Date",
    dataIndex: "Date",
    sorter: {
      compare: (a, b) => {
        a = a.Date.toLowerCase();
        b = b.Date.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "Action",
    sorter: false,
  },
];

const data = [
  {
    Image: "profile-01.jpg",
    Reported_By: "Alexander Manuel",
    User_Img: "user-01.jpg",
    Reported_User: "Aaryian Jose",
    Total_Message_Count: "100",
    Date: "02 Sep 2024, 10:00 AM",
  },
  {
    Image: "profile-02.jpg",
    Reported_By: "Elizabeth Sosa",
    User_Img: "user-02.jpg",
    Reported_User: "Sarika Jain",
    Total_Message_Count: "120",
    Date: "14 Sep 2024, 11:30 AM",
  },
  {
    Image: "profile-03.jpg",
    Reported_By: "Mark Villiams",
    User_Img: "user-03.jpg",
    Reported_User: "Clyde Smith",
    Total_Message_Count: "200",
    Date: "28 Sep 2024, 08:15 AM",
  },
  {
    Image: "profile-04.jpg",
    Reported_By: "Michael Howard",
    User_Img: "user-04.jpg",
    Reported_User: "Clyde Smith",
    Total_Message_Count: "250",
    Date: "12 Oct 2024, 06:40 PM",
  },
  {
    Image: "profile-05.jpg",
    Reported_By: "Horace Keene",
    User_Img: "user-05.jpg",
    Reported_User: "Federico Wells",
    Total_Message_Count: "300",
    Date: "12 Oct 2024, 06:40 PM",
  },
  {
    Image: "profile-06.jpg",
    Reported_By: "Hollis Tran",
    User_Img: "user-06.jpg",
    Reported_User: "Edward Lietz",
    Total_Message_Count: "180",
    Date: "30 Oct 2024, 07:25 AM",
  },
  {
    Image: "profile-07.jpg",
    Reported_By: "James Albert",
    User_Img: "user-07.jpg",
    Reported_User: "Sharon Ford",
    Total_Message_Count: "200",
    Date: "01 Nov 2024, 08:50 AM",
  },
  {
    Image: "profile-08.jpg",
    Reported_By: "Debra Jones",
    User_Img: "user-08.jpg",
    Reported_User: "Thomas Rethman",
    Total_Message_Count: "320",
    Date: "10 Nov 2024, 11:20 AM",
  },
  {
    Image: "profile-09.jpg",
    Reported_By: "Pearl Dewitt",
    User_Img: "user-09.jpg",
    Reported_User: "Wilbur Martinez",
    Total_Message_Count: "400",
    Date: "17 Nov 2024, 04:50 PM",
  },
  {
    Image: "profile-10.jpg",
    Reported_By: "Richard Ohare",
    User_Img: "user-10.jpg",
    Reported_User: "Danielle Baker",
    Total_Message_Count: "210",
    Date: "12 Dec 2024, 09:30 AM",
  },
];

const rowSelection = {
  onChange: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
};

export default {
  data() {
    return {
      data,
      columns,
      rowSelection,
    };
  },
};
</script>

<template>
  <div class="custom-datatable-filter table-responsive">
    <a-table
      class="table datatable thead-light"
      :columns="columns"
      :data-source="data"
      :row-selection="rowSelection"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'Reported_By'">
          <div class="d-flex align-items-center">
            <a href="javascript:void(0);" class="avatar avatar-md"
              ><img
                :src="require(`@/assets/admin/img/profiles/${record.Image}`)"
                class="img-fluid rounded-circle"
                alt="img"
            /></a>
            <div class="ms-2 profile-name">
              <p class="text-dark mb-0">
                <a href="#">{{ record.Reported_By }}</a>
              </p>
            </div>
          </div>
        </template>
        <template v-if="column.key === 'Reported_User'">
          <div class="d-flex align-items-center">
            <a href="javascript:void(0);" class="avatar avatar-md"
              ><img
                :src="require(`@/assets/admin/img/users/${record.User_Img}`)"
                class="img-fluid rounded-circle"
                alt="img"
            /></a>
            <div class="ms-2 profile-name">
              <p class="text-dark mb-0">
                <a href="#">{{ record.Reported_User }}</a>
              </p>
            </div>
          </div>
        </template>
        <template v-if="column.key === 'Action'">
          <a href="#" data-bs-toggle="modal" data-bs-target="#delete-modal"
            ><span class="file-icon"><i class="ti ti-trash"></i></span
          ></a>
        </template>
      </template>
    </a-table>
  </div>
</template>