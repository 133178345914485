<template>
  <div class="card mb-3 mb-md-0">
    <div class="card-body setting-sidebar">
      <div class="d-flex">
        <router-link to="localization-settings" class="rounded flex-fill"
          ><i class="ti ti-globe me-2"></i>Localization</router-link
        >
      </div>
      <div class="d-flex">
        <router-link to="email-settings" class="rounded flex-fill"
          ><i class="ti ti-mail-cog me-2"></i>Email Settings</router-link
        >
      </div>
      <div class="d-flex">
        <router-link to="sms-settings" class="rounded flex-fill"
          ><i class="ti ti-message-cog me-2"></i>SMS Settings</router-link
        >
      </div>
      <div class="d-flex">
        <router-link to="otp" class="rounded flex-fill"
          ><i class="ti ti-password me-2"></i>OTP Settings</router-link
        >
      </div>
      <div class="d-flex">
        <router-link to="language" class="rounded flex-fill"
          ><i class="ti ti-language me-2"></i>Language</router-link
        >
      </div>
      <div class="d-flex">
        <router-link to="gdpr" class="rounded flex-fill"
          ><i class="ti ti-cookie me-2"></i>GDPR Cookies</router-link
        >
      </div>
    </div>
  </div>
</template>
