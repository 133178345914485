<script>
import InputOtp from 'primevue/inputotp';
import { ref } from 'vue';

const value = ref(null);

export default {
  components: {
    InputOtp,
  },
  data() {
    return {
      value
    }
  },
  methods: {
    submitForm() {
      this.$router.push("/reset-password");
    },
  },
};
</script>

<template>
  <div class="container-fuild">
    <div class="w-100 overflow-hidden position-relative flex-wrap d-block vh-100">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <div
            class="row justify-content-center align-items-center vh-100 overflow-auto flex-wrap login-bg1"
          >
            <div class="col-md-9 mx-auto p-4">
              <form @submit.prevent="submitForm">
                <div>
                  <div class="mx-auto mb-5 text-center">
                    <img src="@/assets/img/full-logo.svg" class="img-fluid" alt="Logo" />
                  </div>
                  <div class="card">
                    <div class="card-body">
                      <div class="mb-4">
                        <h2 class="mb-2">OTP Verification</h2>
                        <p class="mb-0 fs-16">We send a code to test@example.com</p>
                      </div>
                      <div class="text-center digit-group">
                        <div
                          class="d-flex justify-content-center align-items-center mb-3"
                        >
                          <InputOtp v-model="value" />
                        </div>
                        <div>
                          <div class="badge bg-soft-danger mb-3">
                            <p>Otp will expire in 09:59</p>
                          </div>
                        </div>
                      </div>

                      <button
                        type="submit"
                        class="btn btn-primary w-100 justify-content-center"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                  <div class="mt-5 text-center">
                    <p class="mb-0 text-gray-9">
                      Dont receive an email?
                      <a href="javascript:void(0);" class="link-primary"
                        >click to resend</a
                      >
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-lg-6 p-0">
          <div
            class="d-lg-flex align-items-center justify-content-center position-relative d-lg-block d-none flex-wrap vh-100 overflowy-auto login-bg2"
          >
            <div class="floating-bg">
              <img src="@/assets/img/bg/circle-1.png" alt="Img" />
              <img src="@/assets/img/bg/circle-2.png" alt="Img" />
              <img src="@/assets/img/bg/emoji-01.svg" alt="Img" />
              <img src="@/assets/img/bg/emoji-02.svg" alt="Img" />
              <img src="@/assets/img/bg/emoji-03.svg" alt="Img" />
              <img src="@/assets/img/bg/emoji-04.svg" alt="Img" />
              <img src="@/assets/img/bg/right-arrow-01.svg" alt="Img" />
              <img src="@/assets/img/bg/right-arrow-02.svg" alt="Img" />
            </div>
            <div class="floating-avatar">
              <span class="avatar avatar-xl avatar-rounded border border-white">
                <img src="@/assets/img/profiles/avatar-12.jpg" alt="img" />
              </span>
              <span class="avatar avatar-xl avatar-rounded border border-white">
                <img src="@/assets/img/profiles/avatar-03.jpg" alt="img" />
              </span>
              <span class="avatar avatar-xl avatar-rounded border border-white">
                <img src="@/assets/img/profiles/avatar-02.jpg" alt="img" />
              </span>
              <span class="avatar avatar-xl avatar-rounded border border-white">
                <img src="@/assets/img/profiles/avatar-05.jpg" alt="img" />
              </span>
            </div>
            <div class="text-center">
              <img src="@/assets/img/bg/login-bg-1.png" class="login-img" alt="Img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
