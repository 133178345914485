<script>
export default {
  data() {
    return {
      title: "OTP Settings",
      text: "System Settings",
      text1: "OTP Settings",
      OtpMin: ["5 Mins", "10 Mins"],
      EmailSele: ["SMS", "Email"],
      OtpSelec: ["4", "6"],
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- Profile Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-md-3">
              <system-sidebar></system-sidebar>
            </div>
            <div class="col-md-9">
              <div class="card setting-content mb-0">
                <div class="card-header px-0 mx-3">
                  <h4>OTP Settings</h4>
                </div>
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="row align-items-center">
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <h6 class="mb-2 fw-medium">OTP Type</h6>
                            <p>Your can configure the type</p>
                          </div>
                        </div>
                        <div class="col-lg-5">
                          <div class="mb-3">
                            <vue-select
                              :options="EmailSele"
                              id="emailsele"
                              placeholder="SMS"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <h6 class="mb-2 fw-medium">OTP Digit Limit</h6>
                            <p>Select size of the format</p>
                          </div>
                        </div>
                        <div class="col-lg-5">
                          <div class="mb-3">
                            <vue-select
                              :options="OtpSelec"
                              id="otpselec"
                              placeholder="4"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center">
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <h6 class="mb-2 fw-medium">OTP Expire Time</h6>
                            <p>Select expire time of OTP</p>
                          </div>
                        </div>
                        <div class="col-lg-5">
                          <div class="mb-3">
                            <vue-select
                              :options="OtpMin"
                              id="otpmin"
                              placeholder="5 Mins"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Profile Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
