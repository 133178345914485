<script>
export default {
  data() {
    return {
      title: "Block Users",
      text: "Users",
      text1: "Block Users",
      Name: "Block Users List",
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- User List -->
      <div class="card">
        <user-top :Name="Name"></user-top>

        <!-- Block-user List -->
        <block-table></block-table>
      </div>
      <!-- /Block-user List -->
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
