<template>
  <div class="d-md-flex d-block align-items-center justify-content-between mb-4">
    <div class="my-auto mb-2 mb-md-0">
      <h4 class="page-title mb-1">{{ title }}</h4>
      <nav>
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item">
            <router-link to="/index"><i class="ti ti-home text-primary"></i></router-link>
          </li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0);">{{ text }}</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ text1 }}</li>
        </ol>
      </nav>
    </div>
    <div class="d-flex my-xl-auto right-content align-items-center flex-wrap">
      <div class="dropdown me-2 mb-2">
        <a
          href="javascript:void(0);"
          class="dropdown-toggle btn fw-medium d-inline-flex align-items-center"
          data-bs-toggle="dropdown"
        >
          <i class="ti ti-file-export me-2"></i>Export
        </a>
        <ul class="dropdown-menu dropdown-menu-end p-3">
          <li>
            <a href="javascript:void(0);" class="dropdown-item rounded-1"
              ><i class="ti ti-file-type-pdf me-1"></i>Export as PDF</a
            >
          </li>
          <li>
            <a href="javascript:void(0);" class="dropdown-item rounded-1"
              ><i class="ti ti-file-type-xls me-1"></i>Export as Excel
            </a>
          </li>
        </ul>
      </div>
      <div class="mb-2" v-if="isBanRoute">
        <a
          href="#"
          class="btn btn-primary d-flex align-items-center"
          data-bs-toggle="modal"
          data-bs-target="#add_user"
          ><i class="ti ti-circle-plus me-2"></i>Add New User</a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    text1: {
      type: String,
      default: "",
    },
  },
  computed: {
    isBanRoute() {
      return this.$route.path === "/admin-template/user/user-list";
    },
  },
};
</script>
