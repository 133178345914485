<script>
import { ref } from "vue";

export default {
  setup() {
    const activeTab = ref("call-menu"); // Default to 'chat-menu'

    // Method to set the active tab
    const setActiveTab = (tabId) => {
      activeTab.value = tabId;
    };

    return {
      activeTab,
      setActiveTab,
    };
  },
  mounted() {
    document.querySelectorAll(".chat-user-list").forEach(function (element) {
      element.addEventListener("click", function () {
        if (window.innerWidth <= 992) {
          const showChat = document.querySelector(".chat-messages");
          if (showChat) {
            showChat.classList.add("show");
          }
        }
      });
    });
    document.querySelectorAll(".chat-close").forEach(function (element) {
      element.addEventListener("click", function () {
        if (window.innerWidth <= 992) {
          const hideChat = document.querySelector(".chat-messages");
          if (hideChat) {
            hideChat.classList.remove("show");
          }
        }
      });
    });
  },
};
</script>

<template>
  <!-- content -->
  <div class="content main_content">
    <!-- Left Sidebar Menu -->
    <div class="sidebar-menu">
      <div class="logo">
        <router-link to="index" class="logo-normal"
          ><img src="@/assets/img/logo.svg" alt="Logo"
        /></router-link>
      </div>
      <div class="menu-wrap">
        <div class="main-menu">
          <ul class="nav">
            <a-tooltip placement="right">
              <template #title>Chats</template>
              <li>
                <router-link
                  to="index"
                  @click.prevent="setActiveTab('chat-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#chat-menu"
                >
                  <i class="ti ti-message-2-heart"></i>
                </router-link>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Contacts</template>
              <li>
                <a
                  href="#"
                  @click.prevent="setActiveTab('contact-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#contact-menu"
                >
                  <i class="ti ti-user-shield"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Groups</template>
              <li>
                <a
                  href="#"
                  @click.prevent="setActiveTab('group-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#group-menu"
                >
                  <i class="ti ti-users-group"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Status</template>
              <li>
                <router-link
                  to="status"
                  @click.prevent="setActiveTab('status-menu')"
                >
                  <i class="ti ti-circle-dot"></i>
                </router-link>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Calls</template>
              <li>
                <a
                  href="#"
                  class="active"
                  @click.prevent="setActiveTab('call-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#call-menu"
                >
                  <i class="ti ti-phone-call"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Profile</template>
              <li>
                <a
                  href="#"
                  @click.prevent="setActiveTab('profile-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-menu"
                >
                  <i class="ti ti-user-circle"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="right">
              <template #title>Settings</template>
              <li>
                <a
                  href="#"
                  @click.prevent="setActiveTab('setting-menu')"
                  data-bs-toggle="tab"
                  data-bs-target="#setting-menu"
                >
                  <i class="ti ti-settings"></i>
                </a>
              </li>
            </a-tooltip>
          </ul>
        </div>
        <profile-menu></profile-menu>
      </div>
    </div>
    <!-- /Left Sidebar Menu -->

    <!-- sidebar group -->
    <div class="sidebar-group">
      <div class="tab-content">
        <div
          id="chat-menu"
          :class="[
            'tab-pane',
            'fade',
            activeTab === 'chat-menu' ? 'active show' : '',
          ]"
        >
          <!-- Chats sidebar -->
          <index-sidebar></index-sidebar>
          <!-- / Chats sidebar -->
        </div>

        <!-- Contact -->
        <div
          id="contact-menu"
          :class="[
            'tab-pane',
            'fade',
            activeTab === 'contact-menu' ? 'active show' : '',
          ]"
        >
          <!-- Chats sidebar -->
          <chat-sidebar></chat-sidebar>
          <!-- / Chats sidebar -->
        </div>
        <!-- /Contact -->

        <!-- Group -->
        <div
          :class="[
            'tab-pane',
            'fade',
            activeTab === 'group-menu' ? 'active show' : '',
          ]"
          id="group-menu"
        >
          <!-- Chats sidebar -->
          <group-sidebar></group-sidebar>
          <!-- / Chats sidebar -->
        </div>
        <!-- /Group -->

        <!-- Profile -->
        <div
          :class="[
            'tab-pane',
            'fade',
            activeTab === 'profile-menu' ? 'active show' : '',
          ]"
          id="profile-menu"
        >
          <!-- Profile sidebar -->
          <profile-sidebar></profile-sidebar>
          <!-- / Profile sidebar -->
        </div>
        <!-- /Profile -->

        <!-- Calls -->
        <div
          :class="[
            'tab-pane',
            'fade',
            activeTab === 'call-menu' ? 'active show' : '',
          ]"
          id="call-menu"
        >
          <!-- Call sidebar -->
          <calls-sidebar></calls-sidebar>
          <!-- / Call sidebar -->
        </div>
        <!-- /Calls -->

        <!-- Settings -->
        <div
          :class="[
            'tab-pane',
            'fade',
            activeTab === 'setting-menu' ? 'active show' : '',
          ]"
          id="setting-menu"
        >
          <!-- Profile sidebar -->
          <settings-sidebar></settings-sidebar>
          <!-- / Chats sidebar -->
        </div>
        <!-- /Settings -->
      </div>
    </div>
    <!-- /Sidebar group -->

    <!-- Chat -->
    <calls-content></calls-content>
    <!-- /Chat -->
  </div>
  <!-- /Content -->

  <modal-index></modal-index>
</template>
