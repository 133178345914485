<script>
const columns = [
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total Incoming Call",
    dataIndex: "Total_Incoming_Call",
    sorter: {
      compare: (a, b) => {
        a = a.Total_Incoming_Call.toLowerCase();
        b = b.Total_Incoming_Call.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total Outgoing Call",
    dataIndex: "Total_Outgoing_Call",
    sorter: {
      compare: (a, b) => {
        a = a.Total_Outgoing_Call.toLowerCase();
        b = b.Total_Outgoing_Call.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total Missed Call",
    dataIndex: "Total_Missed_Call",
    sorter: {
      compare: (a, b) => {
        a = a.Total_Missed_Call.toLowerCase();
        b = b.Total_Missed_Call.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "Action",
    sorter: false,
  },
];

const data = [
  {
    Name: "Aaryian Jose",
    Image: "user-01.jpg",
    Total_Incoming_Call: "80",
    Total_Outgoing_Call: "100",
    Total_Missed_Call: "30",
  },
  {
    Name: "Sarika Jain",
    Image: "user-02.jpg",
    Total_Incoming_Call: "120",
    Total_Outgoing_Call: "120",
    Total_Missed_Call: "120",
  },
  {
    Name: "Clyde Smith",
    Image: "user-03.jpg",
    Total_Incoming_Call: "200",
    Total_Outgoing_Call: "200",
    Total_Missed_Call: "200",
  },
  {
    Name: "Carla Jenkins",
    Image: "user-04.jpg",
    Total_Incoming_Call: "250",
    Total_Outgoing_Call: "250",
    Total_Missed_Call: "250",
  },
  {
    Name: "Federico Wells",
    Image: "user-05.jpg",
    Total_Incoming_Call: "300",
    Total_Outgoing_Call: "300",
    Total_Missed_Call: "300",
  },
  {
    Name: "Edward Lietz",
    Image: "user-06.jpg",
    Total_Incoming_Call: "180",
    Total_Outgoing_Call: "180",
    Total_Missed_Call: "180",
  },
  {
    Name: "Sharon Ford",
    Image: "user-07.jpg",
    Total_Incoming_Call: "200",
    Total_Outgoing_Call: "200",
    Total_Missed_Call: "200",
  },
  {
    Name: "Thomas Rethman",
    Image: "user-08.jpg",
    Total_Incoming_Call: "320",
    Total_Outgoing_Call: "320",
    Total_Missed_Call: "320",
  },
  {
    Name: "Wilbur Martinez",
    Image: "user-09.jpg",
    Total_Incoming_Call: "400",
    Total_Outgoing_Call: "400",
    Total_Missed_Call: "400",
  },
  {
    Name: "Danielle Baker",
    Image: "user-10.jpg",
    Total_Incoming_Call: "210",
    Total_Outgoing_Call: "210",
    Total_Missed_Call: "210",
  },
];

const rowSelection = {
  onChange: () => {},
  onSelect: () => {},
  onSelectAll: () => {},
};

export default {
  data() {
    return {
      data,
      columns,
      rowSelection,
    };
  },
};
</script>

<template>
  <div class="custom-datatable-filter table-responsive">
    <a-table
      class="table datatable thead-light"
      :columns="columns"
      :data-source="data"
      :row-selection="rowSelection"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'Name'">
          <div class="d-flex align-items-center">
            <router-link to="staff-details" class="avatar avatar-md"
              ><img
                :src="require(`@/assets/admin/img/users/${record.Image}`)"
                class="img-fluid rounded-circle"
                alt="img"
            /></router-link>
            <div class="ms-2 profile-name">
              <p class="text-dark mb-0">
                <a href="#">{{ record.Name }}</a>
              </p>
            </div>
          </div>
        </template>
        <template v-if="column.key === 'Action'">
          <a href="#" data-bs-toggle="modal" data-bs-target="#delete-modal"
            ><span class="file-icon"><i class="ti ti-trash"></i></span
          ></a>
        </template>
      </template>
    </a-table>
  </div>
</template>