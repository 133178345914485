<script>
export default {
  data() {
    return {};
  },
  methods: {
    submitForm() {
      this.$router.push("sms-settings");
    },
  },
};
</script>
<template>
  <!-- Php Mail -->
  <div class="modal fade" id="sms_mail">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Nexmo</h4>
          <button
            type="button"
            class="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ti ti-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="mb-3">
              <label class="form-label">API Key </label>
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">API Secret Key </label>
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label class="form-label">Sender ID </label>
              <input type="text" class="form-control" />
            </div>
            <div class="row gx-3">
              <div class="col-6">
                <button
                  type="button"
                  class="btn btn-outline-primary w-100"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
              <div class="col-6">
                <button
                  type="submit"
                  class="btn btn-primary w-100"
                  data-bs-dismiss="modal"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Php Mail -->
</template>
