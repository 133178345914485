<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
export default {
  data() {
    return {
        settings: {
            suppressScrollX: true,
        },
    };
  },
  methods: {
    scrollHanle() {},
  },
  components: {
    PerfectScrollbar,
  },
};
</script>

<template>
    <div class="sidebar-content active slimscroll">
        <perfect-scrollbar
            class="scroll-area"
            :settings="settings"
            @ps-scroll-y="scrollHanle"
        >
            <div class="slimscroll">
                <div class="chat-search-header">                            
                    <div class="header-title d-flex align-items-center justify-content-between">
                        <h4 class="mb-3">Profile</h4>
                    </div>
                </div>   

                <!-- Profile -->
                <div class="profile mx-3">
                    <div class="border-bottom text-center pb-3 mx-1">
                        <div class="d-flex justify-content-center ">
                            <span class="avatar avatar-xxxl online mb-4">
                                <img src="@/assets/img/profiles/avatar-16.jpg" class="rounded-circle" alt="user">
                            </span>
                        </div>
                        <div>
                            <h6 class="fs-16">Salom Katherine</h6>
                            <div class="d-flex justify-content-center">
                                <span class="fs-14 text-center">Web Designer</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Profile -->

                <div class="sidebar-body chat-body">
                
                    <!-- Profile Info -->
                    <h5 class="mb-2">Profile Info</h5>
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex profile-list justify-content-between align-items-center border-bottom mb-3 pb-3">
                                <div>
                                    <h6 class="fs-14">Name</h6>
                                    <p class="fs-16 ">Salom Katherine</p>
                                </div>
                                <span><i class="ti ti-user-circle fs-16"></i></span>
                            </div>
                            <div class="d-flex profile-list justify-content-between align-items-center border-bottom mb-3 pb-3">
                                <div>
                                    <h6 class="fs-14">Phone</h6>
                                    <p class="fs-16">514-245-98315</p>
                                </div>
                                <span><i class="ti ti-phone-check fs-16"></i></span>
                            </div>
                            <div class="d-flex profile-list  profile-list justify-content-between align-items-center border-bottom mb-3 pb-3">
                                <div>
                                    <h6 class="fs-14">Gender</h6>
                                    <p class="fs-16">Female</p>
                                </div>
                                <span><i class="ti ti-user-star fs-16"></i></span>
                            </div>
                            <div class="d-flex profile-list justify-content-between align-items-center border-bottom mb-3 pb-3">
                                <div>
                                    <h6 class="fs-14">Email Address</h6>
                                    <p class="fs-16">info@example.com</p>
                                </div>
                                <span><i class="ti ti-mail-heart fs-16"></i></span>
                            </div>
                            <div class="d-flex profile-list profile-list justify-content-between align-items-center border-bottom mb-3 pb-3">
                                <div>
                                    <h6 class="fs-14">Bio</h6>
                                    <p class="fs-16">Web Designer</p>
                                </div>
                                <span><i class="ti ti-user-check fs-16"></i></span>
                            </div>
                            <div class="d-flex profile-list justify-content-between align-items-center border-bottom mb-3 pb-3">
                                <div>
                                    <h6 class="fs-14">Location</h6>
                                    <p class="fs-16">Portland, USA</p>
                                </div>
                                <span><i class="ti ti-map-2 fs-16"></i></span>
                            </div>
                            <div class="d-flex profile-list justify-content-between align-items-center">
                                <div>
                                    <h6 class="fs-14">Join Date</h6>
                                    <p class="fs-16">01 July 2024</p>
                                </div>
                                <span><i class="ti ti-calendar-event fs-16"></i></span>
                            </div>
                        </div>
                    </div>
                    <!-- /Profile Info -->

                    <!-- Status -->
                    <h5 class="mb-2">Status</h5>
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex profile-list justify-content-between align-items-center border-bottom mb-3 pb-3">
                                <div>
                                    <h6 class="fs-14">Active Status</h6>
                                    <p class="fs-16 ">Show when you’re active</p>
                                </div>
                                <div class="form-check form-switch d-flex justify-content-end align-items-center">
                                    <input class="form-check-input" type="checkbox" role="switch" checked>
                                </div>
                            </div>
                            <div class="d-flex profile-list justify-content-between align-items-center">
                                <div>
                                    <h6 class="fs-14">Friends Status</h6>
                                    <p class="fs-16">Show friends status in chat</p>
                                </div>
                                <div class="form-check form-switch d-flex justify-content-end align-items-center">
                                    <input class="form-check-input" type="checkbox" role="switch" checked>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /Status -->

                    <!-- Social Media -->
                    <h5 class="mb-2">Social Media</h5>
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex profile-list justify-content-between align-items-center border-bottom mb-3 pb-3">
                                <div>
                                    <h6 class="fs-14">Facebook</h6>
                                    <p class="fs-16 ">@SalomKatherine</p>
                                </div>
                                <span><i class="ti ti-brand-facebook fs-16"></i></span>
                            </div>
                            <div class="d-flex profile-list justify-content-between align-items-center border-bottom mb-3 pb-3">
                                <div>
                                    <h6 class="fs-14">Instagram Linkedin</h6>
                                    <p class="fs-16 ">@SalomKatherine</p>
                                </div>
                                <span><i class="ti ti-brand-instagram fs-16"></i></span>
                            </div>
                            <div class="d-flex profile-list justify-content-between align-items-center">
                                <div>
                                    <h6 class="fs-14">Linkedin</h6>
                                    <p class="fs-16">@SalomKatherine</p>
                                </div>
                                <span><i class="ti ti-brand-linkedin fs-16"></i></span>
                            </div>
                        </div>
                    </div>
                    <!-- /Social Media -->

                    <!-- Deactivate -->
                    <h5 class="mb-2">Deactivate</h5>
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex profile-list justify-content-between align-items-center">
                                <div>
                                    <h6 class="fs-14">Deactivate Account</h6>
                                    <p class="fs-16 ">Deactivate your Account</p>
                                </div>
                                <div class="form-check form-switch d-flex justify-content-end align-items-center">
                                    <input class="form-check-input" type="checkbox" role="switch">
                                </div>
                            </div>
                        </div>
                    </div>   
                    <!-- /Deactivate -->

                    <!-- Logout -->
                    <h5 class="mb-2">Logout</h5>
                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="d-flex profile-list justify-content-between align-items-center">
                                <div>
                                    <h6 class="fs-14">Logout</h6>
                                    <p class="fs-16 ">Sign out from this Device</p>
                                </div>
                                <router-link to="/" class="link-icon"><i class="ti ti-logout fs-16"></i></router-link>
                            </div>
                        </div>
                    </div>   
                    <!-- /Logout -->

                </div>

            </div>
        </perfect-scrollbar>
    </div>
</template>


