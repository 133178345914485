const adminDashboard = {

    series: [{
        color: '#6338F6',
        data: [20, 60, 40, 51, 42, 42, 30, 25, 20, 40, 30, 40]
    }],
    AdminDash: {
        chart: {
            height: 206,
            type: "area",
            toolbar: {
                show: false
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: "smooth"
        },

        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        }
    }
}
export { adminDashboard }