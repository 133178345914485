<script>
export default {
  data() {
    return {
      title: "Custom Fields",
      text: "App Settings",
      text1: "Custom Fields",
    };
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- App Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-xl-3 col-md-4">
              <app-sidebar></app-sidebar>
            </div>
            <!-- Autentication Settings -->
            <div class="col-xl-9 col-md-8">
              <div class="card setting-content mb-0">
                <div class="card-header">
                  <div class="d-flex justify-content-between align-items-center">
                    <h4>Custom Fields</h4>
                    <a
                      href="#"
                      class="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#new-field"
                      ><i class="ti ti-circle-plus me-2"></i>Add New Fields</a
                    >
                  </div>
                </div>
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class="thead-light">
                        <tr>
                          <th class="no-sort">
                            <div class="form-check form-check-md">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="select-all"
                              />
                            </div>
                          </th>
                          <th class="fw-semibold">Module</th>
                          <th class="fw-semibold">Label</th>
                          <th class="fw-semibold">Type</th>
                          <th class="fw-semibold">Default Value</th>
                          <th class="fw-semibold">Required</th>
                          <th class="fw-semibold">Status</th>
                          <th class="fw-semibold">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th class="no-sort">
                            <div class="form-check form-check-md">
                              <input class="form-check-input" type="checkbox" />
                            </div>
                          </th>
                          <th>User</th>
                          <th class="text-gray fw-normal">Middle Name</th>
                          <th class="text-gray fw-normal">Text</th>
                          <th class="text-gray fw-normal">-</th>
                          <th>
                            <div class="form-check form-switch">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked
                              />
                            </div>
                          </th>
                          <th class="d-flex">
                            <span
                              class="badge badge-success badge-sm d-flex align-items-center"
                              ><i class="ti ti-point-filled"></i>Active</span
                            >
                          </th>
                          <th>
                            <div class="dropdown">
                              <a
                                href="javascript:void(0);"
                                class="text-gray"
                                data-bs-toggle="dropdown"
                                ><i class="ti ti-dots-vertical"></i
                              ></a>
                              <div class="dropdown-menu">
                                <a
                                  href="javascript:void(0);"
                                  class="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-field"
                                  ><i class="ti ti-edit me-2"></i>Edit</a
                                >
                                <a href="javascript:void(0);" class="dropdown-item"
                                  ><i class="ti ti-trash me-2"></i>Delete</a
                                >
                              </div>
                            </div>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Autentication Settings -->
          </div>
        </div>
      </div>
      <!-- /App Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
  <custom-fields-modal></custom-fields-modal>
</template>
