<script>
export default {
  data() {
    return {
      title: "Chat  Settings",
      text: "App Settings",
      text1: "Chat Settings",
    };
  },
  methods: {
    submitForm() {
      this.$router.push("chat-settings");
    },
  },
};
</script>
<template>
  <admin-header></admin-header>
  <admin-sidebar></admin-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <user-breadcrumb :title="title" :text="text" :text1="text1" />
      <!-- Page Header -->

      <!-- Social-Authentication Settings -->
      <div class="card setting-card">
        <div class="card-body">
          <settings-top></settings-top>
          <div class="row gx-3">
            <div class="col-xl-3 col-md-4">
              <app-sidebar></app-sidebar>
            </div>
            <div class="col-xl-9 col-md-8">
              <div class="card setting-content mb-0">
                <div class="card-header px-0 mx-3">
                  <h4>Chat Settings</h4>
                </div>
                <div class="card-body pb-0">
                  <div class="row">
                    <div class="col-md-8 col-sm-12">
                      <div class="row align-items-center mb-3">
                        <div class="col-lg-6">
                          <div class="chat-settings">
                            <h6 class="fw-medium">Message Length</h6>
                          </div>
                        </div>
                        <div class="col-lg-5">
                          <div class="chat-options">
                            <input type="text" class="form-control" value="1000" />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center chat-settings-lists mb-3 py-2">
                        <div class="col-lg-6">
                          <h6 class="fw-medium">Enable Gif</h6>
                        </div>
                        <div class="col-lg-6">
                          <div class="d-flex align-items-center">
                            <div class="form-check form-switch me-3 mb-0">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked
                              />
                            </div>
                            <a href="#" data-bs-toggle="modal" data-bs-target="#add_gif"
                              ><span class="chat-setting-icons"
                                ><i class="ti ti-settings"></i></span
                            ></a>
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center mb-3 py-2">
                        <div class="col-lg-6">
                          <h6 class="fw-medium">Enable Stickers</h6>
                        </div>
                        <div class="col-lg-5">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center mb-3 py-2">
                        <div class="col-lg-6">
                          <h6 class="fw-medium">Enable Images</h6>
                        </div>
                        <div class="col-lg-5">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center mb-3 py-2">
                        <div class="col-lg-6">
                          <h6 class="fw-medium">Enable Videos</h6>
                        </div>
                        <div class="col-lg-5">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center mb-3 py-2">
                        <div class="col-lg-6">
                          <h6 class="fw-medium">Enable Audio Messages</h6>
                        </div>
                        <div class="col-lg-5">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center mb-3 py-2">
                        <div class="col-lg-6">
                          <h6 class="fw-medium">Enable Codes</h6>
                        </div>
                        <div class="col-lg-5">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center mb-3 py-2">
                        <div class="col-lg-6">
                          <h6 class="fw-medium">Enable Files</h6>
                        </div>
                        <div class="col-lg-5">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center mb-3 py-2">
                        <div class="col-lg-6">
                          <h6 class="fw-medium">File Extension List</h6>
                        </div>
                        <div class="col-lg-6">
                          <textarea class="form-control">
.doc, .docs, .xls, .xlsx, .ppt, .pptx, .txt, .pdf, .jpeg, .png</textarea
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Social-Authentication Settings -->
    </div>
  </div>
  <!-- /Page Wrapper -->
  <!-- Gif -->
  <div class="modal fade" id="add_gif">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Gif Settings</h4>
          <button
            type="button"
            class="btn-close custom-btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ti ti-x"></i>
          </button>
        </div>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">Tenor API Key</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-12">
                <div>
                  <label class="form-label">GIF Limit</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pt-0">
            <div class="d-flex w-100 justify-content-between">
              <a
                href="#"
                class="btn btn-outline-primary me-2 d-flex justify-content-center w-100"
                data-bs-dismiss="modal"
                >Cancel</a
              >
              <button
                type="submit"
                class="btn btn-primary d-flex justify-content-center w-100"
                data-bs-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Gif -->
</template>
